import { formatDate, secondsToHHMM } from './date.utilities';
import {
  ActivityType,
  CategoryEnum,
  Contact,
  DefaultEnum,
  Horse,
  PregnancyCheckTermEnum,
  RealActivities,
  Role,
  Stable,
  StableNames,
} from 'openapi';
import { t, TFunction } from 'i18next';
import Calendar, { GroupByApplied } from 'context/Calendar';
import { addDays, getWeek, weeksToDays } from 'date-fns';
import { contactName } from './Contact';
import { FALLBACK_ACTIVITY_PRIMARY_COLOR } from 'ui/Const';
import { AppliedListFilter, ListFilterType } from 'components/Common/ListFilter';
import { pregnancyCheckTermEnumToStringPrefix } from './Breeding';

export enum DayParts {
  QuarterHour = 96,
  HalfHour = 48,
  Hour = 24,
  QuarterDay = 4,
  HalfDay = 2,
  Day = 1,
}

export enum ViewType {
  Day = 1,
  Week = 2,
  Month = 3,
}

export enum TimeScale {
  TimeScale = 1,
  DayParts,
  FullDay,
}

export enum GroupBy {
  None = 0,
  Horse,
  Staff,
  StaffCatchAll, // All unassigned activities
  Stable,
}

export interface DayPart {
  name: string;
  startTime: Date;
  number: number; // The 'index' of the daypart.
}

export interface Assignee {
  primary: boolean;
  contact?: Contact;
  role?: Role;
}

export enum CalendarActivityType {
  Activity,
  Task,
  Message,
}

export interface CalendarActivity {
  readonly uid: string;
  horseUid?: string;
  horse?: Horse;
  stableUid?: string;
  startTime: Date;
  endTime: Date;
  activityType?: ActivityType;
  dayPart: number;
  // A textual representation of the period (e.g. morning or 8:30 - 10:00)
  startEndText: string;
  doneOn?: Date;
  done: boolean;
  assignedTo: Assignee[];
  order: number;
  type: CalendarActivityType;
  primaryColor: string;
  secondaryColor: string;
  extraInfo?: string; // Note for real activity

  // Indicates if the activity was automatically planned by Equinem. E.g. when you add a vaccination, it will automatically plan the next vaccination.
  isAutomaticallyPlanned: boolean;

  // This means that the activity is not connected to a specific start and end time.
  // But rather is connected to the day with an optional day part.
  isAllDayEvent: boolean;

  // Only used for Daily notes atm.
  title?: string;
  text?: string;
  executable: boolean; // Can the daily note be marked as finished?

  // For pregnancy check activities.
  pregnancyCheckTerm?: PregnancyCheckTermEnum;
  pregnancyCheckUid?: string;

  // For heat check activities.
  heatCheckUid?: string;

  // For stallion mount activities.
  stallionMountUid?: string;
}

export interface CalendarView {
  offset: number;
  previousOffset?: number;
  current: Calendar;
  left: Calendar;
  right: Calendar;
}

export enum BluePrintState {
  EditCompact,
  EditFull,
  Selected,
}

export interface BluePrint {
  state: BluePrintState; // This means that we can show the modal/input for creating the activity from the blueprint.
  type?: CalendarActivityType;
  preferredType?: CalendarActivityType; // Which type should be preselected.
  day?: Date;

  // For Activities:
  appliedGroupBy?: GroupByApplied; // Horse/Contact/Stable/etc uid
  horseUid?: string;
  stableUid?: string;
  dayPart?: number; // The daypart index when we're requesting a blue print for a fixed day part.
  startPeriodOffset?: number;
  activityTypeUid?: string; // Activity type that can be filled in the compact modal but should also be visible in the big modal
  duration?: number;

  // For Daily notes:
  taskOrMessage?: string; // Task or message that can be filled in the compact modal but should also be visible in the big modal
  isExecutable?: boolean; // isExecutable flag that can be filled in the compact modal but should also be visible in the big modal
}

export enum Reshaping {
  ResizeTop = 1,
  ResizeBottom,
  Move,
}

export enum SelectedActivityState {
  Selected,
  Info,
  Edit,
  Finalize,
}

export interface DragActivity {
  activity: CalendarActivity;
  originGroupByUid?: GroupByApplied;
}

export interface SelectedActivity {
  activity: CalendarActivity;

  /**
   * The uid of the horse, contact or stable.
   * Why this flag:
   * In the Staff planning, the same activity can be displayed more than once when we assign multiple
   * contacts to it. Therefor we can specify for which contact the actvity is selected.
   */
  groupByUid?: string;

  selectedActivityState?: SelectedActivityState;
}

export const timeScaleToString = (t: TFunction, timeScale: TimeScale): string => {
  switch (timeScale) {
    case TimeScale.DayParts:
      return t('day-parts', 'Day parts');
    case TimeScale.TimeScale:
      return t('time-scale', 'Time scale');
    case TimeScale.FullDay:
      return t('full-day', 'Full day');
  }
};

export const viewTypeToString = (t: TFunction, viewType: ViewType): string => {
  switch (viewType) {
    case ViewType.Day:
      return t('day-view', 'Day');
    case ViewType.Week:
      return t('week-view', 'Week');
    case ViewType.Month:
      return t('month-view', 'Month');
  }
};

// Get a color based in the Activity Type
export const activityTypeColor = (activityType: ActivityType, primary: boolean): string => {
  switch (activityType.category) {
    case CategoryEnum.BREEDING:
      return primary ? '#f59e0b' : '#fde68a'; // Amber (500, 200) https://tailwindcss.com/docs/customizing-colors
    case CategoryEnum.CARE:
      return primary ? '#9333ea' : '#e9d5ff'; // Purple (600, 200) https://tailwindcss.com/docs/customizing-colors
    case CategoryEnum.SPORT:
      return primary ? '#059669' : '#d1fae5'; // Emerald (500, 100) https://tailwindcss.com/docs/customizing-colors
  }
  return FALLBACK_ACTIVITY_PRIMARY_COLOR;
};

export const orderActivities = (activities: CalendarActivity[]): CalendarActivity[] => {
  function compare(a: CalendarActivity, b: CalendarActivity) {
    if (a.dayPart < b.dayPart) {
      return -1;
    }
    if (a.dayPart > b.dayPart) {
      return 1;
    }
    if (a.order < b.order) {
      return -1;
    }
    if (a.order > b.order) {
      return 1;
    }
    return 0;
  }
  return activities.sort(compare);
};

export const getOffsetForDate = (today: Date, date: Date, viewType: ViewType): number => {
  if (viewType === ViewType.Day) {
    return date.getDate() - today.getDate();
  }
  if (viewType === ViewType.Week) {
    return getWeek(date) - getWeek(today);
  }
  throw Error('Unimplemented');
};

export const activityIsAssignedToContact = (calendarActivity: CalendarActivity, contactUid: string): boolean => {
  return calendarActivity.assignedTo.find(item => item.contact?.uid === contactUid) !== undefined;
};

/**
 * Returns a start and end time (hh:mm) based on a position in a daypart scale.
 * If the index is larger then the scale, it rotates back to 00:00.
 */
export const datePartToTime = (scale: DayParts, index: number): string => {
  const secondsInDay = 24 * 60 * 60;
  const dayPartSeconds = secondsInDay / scale;
  const start = dayPartSeconds * (index % scale);
  return secondsToHHMM(start);
};

/**
 * From a given day part provide the start and end date.
 */
export const datePartToDateTime = (
  date: Date,
  scale: DayParts,
  dayPartStartIndex: number,
  durationInDayParts: number,
): { start: Date; end: Date } => {
  const quotient = Math.floor((dayPartStartIndex + durationInDayParts) / scale);
  const remainder = (dayPartStartIndex + durationInDayParts) % scale;
  let endDate = new Date(date);
  endDate = addDays(endDate, quotient);
  const start = datePartToTime(scale, dayPartStartIndex);
  const end = datePartToTime(scale, remainder);
  return { start: new Date(`${formatDate(date)}T${start}:00`), end: new Date(`${formatDate(endDate)}T${end}:00`) };
};

/**
 * Get the name of the 'group by' for a calendar cluster.
 */
export const groupByAppliedGetName = (t: TFunction, groupByApplied: GroupByApplied): string => {
  if (groupByApplied.groupBy === GroupBy.StaffCatchAll) {
    return t('unassigned', 'Unassigned');
  }
  const subject = groupByApplied.subject;
  if (!subject) {
    console.warn('GroupByApplied has no subject');
    return '';
  }
  if (groupByApplied.groupBy === GroupBy.Horse) {
    return (subject as Horse).name;
  }
  if (groupByApplied.groupBy === GroupBy.Staff) {
    return contactName(subject as Contact) ?? '';
  }
  if (groupByApplied.groupBy === GroupBy.Stable) {
    if ('name' in subject) {
      // It's a StableNames object
      return (subject as StableNames).name;
    } else if ('location' in subject) {
      // It's a Stable object
      return (subject as Stable).location.business_name;
    } else {
      console.warn('Could not get name of GroupByApplied stable object');
      return '';
    }
  }
  console.warn('Could not get name of GroupByApplied object');
  return '';
};

export const stableListFilterTypes = (t: TFunction, stables: Stable[], markedUid?: string): ListFilterType => {
  return {
    id: 'stable',
    name: t('stable', 'Stable'),
    options: (stables ?? []).map(stable => {
      if (markedUid === stable.uid) {
        return { id: stable.uid, name: `${contactName(stable.location)} *` };
      } else {
        return { id: stable.uid, name: contactName(stable.location) ?? '' };
      }
    }),
  };
};

export enum DragDropType {
  Disabled,
  DayPart, // You can drag and drop within a day to change the time or day part
  Cluster, // You can drag between clusters (like staff) but the time and day are not altered.
  ClusterPlus, // Just like Cluster. But you can drag drop within days within the same cluster.
}

export function getActivityTypeCategory(t: TFunction, category: CategoryEnum): string {
  switch (category) {
    case CategoryEnum.BREEDING:
      return t('activity-type-category-breeding', 'Breeding');
    case CategoryEnum.CARE:
      return t('activity-type-category-care', 'Care');
    case CategoryEnum.SPORT:
      return t('activity-type-category-sport', 'Sport');
    default:
      return t('unknown', 'Unknown');
  }
}

// Represents working hours within a day for planning purposes.
export interface WorkingHours {
  from: [number, number]; // HH:MM
  to: [number, number]; // HH:MM
}

// Filter by Applied List Filters
export const listFilterActivityTypes = (activities: CalendarActivity[], appliedListFilter: AppliedListFilter[]): CalendarActivity[] => {
  if (appliedListFilter.length === 0) {
    return activities;
  }

  return activities.filter(activity => {
    for (const alf of appliedListFilter) {
      if (alf.type.id === 'activity-type') {
        if (!alf.options.find(o => activity.activityType?.uid === o.id)) {
          return false;
        }
      }
    }

    return true;
  });
};

export const activityIntervalString = (t: TFunction, activityType: ActivityType, horse: Horse): string | undefined => {
  switch (activityType.default) {
    case DefaultEnum.FARRIER:
      return horse.farrier_weeks ? `${horse.farrier_weeks} ${t('weeks', 'weeks')}` : '-';
    case DefaultEnum.DENTIST:
      return horse.dentist_weeks ? `${horse.dentist_weeks} ${t('weeks', 'weeks')}` : '-';
    case DefaultEnum.RHINO:
      return horse.rhino_weeks ? `${horse.rhino_weeks} ${t('weeks', 'weeks')}` : '-';
    case DefaultEnum.DEWORM:
      return horse.deworm_weeks ? `${horse.deworm_weeks} ${t('weeks', 'weeks')}` : '-';
  }
};

export const activityIntervalDays = (activityType: ActivityType, horse: Horse): number | undefined => {
  switch (activityType.default) {
    case DefaultEnum.FARRIER:
      return horse.farrier_weeks ? weeksToDays(horse.farrier_weeks) : undefined;
    case DefaultEnum.DENTIST:
      return horse.dentist_weeks ? weeksToDays(horse.dentist_weeks) : undefined;
    case DefaultEnum.RHINO:
      return horse.rhino_weeks ? weeksToDays(horse.rhino_weeks) : undefined;
    case DefaultEnum.DEWORM:
      return horse.deworm_weeks ? weeksToDays(horse.deworm_weeks) : undefined;
  }
};

export const realActivityToDate = (activity: RealActivities): Date => {
  if (activity.start.date) {
    return new Date(activity.start.date);
  }
  if (activity.start.datetime) {
    return new Date(activity.start.datetime);
  }
  throw Error('Failed to parse RealActivity to Date');
};

/**
 * A horse has some fields about intervals for care activities. Like go to the farrier once every 6 weeks. When this six weeks is passed it's overdue.
 * @param activityType The type of activity to do the due check for.
 * @param horse The horse we want to measure it on.
 * @param lastActivity The last done activity for the specific activity type.
 * @param now The current date time
 * @returns Returns true when an activity type is overdue.
 */
export const activityIntervalOverdue = (activityType: ActivityType, horse: Horse, lastActivity: RealActivities, now: Date): boolean => {
  if (!lastActivity.done_on) {
    console.warn('Could not determine if the interval is overdue. Last activity is not done.');
    return false;
  }

  const intervalDays = activityIntervalDays(activityType, horse);
  if (intervalDays === undefined) {
    console.warn('Could not determine if the interval is overdue. IntervalDays is undefined.');
    return false;
  }

  const dueDate = addDays(realActivityToDate(lastActivity), intervalDays);
  return dueDate < now;
};

export const activityTypeName = (activity: CalendarActivity): string => {
  const type = activity.activityType;
  if (!type || !type.name) {
    return t('unknown', 'Unknown');
  }

  // Add a term prefix for when it's a pregnancy check
  if (type.default === DefaultEnum.PREGNANCY_CHECK && activity.pregnancyCheckTerm) {
    const prefix = pregnancyCheckTermEnumToStringPrefix(activity.pregnancyCheckTerm);
    if (prefix) {
      return `${pregnancyCheckTermEnumToStringPrefix(activity.pregnancyCheckTerm)} ${type.name}`;
    }
  }
  return type.name;
};
