import React, { Fragment, ReactNode, useMemo, useState } from 'react';
import { Float } from '@headlessui-float/react';
import { ActionModal } from 'ui/Modals';
import useScreenSize, { ScreenSize } from 'hooks/UseScreenSize';
import TitleBarButtons from 'ui/Modals/TitleBarButtons';
import { ActionButton, ActionProps } from 'ui/Modals/ActionModal';
import { ErrorSection } from 'ui/Error';
import ApiErrorParser from 'api/ApiErrorParser';
import { ActivityType, Horse, RealActivities, VaccinationRule } from 'openapi';
import { HorseUsageBadges } from 'components/Horses/HorseUsageBadges';
import Badge, { BadgeSize } from 'ui/Badge/Badge';
import { AllColors } from 'utilities/colors';
import { useTranslation } from 'react-i18next';
import { useAccount } from 'context/AccountContext';
import { activityIntervalString, BluePrintState, CalendarActivityType, realActivityToDate } from 'utilities/Planning';
import { ButtonVariant } from 'ui/Button';
import { usePlanning } from 'hooks/UsePlanning';
import useRefreshingNow from 'hooks/UseRefreshingNow';

export interface Props {
  children: ReactNode;
  visible?: boolean;
  horse: Horse;
  activityType: ActivityType;
  lastActivity?: RealActivities;
  nextPlannedActivity?: RealActivities;
  foundLastNotDone?: RealActivities;
  vaccinationRule?: VaccinationRule;
  onRequestClose?: () => void;
  className?: string;
}

export interface ContentProps {
  horse: Horse;
  activityType: ActivityType;
  lastActivity?: RealActivities;
  nextPlannedActivity?: RealActivities;
  vaccinationRule?: VaccinationRule;
  foundLastNotDone?: RealActivities;
}

/**
 * An inline modal for showing an existing calendar item.
 */
function CareActivityModalContent({
  horse,
  activityType,
  lastActivity,
  nextPlannedActivity,
  foundLastNotDone,
  vaccinationRule,
}: ContentProps): JSX.Element {
  const { t } = useTranslation();
  const { formatDate } = useAccount();

  const activityIntervalStr = activityIntervalString(t, activityType, horse);

  return (
    <div className='space-y-3'>
      <div className='flex flex-col items-start overflow-hidden gap-1'>
        {horse.name}
        {!horse.hidden && <HorseUsageBadges size={BadgeSize.Small} iconOnly={true} horse={horse} />}
        {horse.hidden && <Badge color={AllColors.Rose}>{t('inactive', 'Inactive')}</Badge>}

        {vaccinationRule && (
          <Badge color={AllColors.Purple} size={BadgeSize.Small}>
            <span className='truncate'>{vaccinationRule?.name}</span>
          </Badge>
        )}
      </div>
      <div>
        <p className='font-medium text-gray-700 text-sm'>
          {t('last-activity-done-type', 'Last done {{activityTypeName}}', { activityTypeName: activityType.name })}
        </p>
        <p>
          {lastActivity?.start.date && lastActivity.done_on
            ? formatDate(lastActivity?.start.date ?? '')
            : t('never-unknown', 'Never/Unknown')}
        </p>
        <p>
          {lastActivity?.start.date && !lastActivity.done_on && (
            <div className='text-xs px-0.5 mb-1 border rounded border-rose-400 text-rose-700'>
              {t('not-marked-as-done', 'Not marked as done at {{date}}', {
                date: formatDate(realActivityToDate(lastActivity)),
              })}
            </div>
          )}
        </p>
        <p>{lastActivity && !lastActivity?.done_on && t('done', 'DONE')}</p>
      </div>
      {activityIntervalStr && (
        <div>
          <p className='font-medium text-gray-700 text-sm'>{t('activity-interval', 'Interval')}</p>
          <p>{activityIntervalStr}</p>
        </div>
      )}
      {nextPlannedActivity && (
        <div>
          <p className='font-medium text-gray-700 text-sm'>{t('next-planned', 'Next planned')}</p>
          <p>{formatDate(realActivityToDate(nextPlannedActivity))}</p>
        </div>
      )}
      {foundLastNotDone && (
        <div>
          <p className='font-medium text-gray-700 text-sm'>{t('notice', 'Notice')}</p>
          <p className='text-red-600'>
            {t('planned-not-marked-as-done', 'Activity was planned at {{date}}, but is not marked as finished.', {
              date: formatDate(realActivityToDate(foundLastNotDone)),
            })}
          </p>
        </div>
      )}
    </div>
  );
}

export default function CareActivityModal({
  children,
  visible,
  activityType,
  lastActivity,
  nextPlannedActivity,
  foundLastNotDone,
  horse,
  vaccinationRule,
  onRequestClose,
  className,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const { width } = useScreenSize();
  const { requestBluePrint, getDayPartForTime } = usePlanning();
  const { now } = useRefreshingNow();
  const [apiError, setApiError] = useState<ApiErrorParser<void>>();

  const modalActions = useMemo((): ActionProps[] => {
    if (nextPlannedActivity) {
      return [];
    }
    return [
      {
        variant: ButtonVariant.Primary,
        text: t('plan-next', 'Plan next'),
        onClick: () =>
          requestBluePrint({
            state: BluePrintState.EditFull,
            dayPart: getDayPartForTime(now.getHours(), now.getMinutes()),
            day: now,
            duration: 0,
            startPeriodOffset: 0,
            type: CalendarActivityType.Activity,
            activityTypeUid: activityType.uid,
            horseUid: horse.uid,
          }),
      },
    ];
  }, [activityType, getDayPartForTime, horse, requestBluePrint, now, t, nextPlannedActivity]);

  if (width <= ScreenSize.md) {
    return (
      <div className={className}>
        {children}
        <ActionModal actions={modalActions} open={visible ?? false}>
          <TitleBarButtons title={activityType.name} actions={[]} onClose={onRequestClose} />
          <ErrorSection errors={apiError} />
          <CareActivityModalContent
            horse={horse}
            activityType={activityType}
            vaccinationRule={vaccinationRule}
            lastActivity={lastActivity}
            nextPlannedActivity={nextPlannedActivity}
          />
        </ActionModal>
      </div>
    );
  }

  return (
    <Float
      offset={4}
      show={visible}
      placement='right-start'
      flip={true}
      composable
      portal={true}
      as={Fragment}
      enter='transition duration-200 ease-out'
      enter-from='opacity-0 -translate-y-1'
      enter-to='opacity-100 translate-y-0'
      leave='transition ease-in duration-100'
      leaveFrom='opacity-100'
      leaveTo='opacity-0'
      onHide={() => setApiError(undefined)}
    >
      <Float.Reference>
        <div className={className}>{children}</div>
      </Float.Reference>
      <Float.Content>
        <div
          className='w-64 rounded-lg bg-white pl-4 pb-4 pt-2 pr-2 shadow-[0_8px_30px_rgb(0,0,0,0.22)]'
          onClick={e => {
            // Don't hide when the modal is clicked
            e.stopPropagation();
          }}
        >
          <TitleBarButtons actions={[]} title={activityType.name} onClose={onRequestClose} />
          <ErrorSection errors={apiError} />
          <CareActivityModalContent
            horse={horse}
            activityType={activityType}
            vaccinationRule={vaccinationRule}
            lastActivity={lastActivity}
            nextPlannedActivity={nextPlannedActivity}
            foundLastNotDone={foundLastNotDone}
          />
          <div className='pr-2 mt-5 sm:mt-6 gap-4 flex'>
            {modalActions.map((action, index) => (
              <ActionButton className='w-full' key={index + action.text} action={action} />
            ))}
          </div>
        </div>
      </Float.Content>
    </Float>
  );
}
