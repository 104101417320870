import classNames from 'classnames';
import React, { ReactNode, useEffect, useMemo } from 'react';
import { LoadingSection } from 'ui/Loading';
import { PageAction } from '../../../context/PageContext';
import Alert, { AlertProps } from '../../Alert/Alert';
import Button, { ButtonSize } from '../../Button';
import ApiErrorParser from 'api/ApiErrorParser';
import { Severity } from 'utilities/severity';
import { FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ApiPromises } from 'utilities/ApiPromises';
import useLoadApiPromises from 'api/hooks/useLoadApiPromises';

interface TileProps<T extends FieldValues> {
  title?: ReactNode;
  actions?: PageAction[];
  className?: string;
  alert?: AlertProps;
  loading?: boolean | ApiPromises;
  loadingText?: string;
  apiError?: ApiErrorParser<T>;
  noBoxOnMobile?: boolean;
  children: ReactNode;
  overflowContent?: boolean;
}

/**
 * Tile (Bento box) component.
 * @property title: A textual header for the tile. When the title is set, it will automatically add content paddings.
 * @property noBoxOnMobile: Hide the box wrapping when we're in a mobile layout
 */
function Tile<T extends FieldValues>({
  title,
  actions,
  className,
  alert,
  loading,
  loadingText = 'loading content...',
  apiError,
  noBoxOnMobile,
  children,
  overflowContent,
}: TileProps<T>): JSX.Element {
  const { loadInternal, apiPromisesError, errorInfo, canShowLoader } = useLoadApiPromises();
  const isApiPromises = typeof loading !== 'boolean' && loading !== undefined;

  const { t } = useTranslation();

  /**
   * Updates the loading state (based on ApiPromises).
   */
  useEffect(() => {
    if (isApiPromises) {
      loadInternal(loading);
    }
  }, [isApiPromises, loadInternal, loading, t]);

  /**
   * convert the error to a string for the user
   */
  const errors = useMemo(() => {
    if (apiError) {
      return apiError.nonFieldErrorsStrings().join(' ') ?? t('unknown-error', 'Unknown error');
    }

    if (apiPromisesError) {
      return errorInfo?.description ?? t('unknown-error', 'Unknown error');
    }
  }, [apiError, apiPromisesError, errorInfo?.description, t]);

  return (
    <div
      className={classNames('w-full flex', className, {
        'px-2 print:px-0 md:px-0': !noBoxOnMobile,
      })}
    >
      <section
        className={classNames('grow min-w-0 h-full', {
          // When we have noBoxOnMobile disabled, we should make the box relative and contain the paint as it is boxed
          // in case noBoxOnMobile is enabled, we should not contain the paint and dont make the box relative as it should and can overflow
          'contain-paint relative': !noBoxOnMobile && !overflowContent,
          'md:contain-paint md:relative': noBoxOnMobile && !overflowContent,
          'print:border-none rounded-xl bg-white border border-neutral-200': !noBoxOnMobile,
          'print:border-none md:rounded-xl md:bg-white md:border md:border-neutral-200': noBoxOnMobile,
        })}
      >
        {title && <h2 className='text-lg mb-4 md:mb-6 px-3 print:px-0 md:px-0 font-medium md:mx-8 mt-4 md:mt-6 print:mt-0'>{title}</h2>}

        {errors && (
          <div className='px-4 mb-2'>
            <Alert severity={Severity.Danger} message={errors} />
          </div>
        )}

        {actions && (
          <div className='absolute right-3 top-3 space-x-2'>
            {actions.map((action, index) => (
              <Button
                size={ButtonSize.Small}
                onClick={action.onClick}
                disabled={action.disabled}
                icon={action.icon}
                key={index}
                variant={action.buttonVariant}
                compress={true}
              >
                {action.text}
              </Button>
            ))}
          </div>
        )}

        {alert && <Alert {...alert} />}

        {/* We show and hide loader by adding the .hidden class to the element */}
        {/* this avoid we detach and attach the {children} components and trigger rerenders */}
        <div
          className={classNames('py-4 print:py-0', {
            hidden: isApiPromises ? !canShowLoader : !loading,
          })}
        >
          <LoadingSection text={loadingText} />
        </div>

        <div
          className={classNames('h-full w-full', {
            hidden: isApiPromises ? canShowLoader : loading,
            'px-3 pb-3 md:px-8 md:pb-6 print:px-0 print:pb-0': title,
          })}
        >
          {children}
        </div>
      </section>
    </div>
  );
}

export default Tile;
