import { zodResolver } from '@hookform/resolvers/zod';
import ApiErrorParser from 'api/ApiErrorParser';
import useFormError from 'api/hooks/useFormError';
import { useOrganization } from 'context/OrganizationContext';
import {
  CancelablePromise,
  Horse,
  PregnancycheckService,
  PregnancyCheck,
  PregnancyCheckTermEnum,
  IsPregnantEnum,
  ActivitiesService,
  DefaultEnum,
  RealActivities,
} from 'openapi';
import { schemas } from 'openapi/zod-schemas';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { get, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Button, { ButtonVariant } from 'ui/Button';
import { ErrorSection } from 'ui/Error';
import { DateInput, SelectInput, TextAreaInput } from 'ui/Inputs';
import { OptionItemInterface } from 'ui/Inputs/SelectInput';
import { PageModal } from 'ui/Modals';
import { ActionProps, PageModalActions, PageModalContent, PageModalTitle, PageModalWidth } from 'ui/Modals/PageModal';
import { transformEmptyToUndefined } from 'utilities/zod';
import { useAccount } from 'context/AccountContext';
import { CalendarActivity, realActivityToDate } from 'utilities/Planning';
import { formatDate } from 'utilities/date.utilities';
import { pregnancyCheckIsPregnantEnumToString, pregnancyCheckTermEnumToString } from 'utilities/Breeding';
import RadioButtonGroup, { RadioButtonGroupOption } from 'ui/Inputs/RadioGroupInput';
import { usePlanning } from 'hooks/UsePlanning';
import { addDays, differenceInCalendarDays } from 'date-fns';
import { Plus, Trash } from '@phosphor-icons/react';
import DropdownMenu from 'ui/DropdownMenu';
import classNames from 'classnames';
import { Alert } from 'ui/Alert';
import { Severity } from 'utilities/severity';

interface Props {
  mares?: Horse[];
  skippable?: boolean;
  selectedMare?: Horse;
  pregnancyCheckActivity?: CalendarActivity;
  existingPregnancyCheck?: PregnancyCheck;
  open: boolean;
  onSaved: (reload: boolean, isSkipped: boolean, pregnancyCheck?: PregnancyCheck) => void;
  onRequestClose: () => void;
}

// Return date of today in YYYY-MM-DD format.
const today = (): string => {
  return new Date().toISOString().substr(0, 10);
};

// Check the pregnancy status of a mare.
export function SavePregnancyCheck({
  mares,
  skippable,
  selectedMare,
  existingPregnancyCheck,
  pregnancyCheckActivity,
  open,
  onRequestClose,
  onSaved,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const { selectedOrganizationUid, selectedOrganizationDetails } = useOrganization();
  const { formatDate: formatDatePretty } = useAccount();

  const { activityTypes } = usePlanning();

  const [relatedInsemination, setRelatedInsemination] = useState<RealActivities | undefined>(undefined);

  // This means that we found an insemination to relate to but it's not marked as done.
  const [relatedInseminationNotDone, setRelatedInseminationNotDone] = useState<RealActivities | undefined>(undefined);

  const schema = useMemo(() => {
    return schemas.PregnancyCheck.omit({
      uid: true,
      activity_uid: true,
      created_by: true,
      add_followup_pregnancychecks: true,
      date: pregnancyCheckActivity ? true : undefined,
      mare_uid: pregnancyCheckActivity ? true : undefined,
      pregnancy_check_term: pregnancyCheckActivity && pregnancyCheckActivity.pregnancyCheckTerm ? true : undefined,
      order_item_uid: true, // @TODO Optionally connect to the order.
    });
  }, [pregnancyCheckActivity]);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    control,
    reset,
  } = useForm<PregnancyCheck>({
    reValidateMode: 'onChange',
    resolver: zodResolver(schema),
  });

  // const { fields, append, remove } = useFieldArray({
  //   name: 'add_pregnancycheck_dates',
  //   control,
  // });

  const { fields, append, remove } = useFieldArray({
    name: 'add_followup_pregnancychecks',
    control,
  });

  const { fieldError, nonFieldErrors, setApiError } = useFormError(schema, errors);

  const onSubmit = async (data: PregnancyCheck) => {
    if (!selectedOrganizationUid) {
      console.error('Save pregnancy check without selected organization.');
      return;
    }

    const submitData = data as Partial<PregnancyCheck>;

    if (pregnancyCheckActivity) {
      submitData.activity_uid = pregnancyCheckActivity.uid;
      submitData.date = formatDate(pregnancyCheckActivity.startTime);

      // It might be that it's not filled in at the activity.
      if (pregnancyCheckActivity.pregnancyCheckTerm) {
        submitData.pregnancy_check_term = pregnancyCheckActivity.pregnancyCheckTerm;
      }
    }

    let promise: CancelablePromise<PregnancyCheck>;
    if (existingPregnancyCheck) {
      promise = PregnancycheckService.pregnancycheckPartialUpdate({
        mareOrganisationUid: selectedOrganizationUid,
        uid: existingPregnancyCheck.uid,
        requestBody: submitData,
      });
    } else {
      promise = PregnancycheckService.pregnancycheckCreate({
        mareOrganisationUid: selectedOrganizationUid,
        requestBody: submitData as PregnancyCheck,
      });
    }

    try {
      onSaved(true, false, await promise);
      onRequestClose();
    } catch (e) {
      setApiError(new ApiErrorParser<PregnancyCheck>(e));
    }
  };

  const findRelatedInsemination = useCallback(async (): Promise<RealActivities | undefined> => {
    const insemeniationType = activityTypes?.find(type => type.default === DefaultEnum.INSEMINATION);
    if (!insemeniationType) {
      return;
    }

    if (!selectedMare) {
      console.log('Cannot distill last insemination activity because no mare is selected');
      return;
    }

    const inseminationActivities = await ActivitiesService.activitiesList({
      organisationUid: selectedOrganizationUid ?? '',
      lastDoneAndLastAndFirstFuturePerHorseAndActivityType: true,
      activityTypeUidIn: [insemeniationType.uid],
      horseUidIn: [selectedMare.uid],
    });

    // Filter for the lastDone activity.
    const foundInsemination = inseminationActivities.results.find(
      activity => activity.done_on && realActivityToDate(activity) < new Date(),
    );

    if (!foundInsemination) {
      // We might have an insemination to relate to but it's not marked as done.
      const notDone = inseminationActivities.results.find(activity => !activity.done_on && realActivityToDate(activity) < new Date());
      if (notDone) {
        setRelatedInseminationNotDone(notDone);
      }

      // No historic insemination found.
      return;
    }

    // We only search 100 days in history. Otherwise we might end up with an insemination from previous season.
    if (differenceInCalendarDays(new Date(), realActivityToDate(foundInsemination)) > 100) {
      console.log('Previous insemination found, but it is too old');
      return;
    }

    return foundInsemination;
  }, [activityTypes, selectedMare, selectedOrganizationUid]);

  const resetToDefaults = useCallback(() => {
    remove();
    if (existingPregnancyCheck) {
      reset(existingPregnancyCheck);
    } else {
      if (pregnancyCheckActivity) {
        reset({
          mare_uid: pregnancyCheckActivity.horseUid,
          date: formatDate(pregnancyCheckActivity.startTime),
          extra_info: pregnancyCheckActivity.extraInfo,
          pregnancy_check_term: pregnancyCheckActivity.pregnancyCheckTerm,
        });
      } else {
        reset({
          mare_uid: selectedMare?.uid,
          date: today(),
        });
      }
    }
  }, [reset, existingPregnancyCheck, selectedMare, pregnancyCheckActivity, remove]);

  // Event that will be fired after the modal has been closed
  const resetForm = () => {
    setApiError(undefined);
    resetToDefaults();
  };

  const mareOptions = useMemo((): OptionItemInterface[] | undefined => {
    if (!mares) {
      return undefined;
    }
    return mares
      .filter(mare => !mare.hidden || mare.uid === existingPregnancyCheck?.mare_uid)
      .map(horse => ({ id: horse.uid, name: horse.name }));
  }, [mares, existingPregnancyCheck]);

  const pregnancyCheckTermEnumOptions = useMemo(() => {
    return Object.values(PregnancyCheckTermEnum).reduce<OptionItemInterface[]>((prevVal, currentVal) => {
      prevVal.push({ id: currentVal, name: pregnancyCheckTermEnumToString(currentVal) });
      return prevVal;
    }, []);
  }, []);

  const pregnancyCheckIsPregnantEnumOptions = useMemo((): RadioButtonGroupOption[] => {
    return [
      { id: IsPregnantEnum.YES, name: pregnancyCheckIsPregnantEnumToString(IsPregnantEnum.YES) },
      { id: IsPregnantEnum.DUAL_PREGNANCY, name: pregnancyCheckIsPregnantEnumToString(IsPregnantEnum.DUAL_PREGNANCY) },
      { id: IsPregnantEnum.NO, name: pregnancyCheckIsPregnantEnumToString(IsPregnantEnum.NO) },
    ];
  }, []);

  // Reset when the modal visibility is changed or when the existingCategory is changed.
  useEffect(() => {
    resetToDefaults();
  }, [reset, existingPregnancyCheck, open, selectedMare, resetToDefaults]);

  useEffect(() => {
    if (open) {
      findRelatedInsemination()
        .then(activity => setRelatedInsemination(activity))
        .catch(e => {
          console.error('Failed to get related Insemination', e);
          setRelatedInsemination(undefined);
          setRelatedInseminationNotDone(undefined);
        });
    } else {
      setRelatedInsemination(undefined);
      setRelatedInseminationNotDone(undefined);
    }
  }, [open, findRelatedInsemination]);

  const modalActions = useMemo((): ActionProps[] => {
    const array: ActionProps[] = [];

    if (skippable) {
      array.push({
        variant: ButtonVariant.Default,
        text: t('skip', 'Skip'),
        onClick: () => {
          onSaved(false, true);
          onRequestClose();
        },
      });
    }

    array.push({
      variant: ButtonVariant.Primary,
      text: t('save', 'Save'),
      type: 'submit',
      formId: 'addPregnancyCheck',
      loading: isSubmitting,
    });

    return array;
  }, [t, isSubmitting, skippable, onRequestClose, onSaved]);

  const suggestDateForFollowup = useCallback(
    (term: PregnancyCheckTermEnum): Date => {
      if (!relatedInsemination) {
        return new Date();
      }
      const date = realActivityToDate(relatedInsemination);
      switch (term) {
        case PregnancyCheckTermEnum.FIRST:
          // This can be determined in the planning settings. By default it's 18 days.
          return addDays(date, selectedOrganizationDetails?.days_between_insemination_and_pregnancy_check ?? 18);
        case PregnancyCheckTermEnum.SECOND:
          return addDays(date, 40);
        case PregnancyCheckTermEnum.THIRD:
          return addDays(date, 84); // 12 weeks
      }
    },
    [relatedInsemination, selectedOrganizationDetails],
  );

  return (
    <PageModal
      open={open}
      onClosed={resetForm}
      parentElement='form'
      width={PageModalWidth.Sm}
      parentProps={{ id: 'addPregnancyCheck', noValidate: true, onSubmit: handleSubmit(onSubmit) }}
    >
      <PageModalTitle title={t('new-pregnancy-check', 'New pregnancy check')} onClose={() => onRequestClose()} />
      <PageModalContent>
        <ErrorSection className='mb-4' errors={nonFieldErrors} />
        <div className='flex flex-col gap-4'>
          {mareOptions && (
            <SelectInput
              label={t('mare', 'Mare')}
              nullable={true}
              required={true}
              options={mareOptions}
              error={fieldError('mare_uid')}
              {...register('mare_uid', { setValueAs: (val: unknown): unknown => (val === '----' ? undefined : val) })}
            />
          )}
          {!mareOptions && (
            <div>
              <p className='block text-sm font-medium leading-4 text-gray-600 mb-2'>{t('mare', 'Mare')} *</p>
              <p>{selectedMare?.name ?? t('unknown-mare', 'Unknown mare')}</p>
            </div>
          )}
          {pregnancyCheckActivity && (
            <div>
              <p className='block text-sm font-medium leading-4 text-gray-600 mb-2'>{t('date', 'Date')} *</p>
              <p>{formatDatePretty(pregnancyCheckActivity.startTime)}</p>
            </div>
          )}
          {(!pregnancyCheckActivity || !pregnancyCheckActivity.pregnancyCheckTerm) && (
            <DateInput control={control} required={true} label={t('date', 'Date')} name='date' error={fieldError('date')} />
          )}
          {relatedInsemination && (
            <div>
              <p className='block text-sm font-medium leading-4 text-gray-600 mb-2'>{t('related-insemination', 'Related insemination')}</p>
              <p>{formatDatePretty(realActivityToDate(relatedInsemination))}</p>
            </div>
          )}
          {relatedInseminationNotDone && (
            <div>
              <p className='block text-sm font-medium leading-4 text-gray-600 mb-2'>{t('related-insemination', 'Related insemination')}</p>
              <Alert
                severity={Severity.Warning}
                message={t(
                  'found-related-insemination-not-done-warning',
                  'Found a related insemination at {{date}} but it is not marked as finished.',
                  { date: formatDatePretty(realActivityToDate(relatedInseminationNotDone)) },
                )}
              />
            </div>
          )}
          {pregnancyCheckActivity && (
            <div>
              <p className='block text-sm font-medium leading-4 text-gray-600 mb-2'>
                {t('pregnancy-check-term', 'Pregnancy check term')} *
              </p>
              <p>
                {pregnancyCheckActivity.pregnancyCheckTerm
                  ? pregnancyCheckTermEnumToString(pregnancyCheckActivity.pregnancyCheckTerm)
                  : '-'}
              </p>
            </div>
          )}
          {!pregnancyCheckActivity && (
            <SelectInput
              required={true}
              nullable={true}
              options={pregnancyCheckTermEnumOptions}
              error={fieldError('pregnancy_check_term')}
              label={t('pregnancy-check-term', 'Pregnancy check term')}
              {...register('pregnancy_check_term', { setValueAs: (val: unknown): unknown => (val === '----' ? undefined : val) })}
            />
          )}

          <RadioButtonGroup<PregnancyCheck>
            name='is_pregnant'
            required={true}
            control={control}
            options={pregnancyCheckIsPregnantEnumOptions}
            error={fieldError('is_pregnant')}
            label={t('is-pregnant', 'Is pregnant')}
          />
          <TextAreaInput
            label={t('note', 'Note')}
            error={fieldError('extra_info')}
            {...register('extra_info', { setValueAs: transformEmptyToUndefined() })}
          />
          {!existingPregnancyCheck && (
            <div>
              <p className='text-sm font-medium text-gray-600 mb-2'>{t('plan-next-pregnancy-check', 'Followup pregnancy check(s)')}</p>
              <div className={classNames('rounded-lg space-y-4', { 'border p-4 mb-2': fields.length > 0 })}>
                {fields.map((field, index) => (
                  <div key={field.id}>
                    <p className='block text-sm font-medium leading-4 text-gray-600 mb-2'>
                      {t('add-followup-pregnancy-check-date', 'Pregnancy check ({{term}})', {
                        term: field.pregnancy_check_term ? pregnancyCheckTermEnumToString(field.pregnancy_check_term) : 'unknown',
                      })}
                    </p>
                    <div className='flex gap-4 justify-center'>
                      <DateInput
                        className='grow'
                        control={control}
                        error={get(errors, `add_followup_pregnancychecks.[${index}].date`)?.message}
                        {...register(`add_followup_pregnancychecks.${index}.date`, { setValueAs: transformEmptyToUndefined() })}
                      />
                      <button
                        type='button'
                        onClick={() => remove(index)}
                        className='inline rounded text-center cursor-pointer hover:bg-red-700 hover:text-white px-1 py-1 focus:ring-0 focus:ring-offset-0'
                      >
                        <Trash className='inline' />
                      </button>
                    </div>
                  </div>
                ))}
                <div className='flex'>
                  <DropdownMenu
                    menuPlacement='bottom-start'
                    align='left'
                    menuItems={[
                      [
                        {
                          element: t('first-pregnancy-check', 'First pregnancy check'),
                          onClick: () =>
                            append({
                              date: formatDate(suggestDateForFollowup(PregnancyCheckTermEnum.FIRST)),
                              pregnancy_check_term: PregnancyCheckTermEnum.FIRST,
                              order_item_uid: relatedInsemination?.order_item_uid,
                            }),
                        },
                        {
                          element: t('second-pregnancy-check', 'Second pregnancy check'),
                          onClick: () =>
                            append({
                              date: formatDate(suggestDateForFollowup(PregnancyCheckTermEnum.SECOND)),
                              pregnancy_check_term: PregnancyCheckTermEnum.SECOND,
                              order_item_uid: relatedInsemination?.order_item_uid,
                            }),
                        },
                        {
                          element: t('third-pregnancy-check', 'Third pregnancy check'),
                          onClick: () =>
                            append({
                              date: formatDate(suggestDateForFollowup(PregnancyCheckTermEnum.THIRD)),
                              pregnancy_check_term: PregnancyCheckTermEnum.THIRD,
                              order_item_uid: relatedInsemination?.order_item_uid,
                            }),
                        },
                      ],
                    ]}
                  >
                    <Button icon={<Plus />} type='button'>
                      {fields.length === 0 ? t('add', 'Add') : t('add-another', 'Add another')}
                    </Button>
                  </DropdownMenu>
                </div>
              </div>
            </div>
          )}
        </div>
      </PageModalContent>
      <PageModalActions actions={modalActions} />
    </PageModal>
  );
}
