import { CaretRight, FirstAidKit, Plus } from '@phosphor-icons/react';
import { AppliedListFilter, FilterOption, ListFilterType } from 'components/Common/ListFilter';
import FilterWrapper from 'components/Common/ListFilter/FilterWrapper';
import useListFilter from 'components/Common/ListFilter/useListFilter';
import HorseSelectButton from 'components/Horses/HorseSelectButton';
import { useOrganization } from 'context/OrganizationContext';
import { HeatCheck, HeatcheckService, Horse } from 'openapi';
import { SexEnum } from 'openapi/models/SexEnum';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  defaultApiPageSize,
  table,
  tableHiddenColumnMd,
  tableHiddenHeaderSm,
  tableTbody,
  tableTbodyTr,
  tableTheadTd,
  tableTheadTdSticky,
} from 'ui/Const';
import PullScrollWrapper from 'ui/PullScrollWrapper';
import { PageAction } from 'context/PageContext';
import { ButtonVariant } from 'ui/Button';
import Page from 'ui/Layout/Page';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { useAccount } from 'context/AccountContext';
import { Tile } from 'ui/Layout/Tile';
import { ApiPromises } from 'utilities/ApiPromises';
import { organizationHorses } from 'utilities/ApiRequests';
import { softnessEnumToString, uterusEnumToString } from 'utilities/Breeding';
import { SaveHeatCheck } from 'components/Breeding/SaveHeatCheck';

export default function HeatCheckPage(): JSX.Element {
  const { t } = useTranslation();
  const [horses, setHorses] = useState<Horse[]>();
  const [addHeatCheckModalOpen, setAddHeatCheckModalOpen] = useState<boolean>(false);
  const [heatChecks, setHeatChecks] = useState<HeatCheck[]>();
  const { selectedOrganization, generateCacheKey } = useOrganization();
  const navigate = useNavigate();
  const { formatDate } = useAccount();
  const [apiPromises, setApiPromises] = useState<ApiPromises>();

  // Filter all horses by type Stallion.
  const mares = useMemo(() => {
    if (!horses) return [];
    return horses.filter(horse => horse.sex === SexEnum._2);
  }, [horses]);

  // A list of types we can filter by.
  const filterTypes = useMemo((): ListFilterType[] | undefined => {
    if (!mares) {
      return undefined;
    }

    // A list of available mares to choose from
    const mareFilterOps: FilterOption[] = mares.map(mare => {
      return { id: mare.uid, name: mare.name };
    });

    const mareFilter: ListFilterType = {
      id: 'mare',
      name: t('mare', 'Mare'),
      options: mareFilterOps, // We don't provide a list of stallions here, we hav a special dropdown for this.
    };

    return [mareFilter];
  }, [t, mares]);

  const { filters, setFilters } = useListFilter(filterTypes ?? []);

  // Get the selected mare based on the filter (url search query).
  const selectedMareUid = useMemo((): string | undefined => {
    const foundFilter = filters.find(filter => filter.type.id === 'mare');
    if (!foundFilter) {
      return undefined;
    }
    if (foundFilter.options.length !== 1) {
      return undefined;
    }

    return foundFilter.options[0].id;
  }, [filters]);

  // Get the selected mare based on the filter (url search query).
  const selectedMare = useMemo((): Horse | undefined => {
    if (!selectedMareUid) return undefined;
    return mares.find(mare => mare.uid === selectedMareUid);
  }, [mares, selectedMareUid]);

  // Select a stallion
  const selectMare = useCallback(
    (horse?: Horse) => {
      if (!filterTypes) {
        return;
      }
      const foundFilterType = filterTypes.find(f => f.id === 'mare');
      if (!foundFilterType) {
        // should never happen.
        console.error('Filter type not found');
        return;
      }

      // The selection is cleared.
      if (!horse) {
        setFilters([]);
        return;
      }

      const stallionFilter: AppliedListFilter = {
        type: foundFilterType,
        options: [{ id: horse.uid, name: horse.name }],
      };
      setFilters([stallionFilter]);
    },
    [filterTypes, setFilters],
  );

  const pageActions = useMemo((): PageAction[] => {
    return [
      {
        text: t('new-heat-check', 'New heat check'),
        isMobileAddAction: true,
        icon: <Plus />,
        buttonVariant: ButtonVariant.Primary,
        onClick: () => setAddHeatCheckModalOpen(true),
      },
    ];
  }, [t]);

  const formatMare = (heatCheck: HeatCheck): string => {
    const found = (horses ?? []).find(mare => mare.uid === heatCheck.mare_uid);
    if (!found) {
      return '-';
    }
    return found.name;
  };

  // Load data from the api/cache
  const loadApiData = useCallback((): ApiPromises => {
    const promises = new ApiPromises();

    if (!selectedOrganization) {
      return promises;
    }
    promises.setPaginated<HeatCheck>(
      'heat-checks',
      apiPageNumber =>
        HeatcheckService.heatcheckList({
          mareOrganisationUid: selectedOrganization.uid,
          page: apiPageNumber,
          pageSize: defaultApiPageSize,
          mareUid: selectedMareUid,
          o: '-date,id',
        }),
      setHeatChecks,
      selectedMareUid === undefined, // Only count as empty when we don't filter.
    );
    promises.appendListObj<Horse>('horses', setHorses, organizationHorses(selectedOrganization.uid, generateCacheKey));
    setApiPromises(promises);
    return promises;
  }, [selectedOrganization, generateCacheKey, selectedMareUid]);

  // Load from the api
  useEffect(() => {
    if (selectedOrganization) {
      const promise = loadApiData();
      return () => promise.cancel();
    }
  }, [selectedOrganization, selectedMareUid]); //eslint-disable-line

  return (
    <>
      <Page title={t('heat-checks', 'Heat checks')} loading={apiPromises} actions={pageActions}>
        <PullScrollWrapper apiPromises={apiPromises}>
          <Tile noBoxOnMobile={true} overflowContent={true}>
            {filterTypes && mares && (
              <FilterWrapper>
                <HorseSelectButton selectedHorse={selectedMare} horses={mares} horseSelected={selectMare} />
              </FilterWrapper>
            )}
            <table className={table}>
              <thead>
                <tr className={tableHiddenHeaderSm}>
                  <td className={classNames('w-10', tableTheadTdSticky)} />
                  <td className={tableTheadTdSticky}>{t('date', 'Date')}</td>
                  {!selectedMareUid && <td className={tableTheadTdSticky}>{t('mare', 'Mare')}</td>}
                  <td className={classNames(tableTheadTd, tableTheadTdSticky)}>{t('uterus', 'Uterus')}</td>
                  <td className={classNames(tableTheadTd, tableTheadTdSticky)}>{t('cervix', 'Cervix')}</td>
                  <td className='w-10 md:hidden' />
                </tr>
              </thead>
              <tbody className={tableTbody}>
                {(heatChecks ?? []).map(heatCheck => {
                  return (
                    <tr className={tableTbodyTr} key={heatCheck.uid} onClick={() => navigate(heatCheck.uid)}>
                      <td className='text-center w-10'>
                        <FirstAidKit size={24} weight='light' className='inline' />
                      </td>
                      <td>{heatCheck.date && formatDate(new Date(Date.parse(heatCheck.date)))}</td>

                      {!selectedMareUid && <td>{formatMare(heatCheck)}</td>}
                      <td className={tableHiddenColumnMd}>{uterusEnumToString(heatCheck.uterus)}</td>
                      <td className={tableHiddenColumnMd}>{softnessEnumToString(heatCheck.cervix)}</td>
                      <td className={classNames('md:hidden')}>
                        <CaretRight size={22} weight='light' className='inline' />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </Tile>
        </PullScrollWrapper>
      </Page>
      <SaveHeatCheck
        mares={mares}
        selectedMare={selectedMare}
        open={addHeatCheckModalOpen}
        onSaved={(reload: boolean) => {
          if (reload) {
            loadApiData();
          }
        }}
        onRequestClose={() => setAddHeatCheckModalOpen(false)}
      />
    </>
  );
}
