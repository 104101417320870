import { zodResolver } from '@hookform/resolvers/zod';
import ApiErrorParser from 'api/ApiErrorParser';
import useFormError from 'api/hooks/useFormError';
import { useOrganization } from 'context/OrganizationContext';
import { Category, Contact, Horse, HorsesService, Product, ProductsService, ShippingServiceTypeEnum } from 'openapi';
import { schemas } from 'openapi/zod-schemas';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ErrorSection } from 'ui/Error';
import { RadioButtonGroupOption } from 'ui/Inputs/RadioGroupInput';
import { PageModal } from 'ui/Modals';
import { PageModalActions, PageModalContent, PageModalTitle, PageModalWidth } from 'ui/Modals/PageModal';
import { transformEmptyToUndefined } from 'utilities/zod';
import { ButtonVariant } from 'ui/Button';
import { SelectInput, TextInput } from 'ui/Inputs';
import MultiSelectInput from 'ui/Inputs/MultiSelectInput';
import { useAccount } from 'context/AccountContext';
import UseCountries from 'hooks/UseCountries';
import { z } from 'zod';
import ContactInputSelect from 'components/Contacts/ContactInputSelect';

interface Props {
  visible: boolean;
  availableStallions: Horse[]; // A list of stallions that are not yet connected to a product.
  horses: Horse[]; // A list of all stallions/horses
  contacts: Contact[];
  category?: Category;
  closeModal: () => void;
  existingProduct?: Product;
  onSaved?: (breedingProduct: Product) => void;
  onContactUpdated?: (contact: Contact) => void;
}

const ExtendedProduct = schemas.Product.extend({
  default_semen_collection_station: z.string().optional(),
});
type ExtendedProductType = z.infer<typeof ExtendedProduct>;

export default function SaveProductModal({
  availableStallions,
  horses,
  contacts,
  category,
  visible,
  closeModal,
  existingProduct,
  onSaved,
  onContactUpdated,
}: Props): JSX.Element {
  const [shippingServiceTypeFormValue, setShippingServiceTypeFormValue] = useState<string>();

  const { selectedOrganization, selectedOrganizationDetails } = useOrganization();
  const { t } = useTranslation();
  const { accountDetails } = useAccount();
  const { countries } = UseCountries();

  const schema = useMemo(() => {
    if (category?.default === 'BREEDING') {
      // We only show the stallion select when we're creating a new product.

      if (existingProduct) {
        return schemas.Product.pick({
          current_price: true,
          upfront_cost: true,
        })
          .extend({
            default_semen_collection_station: z.string().optional(),
          })
          .required({
            current_price: true,
            upfront_cost: true,
          });
      } else {
        return schemas.Product.pick({
          stallion: true,
          current_price: true,
          upfront_cost: true,
        })
          .extend({
            default_semen_collection_station: z.string().optional(),
          })
          .required({
            stallion: true,
            current_price: true,
            upfront_cost: true,
          });
      }
    } else if (category?.default === 'SHIPPING') {
      return schemas.Product.pick({
        shipping_provider_name: true,
        shipping_service_type: true,
        // shipping_countries is only required when the shipping service type is not pick up.
        shipping_countries: shippingServiceTypeFormValue === ShippingServiceTypeEnum.PICK_UP ? undefined : true,
        current_price: true,
      }).required({
        shipping_provider_name: true,
        shipping_service_type: true,
        // shipping_countries is only required when the shipping service type is not pick up.
        shipping_countries: shippingServiceTypeFormValue === ShippingServiceTypeEnum.PICK_UP ? undefined : true,
        current_price: true,
      });
    } else {
      // Catch all
      return schemas.Product;
    }
  }, [category?.default, existingProduct, shippingServiceTypeFormValue]);

  const defaultValues = useMemo((): Partial<ExtendedProductType> => {
    if (existingProduct) {
      const stallion = horses.find(horse => horse.uid === existingProduct.stallion);
      const semenCollectionStation = contacts.find(contact => contact.uid === stallion?.default_semen_collection_station);
      return { ...existingProduct, default_semen_collection_station: semenCollectionStation?.uid };
    } else {
      return {
        shipping_countries: [],
      };
    }
  }, [horses, existingProduct, contacts]);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
    setValue,
    control,
    watch,
  } = useForm<ExtendedProductType>({
    resolver: zodResolver(schema),
    reValidateMode: 'onChange',
    defaultValues,
  });

  const { fieldError, nonFieldErrors, setApiError } = useFormError(schema, errors);

  /**
   * Close modal action
   */
  const close = () => {
    closeModal();
    setApiError(undefined);
  };

  /**
   * Submit handler
   */
  const onSubmit = async (data: ExtendedProductType) => {
    if (!selectedOrganization) return console.error('selectedOrganization is not defined');
    try {
      let savedProduct: Product | undefined;
      if (existingProduct) {
        const promiseUpdate = ProductsService.productsUpdate({
          organisationUid: selectedOrganization.uid,
          uid: existingProduct.uid,
          requestBody: data as Product,
        });
        savedProduct = await promiseUpdate;
      } else {
        data.category = category?.uid ?? '';
        const promiseCreate = ProductsService.productsCreate({
          organisationUid: selectedOrganization.uid,
          requestBody: data as Product,
        });
        savedProduct = await promiseCreate;
      }
      if (data.stallion) {
        await HorsesService.horsesPartialUpdate({
          organisationUid: selectedOrganization.uid,
          uid: data.stallion,
          requestBody: { default_semen_collection_station: data.default_semen_collection_station ?? null },
        });
      }

      close();
      onSaved?.(savedProduct);
    } catch (error) {
      setApiError(new ApiErrorParser<Product>(error));
    }
  };

  const title = useMemo(() => {
    if (category?.default === 'BREEDING') {
      return existingProduct ? t('edit-breeding-price', 'Edit breeding price') : t('new-breeding-price', 'New breeding price');
    } else if (category?.default === 'SHIPPING') {
      return existingProduct ? t('edit-shipping-method', 'Edit shipping method') : t('new-shipping-method', 'New shipping method');
    }
    return '';
  }, [existingProduct, t, category]);

  const shippingTypeOptions = useMemo(() => {
    const options: RadioButtonGroupOption[] = [];
    Object.keys(ShippingServiceTypeEnum).forEach(key => {
      let text = key.toString();
      switch (key) {
        case ShippingServiceTypeEnum.REGULAR:
          text = t('shipping-type-regular', 'Regular');
          break;
        case ShippingServiceTypeEnum.PICK_UP:
          text = t('shipping-type-pick-up', 'Pick up');
          break;
        case ShippingServiceTypeEnum.SAME_DAY_DELIVERY:
          text = t('shipping-type-same-day-delivery', 'Same day delivery');
          break;
        case ShippingServiceTypeEnum.NEXT_DAY_DELIVERY:
          text = t('shipping-type-next-day-delivery', 'Next day delivery');
          break;
        case ShippingServiceTypeEnum.SUNDAY_HOLIDAY_DELIVERY:
          text = t('shipping-type-sunday-holiday-delivery', 'Sunday and holiday delivery');
          break;
      }
      options.push({ id: key, name: text });
    });
    return options;
  }, [t]);

  /**
   * event that will be fired after the modal has been closed
   */
  const resetForm = () => {
    setApiError(undefined);
    reset(defaultValues);
  };

  /**
   * Reset the form when a new 'existing product' is set.
   */
  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  /**
   * Watch the shipping_service_type field and set the form value
   *
   * NOTE: We are using the watch as a subscription because we need this value in the schema
   * (and both watch and schema are defined in the useForm hook)
   */
  useEffect(() => {
    const subscription = watch(({ shipping_service_type }, { name }) => {
      if (name === 'shipping_service_type') {
        setShippingServiceTypeFormValue(shipping_service_type);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  /**
   * Reset the shipping countries when the shipping service type is changed to PICK_UP
   */
  useEffect(() => {
    if (shippingServiceTypeFormValue === ShippingServiceTypeEnum.PICK_UP) {
      setValue('shipping_countries', undefined);
    }
  }, [setValue, shippingServiceTypeFormValue]);

  return (
    <>
      <PageModal open={visible} onClosed={resetForm} width={PageModalWidth.Sm}>
        <PageModalTitle title={title} onClose={() => close()} />
        <PageModalContent>
          <ErrorSection errors={nonFieldErrors} />
          <form noValidate={true} id='SaveBreedingPriceForm' onSubmit={handleSubmit(onSubmit)}>
            <div className='flex flex-col gap-4 grow'>
              {category?.default === 'BREEDING' && !existingProduct && (
                <SelectInput
                  label={t('stallion', 'Stallion')}
                  nullable={true}
                  required={true}
                  options={availableStallions.map(horse => ({ id: horse.uid, name: horse.name }))}
                  error={fieldError('stallion')}
                  nullableValue=''
                  {...register('stallion', { setValueAs: transformEmptyToUndefined() })}
                />
              )}
              {category?.default === 'BREEDING' && (
                <ContactInputSelect
                  name='default_semen_collection_station'
                  control={control}
                  contacts={contacts ?? []}
                  onCreated={contact => {
                    onContactUpdated?.(contact); // Update the list of contacts.
                    setValue('default_semen_collection_station', contact.uid);
                  }}
                  label={t('default-semen-collection-station', 'Default semen collection station')}
                  error={fieldError('default_semen_collection_station')}
                  hint={t(
                    'default-semen-collection-station-hint',
                    'Optionally set the semen collection station. This is the default location or company where the semen is collected for this stallion. This is default selected for new semen collections and orders.',
                  )}
                />
              )}
              {category?.default === 'SHIPPING' && (
                <>
                  <SelectInput
                    label={t('shipping-type', 'Type')}
                    nullable={true}
                    required={true}
                    options={shippingTypeOptions}
                    error={fieldError('shipping_service_type')}
                    nullableValue=''
                    {...register('shipping_service_type', { setValueAs: transformEmptyToUndefined() })}
                  />
                  <TextInput
                    required={true}
                    label={t('Name', 'Name')}
                    {...register('shipping_provider_name', { setValueAs: transformEmptyToUndefined() })}
                    error={fieldError('shipping_provider_name')}
                  />

                  {/* Countries are only visible when the user choose a shipping type other than PICKUP */}
                  {shippingServiceTypeFormValue !== ShippingServiceTypeEnum.PICK_UP && (
                    <MultiSelectInput<ExtendedProductType>
                      name='shipping_countries'
                      required={true}
                      control={control}
                      error={fieldError('shipping_countries')}
                      label={t('shipping-counties-label', 'Where does this shipping provider deliver to')}
                      options={countries}
                      searchPlaceholder={t('search-country-name', 'Search country name...')}
                      showToolbar={true}
                    />
                  )}
                </>
              )}
              <TextInput
                required={true}
                type='number'
                lang={accountDetails?.language}
                step='0.01'
                label={t('Price', 'Price')}
                {...register('current_price', { setValueAs: transformEmptyToUndefined() })}
                error={fieldError('current_price')}
                postText={existingProduct?.current_price_currency ?? selectedOrganizationDetails?.currency ?? 'EUR'}
              />
              {category?.default === 'BREEDING' && (
                <TextInput
                  required={true}
                  type='number'
                  lang={accountDetails?.language}
                  step='0.01'
                  label={t('upfront-payment', 'Upfront payment')}
                  {...register('upfront_cost', { setValueAs: transformEmptyToUndefined() })}
                  error={fieldError('upfront_cost')}
                  postText={existingProduct?.current_price_currency ?? selectedOrganizationDetails?.currency ?? 'EUR'}
                  hint={t(
                    'upfront-payment-hint',
                    'The amount that needs to be paid when placing the order. (This only applies for trusted countries)',
                  )}
                />
              )}
            </div>
          </form>
        </PageModalContent>
        <PageModalActions
          actions={[
            {
              loading: isSubmitting,
              variant: ButtonVariant.Primary,
              text: t('save', 'Save'),
              type: 'submit',
              formId: 'SaveBreedingPriceForm',
            },
          ]}
        />
      </PageModal>
    </>
  );
}
