import { IsPregnantEnum, LocationEnum, PregnancyCheckTermEnum, SoftnessEnum, UterusEnum } from 'openapi';
import { t } from 'i18next';

/**
 * Calculate the TMSC
 *
 * @param concentration In million cells per ml
 * @param volume In milliliter
 * @param movingCellsPercentage Percentage from 0 to 100
 * @param morphology percentage from 0 to 100
 * @returns TMSC in million motile sperm count (rounded to 1 decimal).
 */
export const totalMotileSpermCount = (
  concentration?: number,
  volume?: number,
  movingCellsPercentage?: number,
  morphology?: number,
): number | undefined => {
  if (!concentration || !volume || !movingCellsPercentage || !morphology) {
    return undefined;
  }
  const result = concentration * volume * (movingCellsPercentage / 100) * (morphology / 100);
  // Round to 1 decimal
  return Math.round(result * 10) / 10;
};

export const uterusEnumToString = (uterus: UterusEnum): string => {
  switch (uterus) {
    case UterusEnum.NO_EDEMA:
      return t('no-edema', 'No edema');
    case UterusEnum.SMALL_EDEMA:
      return t('small-edema', 'Small edema');
    case UterusEnum.MEDIUM_EDEMA:
      return t('medium-edema', 'Medium edema');
    case UterusEnum.LARGE_EDEMA:
      return t('large-edema', 'Large edema');
  }
};

export const softnessEnumToString = (softness: SoftnessEnum): string => {
  switch (softness) {
    case SoftnessEnum.SOFT:
      return t('cervix-soft', 'Soft');
    case SoftnessEnum.QUITE_SOFT:
      return t('cervix-quite-soft', 'Quite soft');

    case SoftnessEnum.QUITE_HARD:
      return t('cervix-quite-hard', 'Quite hard');
    case SoftnessEnum.HARD:
      return t('cervix-hard', 'Hard');
  }
};

export const pregnancyCheckTermEnumToString = (pregnancyCheckTerm: PregnancyCheckTermEnum): string => {
  switch (pregnancyCheckTerm) {
    case PregnancyCheckTermEnum.FIRST:
      return t('pregnancy-check-term-first', 'First');
    case PregnancyCheckTermEnum.SECOND:
      return t('pregnancy-check-term-second', 'Second');
    case PregnancyCheckTermEnum.THIRD:
      return t('pregnancy-check-term-third', 'Third');
  }
};

export const pregnancyCheckIsPregnantEnumToString = (isPregnant: IsPregnantEnum): string => {
  switch (isPregnant) {
    case IsPregnantEnum.YES:
      return t('yes', 'Yes');
    case IsPregnantEnum.NO:
      return t('no', 'No');
    case IsPregnantEnum.DUAL_PREGNANCY:
      return t('dual-pregnancy', 'Dual pregnancy');
  }
};

export const pregnancyCheckTermEnumToStringPrefix = (pregnancyCheckTerm: PregnancyCheckTermEnum): string | undefined => {
  switch (pregnancyCheckTerm) {
    case PregnancyCheckTermEnum.FIRST:
      return t('pregnancy-check-term-first-short', '1ˢᵗ');
    case PregnancyCheckTermEnum.SECOND:
      return t('pregnancy-check-term-second-short', '2ⁿᵈ');
    case PregnancyCheckTermEnum.THIRD:
      return t('pregnancy-check-term-third-short', '3ʳᵈ');
  }
};

export const eggLocationEnumToString = (eggLocation: LocationEnum): string => {
  switch (eggLocation) {
    case LocationEnum.LEFT:
      return t('egg-location-left', 'Left');
    case LocationEnum.RIGHT:
      return t('egg-location-right', 'Right');
  }
};
