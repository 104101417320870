import { Placement } from '@floating-ui/react';
import { Menu, Transition } from '@headlessui/react';
import { Float } from '@headlessui-float/react';
import classNames from 'classnames';
import React, { Fragment, ReactNode } from 'react';

export interface DropdownMenuItem {
  element: ReactNode;
  onClick?: () => void;
  isVisible?: boolean | (() => boolean);
  isDisabled?: boolean;
  className?: string;
}

export type DropdownMenuItemArray = DropdownMenuItem[];

interface Props {
  headerItem?: ReactNode | string;
  menuItems: DropdownMenuItemArray[];
  children?: ReactNode;
  menuPlacement?: Placement;
  buttonAs?: 'div' | 'button';
  buttonClassName?: string;
  offset?: number; // the offset of the menu
  align?: 'left' | 'right'; // Should we use the left or right side of the button as anchor point.
}

/**
 * The dropdown component create a clickable element with a list of items that are visible after the clickable element
 * received a click event.
 *
 * Example:
 * <DropdownMenu
 *    menuItems={[
 *    [
 *      { data: 'type of the action, or any value you need to pass', element: <div>Item 1</div>, onClick: () => void, isVisible: true },
 *      { data: 'type of the action, or any value you need to pass', element: <div>Item 2</div>, onClick: () => void, isVisible: () => true },
 *    ],
 *    [
 *      { type: 'type of the action, or any value you need to pass', element: <div>Item 5</div>, onClick: () => void },
 *    ],
 *  ]}
 * </Dropdown>
 */
export default function DropdownMenu({
  children,
  menuItems,
  headerItem,
  buttonClassName,
  buttonAs = 'div',
  menuPlacement,
  offset = 4,
  align = 'right',
}: Props): JSX.Element {
  return (
    <Menu>
      <Float portal={true} autoPlacement={menuPlacement === undefined} placement={menuPlacement} offset={offset}>
        <Menu.Button className={buttonClassName} as={buttonAs}>
          {children}
        </Menu.Button>

        <Transition
          as={Fragment}
          enter='transition ease-out duration-100'
          enterFrom='transform opacity-0 scale-95'
          enterTo='transform opacity-100 scale-100'
          leave='transition ease-in duration-75'
          leaveFrom='transform opacity-100 scale-100'
          leaveTo='transform opacity-0 scale-95'
        >
          <Menu.Items
            className={classNames(
              'absolute mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none',
              { 'left-0': align === 'left' },
              { 'right-0': align === 'right' },
            )}
          >
            {headerItem && (
              <Menu.Item className='p-3 font-semibold' as='div'>
                {headerItem}
              </Menu.Item>
            )}

            {menuItems.map((items, index) => {
              // filter out the items that are not visible
              const filteredItems = items.filter(item =>
                typeof item.isVisible === 'function' ? item.isVisible() : item.isVisible !== false,
              );

              return (
                <Fragment key={index}>
                  {filteredItems.length > 0 && (
                    <div className='p-1' key={index}>
                      {filteredItems.map((item, indexItem) => (
                        <Menu.Item key={indexItem} disabled={item.isDisabled}>
                          {({ active, disabled }) => (
                            <button
                              className={classNames(
                                'group flex w-full items-center rounded-md p-2',
                                {
                                  'bg-gray-100': item.onClick !== undefined && active,
                                  '!text-gray-500 !cursor-not-allowed': disabled,
                                  'cursor-default': item.onClick === undefined,
                                },
                                item.className,
                              )}
                              onClick={item.onClick}
                            >
                              {item.element}
                            </button>
                          )}
                        </Menu.Item>
                      ))}
                    </div>
                  )}
                </Fragment>
              );
            })}
          </Menu.Items>
        </Transition>
      </Float>
    </Menu>
  );
}
