import { CaretDown, CaretUp, Check, Horse, LightningA } from '@phosphor-icons/react';
import classNames from 'classnames';
import { usePlanning } from 'hooks/UsePlanning';
import React, { useCallback, useMemo, useRef } from 'react';
import ActivityModal from './ActivityModal';
import { AvatarInitials, AvatarSize } from 'ui/Avatar';
import { contactInitials } from 'utilities/Contact';
import BluePrintModal from './BluePrintModal';
import {
  activityTypeName,
  BluePrint,
  BluePrintState,
  CalendarActivity,
  CalendarActivityType,
  DayParts,
  DragDropType,
  GroupBy,
  orderActivities,
  SelectedActivityState,
} from 'utilities/Planning';
import { areIntervalsOverlapping } from 'date-fns';
import { equalGroupByApplied, GroupByApplied } from 'context/Calendar';

const dayPartsToPx = (canvasHeight: number, interval: DayParts, count: number) => {
  const intervalHeight = canvasHeight / interval;
  return intervalHeight * count;
};

const pxToDayParts = (canvasHeight: number, interval: DayParts, px: number): number => {
  const intervalHeight = canvasHeight / interval;
  return Math.floor(px / intervalHeight);
};

export enum Reshaping {
  ResizeTop = 1,
  ResizeBottom,
  Move,
}

interface ColumnCalendarActivity {
  activity: CalendarActivity;
  colSpan: number;

  // Poisoning
  posTop: number;
  posBottom: number;
  zigzagTop: boolean;
  zigzagBottom: boolean;
  compactHeight: boolean;
  isOutsideWorkHoursBottom: boolean;
  isOutsideWorkHoursTop: boolean;
}

export interface Props {
  activities?: CalendarActivity[];
  className?: string;
  heights: number[];
  // The indexes of the 'heights' that are visible.
  visibleRange?: { start: number; end: number };
  day: Date;
  appliedGroupBy?: GroupByApplied; // Horse/Contact/etc uid
  showHorseName: boolean; // Show/hide the horse name in the calendar activity item
  showContactAvatar: boolean; // Show/hide the primary assignee in the calendar activity item
  dragDropType: DragDropType;
}

/**
 * Lays out Calendar activities in a 24h time scale.
 * This is visible at this part (`x` marks the spot) of the calendar.
 * When the time scale view is selected, the user gets a 24h time scale with the calendar items plotted in.
 * You can drag/drop, inspect, add and edit CalendarActivities from this component.
 *
 *        ┌──────┌──────┌──────┌──────┌──────┌──────┌──────┐
 *        │  12  │  13  │  14  │  15  │  16  │  17  │  18  │
 *        │      │      │      │      │      │      │      │
 *        │      │      │      │      │      │      │      │
 * ┌──────┐──────┼──────┼──────┼──────┼──────┼──────┼──────┼
 * │      │      │xxxxxx│      │      │      │      │      │
 * │ 8:00 ┼──────┼xxxxxx┼──────┼──────┼──────┼──────┼──────┼
 * │      │      │xxxxxx│      │      │      │      │      │
 * │ 9:00 ┼──────┼xxxxxx┼──────┼──────┼──────┼──────┼──────┼
 * │      │      │xxxxxx│      │      │      │      │      │
 * │10:00 ┼──────┼xxxxxx┼──────┼──────┼──────┼──────┼──────┼
 * │      │      │xxxxxx│      │      │      │      │      │
 * │11:00 ┼──────┼xxxxxx┼──────┼──────┼──────┼──────┼──────┼
 * │      │      │xxxxxx│      │      │      │      │      │
 * │12:00 ┼──────┼xxxxxx┼──────┼──────┼──────┼──────┼──────┼
 * │      │      │xxxxxx│      │      │      │      │      │
 * └──────┘──────└──────└──────└──────└──────└──────└──────┘
 *
 *
 *
 *
 *
 */
export function ActivityContainerTimeScale({
  activities,
  className,
  heights,
  visibleRange,
  day,
  showHorseName,
  showContactAvatar,
  appliedGroupBy,
  dragDropType,
}: Props): JSX.Element {
  const ref = useRef<HTMLDivElement>(null);
  const mouseDown = useRef<boolean>(false);
  const {
    lastUsedActivityType,
    bluePrint,
    requestBluePrint,
    unsetBluePrint,
    reshaping,
    requestReshaping,
    setSelectedActivity,
    setDragActivity,
    selectedActivity,
    clearSelectedActivity,
    groupBy: groupByType,
    setHideNonWorkingHours,
  } = usePlanning();

  // We calculate position based on the full height.
  const fullHeight = useMemo(() => {
    return heights.reduce((partialSum, height) => partialSum + height, 0);
  }, [heights]);

  const negativeMarginTop = useMemo(() => {
    if (!visibleRange) {
      return 0;
    }
    const topHeights = heights.slice(0, visibleRange.start);
    return topHeights.reduce((partialSum, height) => partialSum + height, 0);
  }, [visibleRange, heights]);

  const negativeMarginBottom = useMemo(() => {
    if (!visibleRange) {
      return 0;
    }
    const topHeights = heights.slice(visibleRange.end, heights.length);
    return topHeights.reduce((partialSum, height) => partialSum + height, 0);
  }, [visibleRange, heights]);

  const sortedActivities = useMemo(() => {
    if (!activities) {
      return [];
    }
    return orderActivities(activities);
  }, [activities]);

  // Calculate the vertical position as a percentage (0-1) of the container height.
  const pos = useCallback((date: Date) => {
    // Calculate the position in the canvas. We do this based on a fixed time.
    // This means that Daylight savings are skipped.
    // This is required if we want the scale to work for the other days too.
    const offsetSeconds = (date.getHours() * 60 + date.getMinutes()) * 60;
    const totalSecondsInTheDay = 86400;
    return offsetSeconds / totalSecondsInTheDay;
  }, []);

  // Returns the sortedActivities grouped into columns. These columns allow us to place
  // calendar items on the canvas without overlapping. I.e. the following example shows
  // three activities. If they would all render in the same with they would overlap.
  // Therefor we split it into two columns.
  // +----+----+
  // |    |    |
  // | ## | ## |
  // | ## | ## |
  // |    | ## |
  // |    | ## |
  // | ## | ## |
  // +----+----+
  const sortedActivityColumns = useMemo((): ColumnCalendarActivity[][] => {
    const columns: CalendarActivity[][] = [];

    // First divide the activities into columns.
    sortedActivities.forEach((act: CalendarActivity) => {
      for (const col of columns) {
        let hasOverlap = false;
        for (const colActivity of col) {
          if (
            areIntervalsOverlapping({ start: act.startTime, end: act.endTime }, { start: colActivity.startTime, end: colActivity.endTime })
          ) {
            hasOverlap = true;
          }
        }
        if (!hasOverlap) {
          col.push(act);
          return;
        }
      }
      columns.push([act]);
    });

    // Then calculate the colspan for each activity
    const calculateColSpan = (activity: CalendarActivity, activityCol: number): number => {
      for (let i = activityCol + 1; i < columns.length; i++) {
        if (
          columns[i].find(colActivity =>
            areIntervalsOverlapping(
              { start: activity.startTime, end: activity.endTime },
              { start: colActivity.startTime, end: colActivity.endTime },
            ),
          )
        ) {
          return i - (activityCol + 1);
        }
      }
      return columns.length - 1 - activityCol;
    };

    const res: ColumnCalendarActivity[][] = columns.map((col, index) => {
      return col.map(activity => {
        const topFrac = pos(activity.startTime);
        let posTop = fullHeight * topFrac;
        const isOutsideWorkHoursBottom = posTop > fullHeight - negativeMarginBottom && !activity.isAllDayEvent;
        const capTop = posTop < negativeMarginTop;
        if (capTop) {
          posTop = negativeMarginTop;
        }
        const bottomFrac = 1 - pos(activity.endTime);
        let posBottom = fullHeight * bottomFrac;

        const isOutsideWorkHoursTop = fullHeight - posBottom < negativeMarginTop && !activity.isAllDayEvent;

        const capBottom = posBottom < negativeMarginBottom;
        if (capBottom) {
          posBottom = negativeMarginBottom;
        }

        // Show zigzag borders when the activity is partly outside the workday.
        const zigzagTop = capTop && !activity.isAllDayEvent;
        const zigzagBottom = capBottom && !activity.isAllDayEvent;

        // We mark it as 'compact height' the activity takes less then 2 hours.
        const compactHeight = bottomFrac - topFrac < 0.083;

        return {
          activity: activity,
          colSpan: calculateColSpan(activity, index),
          posTop,
          posBottom,
          zigzagTop,
          zigzagBottom,
          compactHeight,
          isOutsideWorkHoursTop,
          isOutsideWorkHoursBottom,
        };
      });
    });

    return res;
  }, [sortedActivities, negativeMarginBottom, negativeMarginTop, fullHeight, pos]);

  const hasActivitiesOutsideWorkingHoursTop = useMemo((): number => {
    return sortedActivityColumns.flatMap(col => col.filter(activityItem => activityItem.isOutsideWorkHoursTop)).length;
  }, [sortedActivityColumns]);

  const hasActivitiesOutsideWorkingHoursBottom = useMemo((): number => {
    return sortedActivityColumns.flatMap(col => col.filter(activityItem => activityItem.isOutsideWorkHoursBottom)).length;
    // return sortedActivityColumns.find(col => col.find(activityItem => activityItem.isOutsideWorkHoursBottom)) !== undefined;
  }, [sortedActivityColumns]);

  // Get the Y mouse position within the canvas div
  const getMouseYPos = (mouseEventClientY: number): number => {
    const bounds = ref.current?.getBoundingClientRect();
    return mouseEventClientY - (bounds?.top ?? 0);
  };

  const myBluePrint = useMemo((): BluePrint | undefined => {
    if (
      bluePrint &&
      bluePrint.type === CalendarActivityType.Activity &&
      bluePrint.day &&
      bluePrint.day.getFullYear() === day.getFullYear() &&
      bluePrint.day.getMonth() === day.getMonth() &&
      bluePrint.day.getDate() === day.getDate() &&
      equalGroupByApplied(bluePrint.appliedGroupBy, appliedGroupBy)
    ) {
      return bluePrint;
    }
  }, [bluePrint, day, appliedGroupBy]);

  // Based on the vertical position of the mouse towards the activity rect, calculate what resize action we are initiating.
  // For example, when the user grabs the top of the activity then resizing should happen from the start date.
  const getReshapeAction = (mouseEventClientY: number): Reshaping | undefined => {
    if (!myBluePrint) {
      return;
    }
    const mousePos = getMouseYPos(mouseEventClientY);
    const bluePrintTop = dayPartsToPx(fullHeight, DayParts.HalfHour, myBluePrint.startPeriodOffset ?? 0);
    const bluePrintBottom = dayPartsToPx(fullHeight, DayParts.HalfHour, (myBluePrint.startPeriodOffset ?? 0) + (myBluePrint.duration ?? 1));
    if (mousePos < bluePrintTop || mousePos > bluePrintBottom) {
      // Mouse is outside the blueprint
      return;
    }
    const posInBluePrint = mousePos - bluePrintTop;
    const bluePrintHeight = bluePrintBottom - bluePrintTop;
    const mousePosPercentage = posInBluePrint / bluePrintHeight;
    if (mousePosPercentage < 0.15) {
      return Reshaping.ResizeTop;
    }
    if (mousePosPercentage > 0.85) {
      return Reshaping.ResizeBottom;
    }
    return Reshaping.Move;
  };

  return (
    <div
      ref={ref}
      className={classNames(className, 'overflow-y-hidden')}
      onClick={e => {
        e.stopPropagation();
        if (!reshaping) {
          if (selectedActivity && selectedActivity.selectedActivityState === SelectedActivityState.Finalize) {
            // When we are in a finalize state (i.e. when we create a heat check) we just return.
            return;
          } else if (selectedActivity && selectedActivity.selectedActivityState !== SelectedActivityState.Selected) {
            setSelectedActivity(selectedActivity.activity, SelectedActivityState.Selected, selectedActivity?.groupByUid);
          } else if (bluePrint) {
            // We already have a blueprint active then close it. Otherwise create a new blueprint for the given daypart.
            unsetBluePrint();
            clearSelectedActivity();
          } else {
            clearSelectedActivity();
            const bounds = ref.current?.getBoundingClientRect();
            const yOffset = e.clientY - (bounds?.top ?? 0) + (negativeMarginTop ?? 0);
            const startPeriodOffset = pxToDayParts(fullHeight, DayParts.HalfHour, yOffset);
            const duration = Math.min(2, DayParts.HalfHour - startPeriodOffset);
            requestBluePrint({
              day,
              appliedGroupBy,
              horseUid: appliedGroupBy?.groupBy === GroupBy.Horse ? appliedGroupBy.subject?.uid : undefined,
              stableUid: appliedGroupBy?.groupBy === GroupBy.Stable ? appliedGroupBy.subject?.uid : undefined,
              startPeriodOffset,
              duration,
              state: BluePrintState.EditCompact,
              activityTypeUid: groupByType === GroupBy.Horse ? lastUsedActivityType?.uid : undefined,
              type: CalendarActivityType.Activity,
            });
          }
        }
      }}
      onMouseDown={e => {
        mouseDown.current = true;
        setTimeout(() => {
          if (mouseDown.current) {
            const reshapingAction = getReshapeAction(e.clientY);

            if (reshapingAction) {
              requestReshaping(reshapingAction);
            } else {
              const bounds = ref.current?.getBoundingClientRect();
              const yOffset = e.clientY - (bounds?.top ?? 0) + (negativeMarginTop ?? 0);
              const startPeriodOffset = pxToDayParts(fullHeight, DayParts.HalfHour, yOffset);
              const duration = Math.min(2, DayParts.HalfHour - startPeriodOffset);
              requestBluePrint({
                day,
                appliedGroupBy,
                horseUid: appliedGroupBy?.groupBy === GroupBy.Horse ? appliedGroupBy.subject?.uid : undefined,
                stableUid: appliedGroupBy?.groupBy === GroupBy.Stable ? appliedGroupBy.subject?.uid : undefined,
                startPeriodOffset,
                duration,
                state: BluePrintState.EditCompact,
                activityTypeUid: groupByType === GroupBy.Horse ? lastUsedActivityType?.uid : undefined,
                type: CalendarActivityType.Activity,
              });
              requestReshaping(Reshaping.ResizeBottom);
            }
          }
        }, 300);
      }}
      onMouseUp={() => {
        mouseDown.current = false;
        setTimeout(() => requestReshaping(undefined), 100);
      }}
      onMouseMove={e => {
        if (!reshaping || !bluePrint) {
          return;
        }
        const yOffset = e.clientY + (negativeMarginTop ?? 0);
        const periods = pxToDayParts(fullHeight, DayParts.HalfHour, getMouseYPos(yOffset));
        if (reshaping === Reshaping.ResizeBottom) {
          requestBluePrint({
            day,
            appliedGroupBy,
            horseUid: appliedGroupBy?.groupBy === GroupBy.Horse ? appliedGroupBy.subject?.uid : undefined,
            stableUid: appliedGroupBy?.groupBy === GroupBy.Stable ? appliedGroupBy.subject?.uid : undefined,
            startPeriodOffset: bluePrint.startPeriodOffset,
            duration: Math.max(1, periods - (bluePrint.startPeriodOffset ?? 0)),
            state: BluePrintState.EditCompact,
            activityTypeUid: groupByType === GroupBy.Horse ? lastUsedActivityType?.uid : undefined,
            type: CalendarActivityType.Activity,
          });
        } else if (reshaping === Reshaping.ResizeTop) {
          if (bluePrint.duration === 1 && periods > (bluePrint.startPeriodOffset ?? 0)) {
            // Stop resizing from top when bypass the bottom.
            return;
          }
          requestBluePrint({
            day,
            appliedGroupBy,
            horseUid: appliedGroupBy?.groupBy === GroupBy.Horse ? appliedGroupBy.subject?.uid : undefined,
            stableUid: appliedGroupBy?.groupBy === GroupBy.Stable ? appliedGroupBy.subject?.uid : undefined,
            startPeriodOffset: periods,
            duration: Math.max(1, (bluePrint.duration ?? 1) + (bluePrint.startPeriodOffset ?? 0) - periods),
            state: BluePrintState.EditCompact,
            type: CalendarActivityType.Activity,
          });
        } else if (reshaping === Reshaping.Move) {
          requestBluePrint({
            day,
            appliedGroupBy,
            horseUid: appliedGroupBy?.groupBy === GroupBy.Horse ? appliedGroupBy.subject?.uid : undefined,
            stableUid: appliedGroupBy?.groupBy === GroupBy.Stable ? appliedGroupBy.subject?.uid : undefined,
            startPeriodOffset: periods,
            duration: bluePrint.duration,
            state: BluePrintState.EditCompact,
            activityTypeUid: groupByType === GroupBy.Horse ? lastUsedActivityType?.uid : undefined,
            type: CalendarActivityType.Activity,
          });
        }
      }}
    >
      <div
        className='w-full relative'
        style={{ marginTop: `-${negativeMarginTop}px`, marginBottom: `-${negativeMarginBottom}px`, height: fullHeight }}
      >
        {sortedActivityColumns.map((column, columnIndex) => {
          const columnWidth = 1 / sortedActivityColumns.length;
          return (
            <div key={columnIndex}>
              {column.map(({ activity, colSpan, posTop, posBottom, zigzagBottom, zigzagTop, compactHeight }) => {
                const primaryAssignee = activity.assignedTo.find(assignee => assignee.primary)?.contact;
                const widthFrac = columnWidth * (1 + colSpan);
                const posFracX = columnWidth * columnIndex;

                return (
                  <ActivityModal
                    style={{
                      position: 'absolute',
                      top: posTop,
                      bottom: posBottom,
                      left: `${Math.round(posFracX * 100)}%`,
                      width: `${Math.round(widthFrac * 100)}%`,
                    }}
                    key={activity.uid + 'activitymodal'}
                    activity={activity}
                    groupByUid={appliedGroupBy?.subject?.uid}
                  >
                    <div
                      style={{
                        backgroundColor: activity.secondaryColor,
                        borderColor: activity.primaryColor,
                        borderLeftWidth: 6,
                        borderRightWidth: 0,
                        borderTopWidth: 0,
                        borderBottomWidth: 0,
                      }}
                      className={classNames(
                        'w-full h-full rounded border items-start pr-1 py-0 cursor-pointer select-none overflow-hidden',
                        {
                          'flex flex-row justify-center': compactHeight,
                          'flex-1 flex-col justify-start': !compactHeight, // flex-1
                        },
                      )}
                      onClick={e => {
                        e.stopPropagation();
                        setSelectedActivity(activity, SelectedActivityState.Info, appliedGroupBy?.subject?.uid);
                        unsetBluePrint();
                      }}
                      draggable={dragDropType === DragDropType.DayPart && !activity.done}
                      onDrag={() => {
                        setDragActivity({ activity, originGroupByUid: appliedGroupBy });
                        unsetBluePrint();
                      }}
                      onDragEnd={() => setDragActivity(undefined)}
                    >
                      <div className={classNames('ml-1 truncate', { grow: compactHeight })}>
                        <p className='text-sm w-full font-medium -mb-1 truncate'>
                          {activityTypeName(activity)} {activity.isAutomaticallyPlanned && <LightningA className='inline' />}
                        </p>
                        <p className='text-xs opacity-90 truncate'>{activity.startEndText}</p>
                        {showHorseName && (
                          <p className='text-xs'>
                            <Horse className='inline-block mb-0.5 mr-[1px]' />
                            {activity.horse?.name}
                          </p>
                        )}
                      </div>
                      {primaryAssignee && showContactAvatar && (
                        <div className={classNames('pl-1', { 'mt-0.5': compactHeight })}>
                          <AvatarInitials size={AvatarSize.XSmall} initials={contactInitials(primaryAssignee)} uuid={primaryAssignee.uid} />
                        </div>
                      )}
                      {activity.done && (
                        <div
                          style={{
                            backgroundColor: activity.primaryColor,
                          }}
                          className='inline-flex items-center justify-center rounded-full text-white p-0.5 mt-1 ml-1'
                        >
                          <Check />
                        </div>
                      )}
                      {zigzagTop && (
                        <div
                          // Inspired from https://css-tip.com/css-zig-zag-edge/
                          className='absolute inset-x-0 top-0 h-2 bg-neutral-50'
                          style={{
                            mask: 'conic-gradient(from calc(90deg/-2) at bottom, #000 90deg,#0000 0) 50%/10px',
                          }}
                        />
                      )}
                      {zigzagBottom && (
                        // Inspired from https://css-tip.com/css-zig-zag-edge/
                        <div
                          className='absolute inset-x-0 bottom-0 h-2 bg-neutral-50 border-b'
                          style={{
                            mask: 'conic-gradient(from calc(180deg - 90deg/2) at top, #0000,#000 1deg 90deg,#0000 calc(90deg + 1deg)) 50%/10px',
                          }}
                        />
                      )}
                    </div>
                  </ActivityModal>
                );
              })}
            </div>
          );
        })}
        {myBluePrint && (
          <BluePrintModal
            style={{
              position: 'absolute',
              top: `${dayPartsToPx(fullHeight, DayParts.HalfHour, myBluePrint.startPeriodOffset ?? 0)}px`,
              height: `${dayPartsToPx(fullHeight, DayParts.HalfHour, myBluePrint.duration ?? 1)}px`,
              left: '0',
              right: '0',
            }}
          >
            <div
              className='h-full w-full bg-blue-500 bg-opacity-10 rounded-lg border-blue-500 border-2 before:h-3 before:w-3 before:bg-blue-500 before:absolute before:inset-x-2 before:-inset-y-1 before:rounded-full before:border-blue-200 before:border-2 after:h-3 after:w-3 after:bg-blue-500 after:absolute after:right-2 after:-bottom-1.5 after:rounded-full after:border-blue-200 after:border-2'
              onClick={e => {
                e.stopPropagation();
              }}
            />
          </BluePrintModal>
        )}
      </div>
      {visibleRange && (
        <>
          {hasActivitiesOutsideWorkingHoursTop && (
            <div className='absolute top-1 right-1 rounded-full w-4 h-4 bg-blue-500 text-white p-0.5 text-xs flex items-start justify-center'>
              <CaretUp className='absolute -top-0.5' />
              <span className='text-[9px]'>{hasActivitiesOutsideWorkingHoursTop}</span>
            </div>
          )}
          {hasActivitiesOutsideWorkingHoursBottom && (
            <div className='absolute bottom-1 right-1 rounded-full w-4 h-4 bg-blue-500 text-white p-0.5 text-xs flex items-end justify-center'>
              <CaretDown className='absolute -bottom-[1px]' />
              <span className='text-[9px]'>{hasActivitiesOutsideWorkingHoursBottom}</span>
            </div>
          )}
          <div
            className='absolute inset-x-0 top-0 h-4 cursor-n-resize'
            onClick={e => {
              e.stopPropagation();
              setHideNonWorkingHours(false);
            }}
          />
          <div
            className='absolute inset-x-0 bottom-0 h-4 cursor-s-resize'
            onClick={e => {
              e.stopPropagation();
              setHideNonWorkingHours(false);
            }}
          />
        </>
      )}
    </div>
  );
}
