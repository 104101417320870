/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Account } from '../models/Account';
import type { Email } from '../models/Email';
import type { EquineMPasswordReset } from '../models/EquineMPasswordReset';
import type { EquineMRegister } from '../models/EquineMRegister';
import type { PasswordChange } from '../models/PasswordChange';
import type { PasswordResetConfirm } from '../models/PasswordResetConfirm';
import type { PatchedAccount } from '../models/PatchedAccount';
import type { RestAuthDetail } from '../models/RestAuthDetail';
import type { SignupResponse } from '../models/SignupResponse';
import type { SignupWithInvitation } from '../models/SignupWithInvitation';
import type { SSO } from '../models/SSO';
import type { TokenObtainPair } from '../models/TokenObtainPair';
import type { TokenRefresh } from '../models/TokenRefresh';
import type { VerifyEmail } from '../models/VerifyEmail';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class AccountService {
  /**
   * @returns Account
   * @throws ApiError
   */
  public static apiV5AccountRetrieve(): CancelablePromise<Account> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v5/account',
    });
  }
  /**
   * @returns Account
   * @throws ApiError
   */
  public static apiV5AccountUpdate({
    requestBody,
  }: {
    requestBody: Account,
  }): CancelablePromise<Account> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/v5/account',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @returns Account
   * @throws ApiError
   */
  public static apiV5AccountPartialUpdate({
    requestBody,
  }: {
    requestBody?: PatchedAccount,
  }): CancelablePromise<Account> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/api/v5/account',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @returns void
   * @throws ApiError
   */
  public static apiV5AccountDestroy(): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/v5/account',
    });
  }
  /**
   * Input:
   * - an emailaddress
   * Output:
   * - a list of accepted login methods.
   *
   * We check the domain of the email. If that domain has only_use_ad you cannot login using email.
   * If that domain is connected to for example microsft, microsoft will be added to the list.
   *
   * Even if a user (email) does not exist we add 'email' to prevent enumeration attacks!
   * @returns SSO
   * @throws ApiError
   */
  public static apiV5CheckSsoCreate({
    requestBody,
  }: {
    requestBody: Email,
  }): CancelablePromise<SSO> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v5/check_sso',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * Takes a set of user credentials and returns an access and refresh JSON web
   * token pair to prove the authentication of those credentials.
   * @returns TokenObtainPair
   * @throws ApiError
   */
  public static apiV5JwtObtainCreate({
    requestBody,
  }: {
    requestBody: TokenObtainPair,
  }): CancelablePromise<TokenObtainPair> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v5/jwt/obtain',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * Takes a refresh type JSON web token and returns an access type JSON web
   * token if the refresh token is valid.
   * @returns TokenRefresh
   * @throws ApiError
   */
  public static apiV5JwtRefreshCreate({
    requestBody,
  }: {
    requestBody: TokenRefresh,
  }): CancelablePromise<TokenRefresh> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v5/jwt/refresh',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * Calls Django Auth SetPasswordForm save method.
   *
   * Accepts the following POST parameters: new_password1, new_password2
   * Returns the success/fail message.
   * @returns RestAuthDetail
   * @throws ApiError
   */
  public static apiV5PasswordChangeCreate({
    requestBody,
  }: {
    requestBody: PasswordChange,
  }): CancelablePromise<RestAuthDetail> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v5/password-change',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * Calls Django Auth PasswordResetForm save method.
   *
   * Accepts the following POST parameters: email
   * Returns the success/fail message.
   * @returns EquineMPasswordReset
   * @throws ApiError
   */
  public static apiV5PasswordResetCreate({
    requestBody,
  }: {
    requestBody: EquineMPasswordReset,
  }): CancelablePromise<EquineMPasswordReset> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v5/password-reset',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * Password reset e-mail link is confirmed, therefore
   * this resets the user's password.
   *
   * Accepts the following POST parameters: token, uid,
   * new_password1, new_password2
   * Returns the success/fail message.
   * @returns RestAuthDetail
   * @throws ApiError
   */
  public static apiV5PasswordResetConfirmCreate({
    requestBody,
  }: {
    requestBody: PasswordResetConfirm,
  }): CancelablePromise<RestAuthDetail> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v5/password-reset-confirm',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * Registers a new user.
   *
   * Accepts the following POST parameters: username, email, password1, password2.
   * @returns EquineMRegister
   * @throws ApiError
   */
  public static apiV5SignupCreate({
    requestBody,
  }: {
    requestBody: EquineMRegister,
  }): CancelablePromise<EquineMRegister> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v5/signup',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * A user can sign up after having received an invitation. The invitation's username and email
   * will be applied to the resulting account.
   * @returns SignupResponse
   * @throws ApiError
   */
  public static apiV5SignupWithInvitationCreate({
    requestBody,
  }: {
    requestBody: SignupWithInvitation,
  }): CancelablePromise<SignupResponse> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v5/signup_with_invitation',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * Verifies the email associated with the provided key.
   *
   * Accepts the following POST parameter: key.
   * @returns RestAuthDetail
   * @throws ApiError
   */
  public static apiV5VerifyEmailCreate({
    requestBody,
  }: {
    requestBody: VerifyEmail,
  }): CancelablePromise<RestAuthDetail> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v5/verify-email',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
}
