import ApiErrorParser from 'api/ApiErrorParser';
import { useOrganization } from 'context/OrganizationContext';
import { usePage } from 'context/PageContext';
import { CancelablePromise, ContactDetail, ContactsService } from 'openapi';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Page, { PageMaxWidth } from '../../ui/Layout/Page';
import { useParams } from 'react-router-dom';
import useModal from 'ui/Modals/UseModal';
import { AppRoutes } from 'AppRoutes';
import UpdateAddressModal from 'components/Contacts/Modals/UpdateAddressModal';
import { Tile } from 'ui/Layout/Tile';
import { Pencil } from '@phosphor-icons/react';
import DescriptionList from 'ui/Layout/DescriptionList';
import { stringArrayToString } from 'utilities/string.utility';
import { contactName } from 'utilities/Contact';
import UpdateLocationModal from 'components/Locations/UpdateLocationModal';
import UseCountries from 'hooks/UseCountries';

export default function LocationDetailPage(): JSX.Element {
  const [contact, setContact] = useState<ContactDetail>();
  const [loading, setLoading] = useState<boolean>(true);

  const { selectedOrganization } = useOrganization();
  const { t } = useTranslation();
  const { setApiError } = usePage();
  const { contactUid } = useParams();
  const { modalIsVisible: generalModalIsVisible, closeModal: closeGeneralModal, showModal: showGeneralModal } = useModal();
  const { modalIsVisible: addressModalIsVisible, closeModal: closeAddressModal, showModal: showAddressModal } = useModal();
  const { countryById } = UseCountries();

  const breadCrumbs = useMemo(() => [AppRoutes.ManageLocations], []);

  /**
   * Get the name of the country
   */
  const country = useMemo((): string => {
    const countryResult = countryById(contact?.country);
    if (countryResult) {
      return countryResult.name;
    }

    return '-';
  }, [contact?.country, countryById]);

  /**
   * Define the page title
   */
  const pageTitle = useMemo((): string => {
    if (!contact) return t('stable', 'Stable');

    return contact && contact.business_name ? contact.business_name : contactName(contact) || t('stable', 'Stable');
  }, [contact, t]);

  /**
   * Get the contact
   */
  const getContact = useCallback(
    (selectedOrganization: string, contactUid: string): CancelablePromise<ContactDetail> => {
      const promise = ContactsService.contactsRetrieve({
        organisationUid: selectedOrganization,
        uid: contactUid,
      });

      promise
        .then(res => setContact(res))
        .catch(e => {
          if (!promise.isCancelled) {
            setApiError(new ApiErrorParser<ContactDetail>(e));
          }
        })
        .finally(() => setLoading(false));

      return promise;
    },
    [setApiError],
  );

  /**
   * Load contact on mount
   */
  useEffect(() => {
    if (!selectedOrganization || !contactUid) return;
    const promise = getContact(selectedOrganization.uid, contactUid);
    return () => promise.cancel();
  }, [contactUid, getContact, selectedOrganization]);

  return (
    <Page title={pageTitle} breadCrumbs={breadCrumbs} maxWidth={PageMaxWidth.Tile}>
      <Tile
        title={t('general', 'General')}
        className='mb-4'
        loading={loading}
        actions={[
          {
            icon: <Pencil />,
            text: t('edit', 'Edit'),
            onClick: showGeneralModal,
          },
        ]}
      >
        <DescriptionList
          list={[
            {
              term: t('name', 'Name'),
              definition: contact?.business_name || '-',
            },
            {
              term: t('UBN', 'UBN'),
              definition: contact?.UBN || '-',
            },
          ]}
        />
      </Tile>

      <Tile
        title={t('address', 'Address')}
        className='mb-4'
        loading={loading}
        actions={[
          {
            icon: <Pencil />,
            text: t('edit', 'Edit'),
            onClick: showAddressModal,
          },
        ]}
      >
        <DescriptionList
          list={[
            {
              term: t('street', 'Street'),
              definition: stringArrayToString([contact?.address_line1, contact?.address_line3], ''),
            },
            {
              term: t('address-line2', 'Address line 2'),
              definition: contact?.address_line2 || '-',
            },
            {
              term: t('postcode', 'Postcode'),
              definition: contact?.postcode || '-',
            },
            {
              term: t('city', 'City'),
              definition: contact?.city || '-',
            },
            {
              term: t('state', 'State'),
              definition: contact?.state || '-',
            },
            {
              term: t('country', 'Country'),
              definition: country || '-',
            },
          ]}
        />
      </Tile>

      {contact && (
        <>
          <UpdateAddressModal
            isVisible={addressModalIsVisible}
            onRequestCloseModal={closeAddressModal}
            contact={contact}
            onUpdated={updatedContact => setContact(updatedContact)}
          />
          <UpdateLocationModal
            isVisible={generalModalIsVisible}
            onRequestCloseModal={closeGeneralModal}
            contact={contact}
            onUpdated={updatedContact => setContact(updatedContact)}
          />
        </>
      )}
    </Page>
  );
}
