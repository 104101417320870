import { CaretRight, Horse as HorseIcon } from '@phosphor-icons/react';
import { AppliedListFilter, FilterOption, ListFilterType } from 'components/Common/ListFilter';
import FilterWrapper from 'components/Common/ListFilter/FilterWrapper';
import useListFilter from 'components/Common/ListFilter/useListFilter';
import HorseSelectButton from 'components/Horses/HorseSelectButton';
import { useOrganization } from 'context/OrganizationContext';
import { CategoriesService, Category, Horse, HorsesService, Product, ProductsService, StallionMount, StallionmountsService } from 'openapi';
import { SexEnum } from 'openapi/models/SexEnum';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { table, tableHiddenColumnMd, tableHiddenHeaderMd, tableTbody, tableTbodyTr, tableTheadTd, tableTheadTdSticky } from 'ui/Const';
import PullScrollWrapper from 'ui/PullScrollWrapper';
import Page from 'ui/Layout/Page';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { Tile } from 'ui/Layout/Tile';
import { ApiPromises } from 'utilities/ApiPromises';
import { getBreedingProducts } from 'components/Breeding/Helpers';

export default function StallionMountInventoryPage(): JSX.Element {
  const { t } = useTranslation();
  const [horses, setHorses] = useState<Horse[]>();
  const [mounts, setMounts] = useState<StallionMount[]>();
  const [products, setProducts] = useState<Product[]>();
  const [financialCategories, setFinancialCategories] = useState<Category[]>();
  const { selectedOrganization, generateCacheKey } = useOrganization();
  const navigate = useNavigate();
  const [apiPromises, setApiPromises] = useState<ApiPromises>();

  // Filter all horses by type Stallion.
  const stallions = useMemo(() => {
    if (!horses) return [];
    return horses.filter(horse => horse.sex === SexEnum._1);
  }, [horses]);

  // A list of types we can filter by.
  const filterTypes = useMemo((): ListFilterType[] | undefined => {
    if (!stallions) {
      return undefined;
    }

    // A list of available stallions to choose from
    const stallionFilterOps: FilterOption[] = stallions.map(stallion => {
      return { id: stallion.uid, name: stallion.name };
    });

    const stallionFilter: ListFilterType = {
      id: 'stallion',
      name: t('stallion', 'Stallion'),
      options: stallionFilterOps, // We don't provide a list of stallions here, we hav a special dropdown for this.
    };

    return [stallionFilter];
  }, [t, stallions]);

  const { filters, setFilters } = useListFilter(filterTypes ?? []);

  // Get the selected stallion based on the filter (url search query).
  const selectedStallionUid = useMemo((): string | undefined => {
    const foundFilter = filters.find(filter => filter.type.id === 'stallion');
    if (!foundFilter) {
      return undefined;
    }
    if (foundFilter.options.length !== 1) {
      return undefined;
    }

    return foundFilter.options[0].id;
  }, [filters]);

  // Get the selected stallion based on the filter (url search query).
  const selectedStallion = useMemo((): Horse | undefined => {
    if (!selectedStallionUid) return undefined;
    return stallions.find(horse => horse.uid === selectedStallionUid);
  }, [stallions, selectedStallionUid]);

  /**
   * Get the breeding products
   */
  const breedingProducts = useMemo(() => {
    return getBreedingProducts(
      financialCategories,
      horses?.filter(horse => horse.hidden === false),
      products,
    );
  }, [financialCategories, horses, products]);

  /**
   * Select a stallion
   */
  const selectStallion = useCallback(
    (horse?: Horse) => {
      if (!filterTypes) {
        return;
      }
      const foundFilterType = filterTypes.find(f => f.id === 'stallion');
      if (!foundFilterType) {
        // should never happen.
        console.error('Filter type not found');
        return;
      }

      // The selection is cleared.
      if (!horse) {
        setFilters([]);
        return;
      }

      const stallionFilter: AppliedListFilter = {
        type: foundFilterType,
        options: [{ id: horse.uid, name: horse.name }],
      };
      setFilters([stallionFilter]);
    },
    [filterTypes, setFilters],
  );

  const formatStallion = (stallionUid: string): string => {
    const found = stallions.find(stallion => stallion.uid === stallionUid);
    if (!found) {
      return '-';
    }
    return found.name;
  };

  /**
   * This will return an array of the sum of the portions fresh and straws frozen available.
   */
  const calculatedMounts = useMemo(() => {
    if (!mounts) {
      return [];
    }

    const mappedMounts: Map<
      string,
      Pick<StallionMount, 'stallion' | 'portions_fresh' | 'portions_fresh_available' | 'straws_frozen' | 'straws_frozen_available'>
    > = new Map();
    for (const mount of mounts) {
      const found = mappedMounts.get(mount.stallion);
      if (found) {
        found.portions_fresh = Number(mount.portions_fresh) + Number(found.portions_fresh);
        found.portions_fresh_available = Number(mount.portions_fresh_available) + Number(found.portions_fresh_available);
        found.straws_frozen = Number(mount.straws_frozen) + Number(found.straws_frozen);
        found.straws_frozen_available = Number(mount.straws_frozen_available) + Number(found.straws_frozen_available);
      } else {
        mappedMounts.set(mount.stallion, {
          stallion: mount.stallion,
          portions_fresh: mount.portions_fresh,
          portions_fresh_available: mount.portions_fresh_available,
          straws_frozen: mount.straws_frozen,
          straws_frozen_available: mount.straws_frozen_available,
        });
      }
    }

    // we also need to check the breeding products and check if the stallion does not exists in the mappedMounts
    // if not, we create a new entry with 0 portions and straws.
    if (!selectedStallionUid) {
      breedingProducts
        .filter(product => product.hidden === false)
        .forEach(product => {
          if (!mappedMounts.has(String(product.stallion))) {
            mappedMounts.set(String(product.stallion), {
              stallion: String(product.stallion),
              portions_fresh: 0,
              portions_fresh_available: 0,
              straws_frozen: 0,
              straws_frozen_available: 0,
            });
          }
        });
    }

    return Array.from(mappedMounts.values());
  }, [breedingProducts, mounts, selectedStallionUid]);

  // Load data from the api/cache
  const loadApiData = useCallback((): ApiPromises => {
    const promises = new ApiPromises();

    if (!selectedOrganization) {
      return promises;
    }
    promises.appendList<StallionMount>(
      'mounts',
      () =>
        StallionmountsService.stallionmountsList({
          stallionOrganisationUid: selectedOrganization.uid,
          stallionUid: selectedStallionUid,
          portionsFreshAvailableGte: 0,
          strawsFrozenAvailableGte: 0,
        }),
      setMounts,
    );

    promises.appendList<Category>(
      'financialCategories',
      () =>
        CategoriesService.categoriesList({
          organisationUid: selectedOrganization.uid,
          o: 'p_s_type,name',
        }),
      setFinancialCategories,
    );

    promises.appendList<Product>(
      'products',
      () =>
        ProductsService.productsList({
          organisationUid: selectedOrganization.uid,
        }),
      setProducts,
    );

    promises.appendList<Horse>(
      'webshop-horses',
      () => {
        return HorsesService.horsesList({
          organisationUid: selectedOrganization.uid,
          onUnknownLocation: false,
        });
      },
      setHorses,
      generateCacheKey('webshop-horses'),
    );

    setApiPromises(promises);

    return promises;
  }, [selectedOrganization, generateCacheKey, selectedStallionUid]);

  // Load from the api
  useEffect(() => {
    if (selectedOrganization) {
      const promise = loadApiData();
      return () => promise.cancel();
    }
  }, [selectedOrganization, selectedStallionUid]); //eslint-disable-line

  return (
    <>
      <Page title={t('semen-inventory', 'Semen inventory')} loading={apiPromises}>
        <PullScrollWrapper apiPromises={apiPromises}>
          <Tile noBoxOnMobile={true} overflowContent={true}>
            {filterTypes && horses && (
              <FilterWrapper>
                <HorseSelectButton selectedHorse={selectedStallion} stallionsOnly={true} horses={horses} horseSelected={selectStallion} />
              </FilterWrapper>
            )}
            <table className={table}>
              <thead>
                <tr className={tableHiddenHeaderMd}>
                  <td className={classNames('w-10', tableTheadTdSticky)} />
                  <td className={tableTheadTdSticky}>{t('stallion', 'Stallion')}</td>
                  <td className={classNames(tableTheadTd, tableTheadTdSticky)}>{t('semen-portions-fresh', 'Portions fresh')}</td>
                  <td className={classNames(tableTheadTd, tableTheadTdSticky)}>{t('semen-straws-frozen', 'Straws frozen')}</td>
                  <td className='w-10 md:hidden' />
                </tr>
              </thead>
              <tbody className={tableTbody}>
                {calculatedMounts.map(mount => {
                  return (
                    <tr className={tableTbodyTr} key={mount.stallion} onClick={() => navigate(mount.stallion)}>
                      <td className='text-center w-10'>
                        <HorseIcon size={24} weight='light' className='inline' />
                      </td>
                      <td className={tableHiddenColumnMd}>{formatStallion(mount.stallion)}</td>
                      <td className={tableHiddenColumnMd}>{mount.portions_fresh_available ?? 0}</td>
                      <td className={tableHiddenColumnMd}>{mount.straws_frozen_available ?? 0}</td>
                      <td className={classNames('md:hidden')}>
                        <div className='flex items-center p-2 gap-2'>
                          <div className='flex flex-col grow'>
                            <p>{formatStallion(mount.stallion)}</p>
                            <p className='text-sm'>
                              {t('semen-portions-fresh', 'Portions fresh')}: {mount.portions_fresh_available ?? 0}
                            </p>
                            <p className='text-sm'>
                              {t('semen-straws-frozen', 'Straws frozen')}: {mount.straws_frozen_available ?? 0}
                            </p>
                          </div>

                          <CaretRight size={22} weight='light' className='inline' />
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </Tile>
        </PullScrollWrapper>
      </Page>
    </>
  );
}
