import classNames from 'classnames';
import React, { ReactNode } from 'react';

export enum DescriptionListMode {
  Default = 'default', // default padding is used
  Compact = 'compact', // compact padding is used
}

export interface DefinitionItem {
  term: string;
  definition: ReactNode;
  className?: string;
  classNameDT?: string;
  classNameDD?: string;
  mode?: DescriptionListMode; // the mode defines how the padding is applied
  isVisible?: boolean;
}

/**
 * Render a single label and definition.
 */
function DescriptionListItem({
  definition,
  term,
  className,
  classNameDT,
  classNameDD,
  mode = DescriptionListMode.Default,
  isVisible = true,
}: DefinitionItem): JSX.Element {
  if (!isVisible) {
    return <></>;
  }

  return (
    <div
      className={classNames('sm:flex sm:gap-4 sm:px-0 leading-6', className, {
        'py-4': mode === DescriptionListMode.Default,
        'py-2': mode === DescriptionListMode.Compact,
      })}
    >
      <dt className={classNames(classNameDT, 'font-medium text-gray-900 sm:w-1/3 shrink-0 overflow-x-hidden')}>{term}</dt>
      <dd className={classNames(classNameDD, 'text-gray-700 sm:mt-0')}>{definition}</dd>
    </div>
  );
}

export default DescriptionListItem;
