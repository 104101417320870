import { DotsThreeVertical, Plus } from '@phosphor-icons/react';
import { ActivityType, ActivitytypesService, CategoryEnum, Role, RolesService } from 'openapi';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonVariant } from 'ui/Button';
import { Tile } from 'ui/Layout/Tile';
import useModal from 'ui/Modals/UseModal';
import { table, tableHiddenHeaderMd, tableTbody, tableTbodyTrNoClick, tableThead, tableTheadTd } from 'ui/Const';
import DropdownMenu from 'ui/DropdownMenu';
import { ApiPromises } from 'utilities/ApiPromises';
import { useOrganization } from 'context/OrganizationContext';
import DeleteActivityTypeModal from './DeleteActivityTypeModal';
import SaveActivityTypeModal from './SaveActivityTypeModal';
import classNames from 'classnames';
import Badge from 'ui/Badge';
import { AllColors } from 'utilities/colors';
import { BreedingBadge, CareBadge, SportBadge } from 'components/Horses/HorseUsageBadges';
import { BadgeSize } from 'ui/Badge/Badge';

export default function SettingsActivityTypesTile(): JSX.Element {
  const { selectedOrganizationUid, generateCacheKey } = useOrganization();
  const { t } = useTranslation();

  const { showModal: showSaveModal, closeModal: closeSaveModal, modalIsVisible: saveModalIsVisible } = useModal();
  const { showModal: showDeleteModal, closeModal: closeDeleteModal, modalIsVisible: deleteModalIsVisible } = useModal();

  const [activityTypes, setActivityTypes] = useState<ActivityType[]>();
  const [roles, setRoles] = useState<Role[]>();
  const [apiPromises, setApiPromises] = useState<ApiPromises>();
  const [selectedType, setSelectedType] = useState<ActivityType | undefined>();

  // Load data from the api/cache
  const loadApiData = useCallback((): ApiPromises => {
    const promises = new ApiPromises();

    if (!selectedOrganizationUid) {
      return promises;
    }
    promises.appendList<ActivityType>(
      'activity-types',
      () =>
        ActivitytypesService.activitytypesList({
          organisationUid: selectedOrganizationUid,
        }),
      setActivityTypes,
    );
    promises.appendList<Role>(
      'roles',
      () =>
        RolesService.rolesList({
          organisationUid: selectedOrganizationUid,
        }),
      setRoles,
      generateCacheKey('roles'),
    );
    setApiPromises(promises);
    return promises;
  }, [selectedOrganizationUid, generateCacheKey]);

  // Load from the api
  useEffect(() => {
    if (selectedOrganizationUid) {
      const promise = loadApiData();
      return () => promise.cancel();
    }
  }, [selectedOrganizationUid]); //eslint-disable-line

  /**
   * Return the actions for the tile
   */
  const tileActions = useMemo(() => {
    return [
      {
        onClick: () => {
          setSelectedType(undefined);
          showSaveModal();
        },
        text: t('add', 'Add'),
        buttonVariant: ButtonVariant.Default,
        icon: <Plus />,
      },
    ];
  }, [showSaveModal, t]);

  return (
    <Tile title={t('activity-types', 'Activity types')} loading={apiPromises} actions={tileActions}>
      <table className={table}>
        <thead className={tableThead}>
          <tr className={tableHiddenHeaderMd}>
            <td className={tableTheadTd}>{t('name', 'Name')}</td>
            <td className={tableTheadTd}>{t('category', 'Category')}</td>
            <td className='w-10' />
          </tr>
        </thead>
        <tbody className={tableTbody}>
          {activityTypes?.map(activityType => (
            <tr className={tableTbodyTrNoClick} key={activityType.uid}>
              <td>
                <span className={classNames({ 'line-through': activityType.hidden })}>{activityType.name}</span>{' '}
                {activityType.hidden && <Badge color={AllColors.Rose}>{t('disabled', 'Disabled')}</Badge>}
              </td>
              <td>
                <div className={classNames('flex', { 'opacity-30': activityType.hidden })}>
                  {activityType.category === CategoryEnum.BREEDING && <BreedingBadge size={BadgeSize.Normal} />}
                  {activityType.category === CategoryEnum.CARE && <CareBadge size={BadgeSize.Normal} />}
                  {activityType.category === CategoryEnum.SPORT && <SportBadge size={BadgeSize.Normal} />}
                </div>
              </td>
              <td align='right'>
                <DropdownMenu
                  menuPlacement='right-end'
                  menuItems={[
                    [
                      {
                        element: t('edit', 'Edit'),
                        onClick: () => {
                          setSelectedType(activityType);
                          showSaveModal();
                        },
                      },
                    ],
                    [
                      {
                        element: activityType.default ? t('disable', 'Disable') : t('remove', 'Remove'),
                        className: 'text-red-600',
                        isVisible: !activityType.hidden,
                        onClick: () => {
                          setSelectedType(activityType);
                          showDeleteModal();
                        },
                      },
                    ],
                  ]}
                >
                  <button className='px-2'>
                    <DotsThreeVertical size={20} weight='bold' />
                  </button>
                </DropdownMenu>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <SaveActivityTypeModal
        isVisible={saveModalIsVisible}
        onRequestClose={() => {
          closeSaveModal();
          setSelectedType(undefined);
        }}
        onSaved={() => {
          setSelectedType(undefined);
          loadApiData();
        }}
        activityType={selectedType}
        roles={roles ?? []}
      />
      <DeleteActivityTypeModal
        isVisible={deleteModalIsVisible}
        onRequestClose={() => {
          closeDeleteModal();
          setSelectedType(undefined);
        }}
        onDeleted={() => {
          setSelectedType(undefined);
          loadApiData();
        }}
        activityType={selectedType}
      />
    </Tile>
  );
}
