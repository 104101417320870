import { PencilSimple, TrashSimple } from '@phosphor-icons/react';
import ApiErrorParser from 'api/ApiErrorParser';
import { AppRoutes } from 'AppRoutes';
import { useAccount } from 'context/AccountContext';
import { useOrganization } from 'context/OrganizationContext';
import { usePage } from 'context/PageContext';
import {
  CancelablePromise,
  Contact,
  Horse,
  HorseDetail,
  HorsesService,
  SexEnum,
  StallionMount,
  StallionMountDetail,
  StallionmountsService,
} from 'openapi';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import Button, { ButtonVariant } from 'ui/Button';
import { ErrorSection } from 'ui/Error';
import DescriptionList from 'ui/Layout/DescriptionList';
import Page, { navBackToThisPage, PageMaxWidth } from 'ui/Layout/Page';
import { Tile } from 'ui/Layout/Tile';
import { ActionModal } from 'ui/Modals';
import { NavLink, generatePath } from 'react-router-dom';
import SaveStallionMount from 'components/Breeding/SaveStallionMount';
import useModal from 'ui/Modals/UseModal';
import { totalMotileSpermCount } from 'utilities/Breeding';
import { ApiPromises } from 'utilities/ApiPromises';
import { activeContacts, organizationHorses } from 'utilities/ApiRequests';
import { contactName } from 'utilities/Contact';

// Small formula 'image' of the TMSC calculation.,
function TmscFormula(): JSX.Element {
  const { t } = useTranslation();
  return (
    <div className='hidden md:flex flex-row gap-1 items-center border p-2 text-xs'>
      <p className='font-serif'>{t('tmsc', 'TMSC')}</p>
      <p>{'='}</p>
      <p className='font-serif'>{t('concentration', 'Concentration')}</p>
      <p>{'×'}</p>
      <p className='font-serif'>{t('volume', 'Volume')}</p>
      <p>{'×'}</p>
      <div className='flex flex-col items-center'>
        <p className='font-serif'>{t('moving-cells', 'Moving cells')}</p>
        <div className='w-full h-[1px] bg-black' />
        <p className='font-serif'>100</p>
      </div>
      <p>{'×'}</p>
      <div className='flex flex-col items-center'>
        <p className='font-serif'>{t('morphology', 'Morphology')}</p>
        <div className='w-full h-[1px] bg-black' />
        <p className='font-serif'>100</p>
      </div>
    </div>
  );
}

// A page that shows the details of a specific stallion mount. It also has to
// actions to remove or edit the mount.
export default function StallionMountDetailsPage(): JSX.Element {
  const { t } = useTranslation();
  const [stallion, setStallion] = useState<HorseDetail>();
  const [horses, setHorses] = useState<Horse[]>();
  const [contacts, setContacts] = useState<Contact[]>();
  const [apiPromises, setApiPromises] = useState<ApiPromises>();
  const [mount, setMount] = useState<StallionMountDetail>();
  const [removeMountDialogError, setRemoveMountDialogError] = useState<ApiErrorParser<void> | undefined>();
  const [destroyFreshDialogError, setDestroyFreshDialogError] = useState<ApiErrorParser<StallionMount> | undefined>();
  const [destroyFrozenDialogError, setDestroyFrozenDialogError] = useState<ApiErrorParser<StallionMount> | undefined>();

  const { closeModal: closeRemoveMountDialog, modalIsVisible: updateRemoveMountDialog, showModal: showRemoveMountDialog } = useModal();
  const { closeModal: closeUpdateMountModal, modalIsVisible: updateMountModalIsVisible, showModal: showUpdateMountModal } = useModal();
  const { closeModal: closeDestroyFreshModal, modalIsVisible: destroyFreshModalIsVisible, showModal: showDestroyFreshModal } = useModal();
  const {
    closeModal: closeDestroyFrozenModal,
    modalIsVisible: destroyFrozenModalIsVisible,
    showModal: showDestroyFrozenModal,
  } = useModal();

  const { formatDate, formatDateTime } = useAccount();
  const { selectedOrganization, selectedOrganizationUid, generateCacheKey } = useOrganization();
  const { setApiError } = usePage();
  const { uid } = useParams();
  const navigate = useNavigate();
  const { formatNumber } = useAccount();

  /*
   * Calculate the Total Motile Sperm Count in millions.
   */
  const tmsc = useMemo(() => {
    if (!mount) {
      return '';
    }
    const result = totalMotileSpermCount(mount.concentration, mount.volume, mount.moving_cells_percentage, mount.morphology);
    if (!result) {
      return t('cannot-be-calculated', "Can't be calculated");
    }
    if (result === 0) {
      return t('no motile sperm', 'No motile sperm');
    }
    return t('million-motile-sperm-count', '{{tmsc}} million motile sperm count', { tmsc: formatNumber(result) });
  }, [t, mount, formatNumber]);

  const loadApiData = useCallback((): ApiPromises => {
    const promises = new ApiPromises();

    if (!selectedOrganizationUid) {
      return promises;
    }

    promises.appendListObj<Horse>('horses', setHorses, organizationHorses(selectedOrganizationUid, generateCacheKey));
    promises.appendListObj<Contact>('contacts', setContacts, activeContacts(selectedOrganizationUid, generateCacheKey));
    promises.appendSingle<StallionMountDetail>(
      'mount',
      () =>
        StallionmountsService.stallionmountsRetrieve({
          stallionOrganisationUid: selectedOrganizationUid,
          uid: uid ?? '',
        }),
      setMount,
    );
    setApiPromises(promises);
    return promises;
  }, [generateCacheKey, selectedOrganizationUid, uid]);

  // Load the stallion. Do it in a separate call to ensure we have the right one.
  const loadStallion = useCallback(
    (stallionUid: string): CancelablePromise<HorseDetail> => {
      const promise = HorsesService.horsesRetrieve({
        organisationUid: selectedOrganizationUid ?? '',
        uid: stallionUid,
      });
      promise
        .then(result => {
          setApiError(undefined);
          setStallion(result);
        })
        .catch(e => {
          if (!promise.isCancelled) {
            setApiError(new ApiErrorParser<HorseDetail>(e));
          }
        });
      return promise;
    },
    [selectedOrganizationUid, setApiError],
  );

  // Load api data
  useEffect(() => {
    if (selectedOrganizationUid) {
      const promise = loadApiData();
      return () => promise.cancel();
    }
  }, [selectedOrganizationUid]); //eslint-disable-line

  // Load the stallion
  useEffect(() => {
    if (selectedOrganizationUid && mount && !stallion) {
      const promise = loadStallion(mount.stallion);
      return () => promise.cancel();
    }
  }, [selectedOrganizationUid, mount]); //eslint-disable-line

  const semenCollectionStation = useMemo(() => {
    if (!stallion || !contacts) {
      return undefined;
    }
    return contacts.find(contact => contact.uid === stallion.default_semen_collection_station);
  }, [stallion, contacts]);

  const actions = useMemo(() => {
    return [
      {
        onClick: showUpdateMountModal,
        text: t('edit', 'Edit'),
        icon: <PencilSimple />,
      },
      {
        onClick: showRemoveMountDialog,
        text: t('remove-mount', 'Remove mount'),
        buttonVariant: ButtonVariant.Danger,
        icon: <TrashSimple />,
      },
    ];
  }, [t, showRemoveMountDialog, showUpdateMountModal]);

  // Remove a mount and navigate back to the mount list.
  const removeMount = async (): Promise<void> => {
    const promise = StallionmountsService.stallionmountsDestroy({
      stallionOrganisationUid: selectedOrganization?.uid ?? '',
      uid: uid ?? '',
    });
    try {
      await promise;
      closeRemoveMountDialog();
      navigate(AppRoutes.MountList.path);
    } catch (e) {
      setRemoveMountDialogError(new ApiErrorParser(e));
    }
  };

  const destroyRemainingFreshPortions = async (): Promise<void> => {
    try {
      await StallionmountsService.stallionmountsDestroyFreshPartialUpdate({
        stallionOrganisationUid: selectedOrganization?.uid ?? '',
        uid: uid ?? '',
      });
      await loadApiData();
      closeDestroyFreshModal();
    } catch (e) {
      setDestroyFreshDialogError(new ApiErrorParser<StallionMount>(e));
    }
  };

  const destroyRemainingFrozenStraws = async (): Promise<void> => {
    try {
      await StallionmountsService.stallionmountsDestroyFrozenPartialUpdate({
        stallionOrganisationUid: selectedOrganization?.uid ?? '',
        uid: uid ?? '',
      });
      await loadApiData();
      closeDestroyFrozenModal();
    } catch (e) {
      setDestroyFrozenDialogError(new ApiErrorParser<StallionMount>(e));
    }
  };

  const breadCrumbs = useMemo(() => [AppRoutes.MountList], []);
  return (
    <Page
      title={`${t('mount', 'Mount')} ${mount?.uid ?? ''}`}
      loading={apiPromises}
      breadCrumbs={breadCrumbs}
      maxWidth={PageMaxWidth.Tile}
      actions={actions}
    >
      <Tile title={t('general', 'General')}>
        {mount && (
          <DescriptionList
            list={[
              { term: t('mount-identifier', 'Mount identifier'), definition: mount.uid },
              {
                term: t('stallion', 'Stallion'),
                definition: (
                  <NavLink
                    className='text-blue-500'
                    to={{
                      pathname: generatePath(AppRoutes.HorsesDetails.path, { uid: stallion?.uid ?? '' }),
                      search: navBackToThisPage().toString(),
                    }}
                  >
                    {stallion?.name}
                  </NavLink>
                ),
              },
              {
                term: t('semen-collection-station', 'Semen collection station'),
                definition: semenCollectionStation ? contactName(semenCollectionStation) : '-',
              },
              { term: t('collection-date', 'Collection date'), definition: formatDate(mount.collection_date ?? '') },
              { term: t('volume', 'Volume'), definition: mount.volume + ' ml' },
              { term: t('concentration', 'Concentration'), definition: `${mount.concentration} ${t('million-per-ml', 'million/ml')}` },
              { term: t('moving-cells', 'Moving cells'), definition: mount.moving_cells_percentage + '%' },
              { term: t('morphology', 'Morphology'), definition: mount.morphology ? mount.morphology + '%' : '-' },
              {
                term: t('total-motile-sperm-count', 'TMSC (Total Motile Sperm Count)'),
                definition: (
                  <div className='space-y-2'>
                    <p>{tmsc}</p>
                    <TmscFormula />
                  </div>
                ),
              },
              {
                term: t('portions-fresh', 'Portions fresh'),
                definition: (
                  <div className='flex flex-col gap-2'>
                    <p>{`${mount.portions_fresh} (${mount.portions_fresh_available} ${t('in-stock', 'in stock')})`}</p>
                    {(mount.portions_fresh_available ?? 0) > 0 && (
                      <Button variant={ButtonVariant.Danger} onClick={showDestroyFreshModal}>
                        {t('destroy-remaining-stock', 'Destroy remaining stock')}
                      </Button>
                    )}
                    {(mount.portions_fresh_destroyed_amount ?? 0) > 0 && mount.portions_fresh_destroyed_on && (
                      <p className='text-rose-500'>
                        {t(
                          'fresh-portions-destroyed-on',
                          '{{portions_fresh_destroyed_amount}} portions destroyed on {{portions_fresh_destroyed_on}}.',
                          {
                            portions_fresh_destroyed_amount: mount.portions_fresh_destroyed_amount,
                            portions_fresh_destroyed_on: formatDateTime(new Date(mount.portions_fresh_destroyed_on)),
                          },
                        )}
                      </p>
                    )}
                  </div>
                ),
              },
              {
                term: t('straws-frozen', 'Straws frozen'),
                definition: (
                  <div className='flex flex-col gap-2'>
                    <p>{`${mount.straws_frozen} (${mount.straws_frozen_available} ${t('in-stock', 'in stock')})`}</p>
                    {(mount.straws_frozen_available ?? 0) > 0 && (
                      <Button variant={ButtonVariant.Danger} onClick={showDestroyFrozenModal}>
                        {t('destroy-remaining-stock', 'Destroy remaining stock')}
                      </Button>
                    )}
                    {(mount.straws_frozen_destroyed_amount ?? 0) > 0 && mount.straws_frozen_destroyed_on && (
                      <p className='text-rose-500'>
                        {t(
                          'frozen-straws-destroyed-on',
                          '{{straws_frozen_destroyed_amount}} straws destroyed on {{straws_frozen_destroyed_on}}.',
                          {
                            straws_frozen_destroyed_amount: mount.straws_frozen_destroyed_amount,
                            straws_frozen_destroyed_on: formatDateTime(new Date(mount.straws_frozen_destroyed_on)),
                          },
                        )}
                      </p>
                    )}
                  </div>
                ),
              },
              { term: t('storage-container', 'Storage container'), definition: mount.storage_container },
              { term: t('storage-canister', 'Storage canister'), definition: mount.storage_canister },
              { term: t('straw-color', 'Straw color'), definition: (mount.NIFA_straw_color ?? '').toString().replaceAll('_', ' ') },
            ]}
          />
        )}
      </Tile>
      {mount && horses && (
        <SaveStallionMount
          stallions={horses.filter(horse => horse.sex === SexEnum._1)}
          contacts={contacts ?? []}
          existingMount={mount}
          open={updateMountModalIsVisible}
          onSaved={(reload: boolean) => {
            if (reload) {
              loadApiData();
            }
          }}
          onRequestClose={closeUpdateMountModal}
        />
      )}
      <ActionModal
        open={updateRemoveMountDialog}
        actions={[
          {
            text: t('cancel', 'Cancel'),
            variant: ButtonVariant.Default,
            onClick: closeRemoveMountDialog,
          },
          {
            text: t('remove', 'Remove'),
            variant: ButtonVariant.PrimaryDanger,
            onClick: removeMount,
          },
        ]}
        title={t('remove-mount-confirm-title', 'Remove mount')}
      >
        <>
          <ErrorSection errors={removeMountDialogError} />
          <p>{t('remove-mount-confirm-text', 'Are you sure you want to remove this stallion mount?')}</p>
        </>
      </ActionModal>
      <ActionModal
        open={destroyFreshModalIsVisible}
        actions={[
          {
            text: t('cancel', 'Cancel'),
            variant: ButtonVariant.Default,
            onClick: closeDestroyFreshModal,
          },
          {
            text: t('destroy', 'Destroy'),
            variant: ButtonVariant.PrimaryDanger,
            onClick: destroyRemainingFreshPortions,
          },
        ]}
        title={t('destroy-fresh-confirm-title', 'Destroy remaining fresh stock')}
      >
        <ErrorSection errors={destroyFreshDialogError} />
        <p>{t('destroy-fresh-confirm-text', 'Are you sure you want to destroy the remaining fresh stock for this stallion mount?')}</p>
      </ActionModal>
      <ActionModal
        open={destroyFrozenModalIsVisible}
        actions={[
          {
            text: t('cancel', 'Cancel'),
            variant: ButtonVariant.Default,
            onClick: closeDestroyFrozenModal,
          },
          {
            text: t('destroy', 'Destroy'),
            variant: ButtonVariant.PrimaryDanger,
            onClick: destroyRemainingFrozenStraws,
          },
        ]}
        title={t('destroy-frozen-confirm-title', 'Destroy remaining frozen stock')}
      >
        <ErrorSection errors={destroyFrozenDialogError} />
        <p>{t('destroy-frozen-confirm-text', 'Are you sure you want to destroy the remaining frozen stock for this stallion mount?')}</p>
      </ActionModal>
    </Page>
  );
}
