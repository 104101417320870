import { Listbox, Transition } from '@headlessui/react';
import { CaretUpDown, Check } from '@phosphor-icons/react';
import { t } from 'i18next';
import React, { Fragment, useMemo, useState } from 'react';
import { useOrganization } from '../../../context/OrganizationContext';
import { ButtonVariant } from '../../../ui/Button';
import Spinner, { SpinnerSize } from '../../../ui/Loading/Spinner';
import { ActionModal } from '../../../ui/Modals';

export default function OrganizationSelector(): JSX.Element {
  const [switchErrorMessage, setSwitchErrorMessage] = useState<string | undefined>();
  const { loading, selectedOrganization, organizations, setSelectedOrganization } = useOrganization();

  const title = useMemo((): string => {
    return selectedOrganization ? selectedOrganization.name : '';
  }, [selectedOrganization]);

  if (organizations.length === 1) {
    return <div className='ml-11 md:ml-0 w-full p-2 select-none truncate'>{selectedOrganization?.name}</div>;
  }

  return (
    <>
      <Listbox value={selectedOrganization?.uid ?? ''} onChange={setSelectedOrganization}>
        <Listbox.Button className='ml-11 md:ml-0 w-full cursor-pointer select-none px-2 py-1.5 bg-white bg-opacity-10 rounded flex items-center'>
          <div className={'w-full text-left'}>
            <p className={'opacity-90 overflow-hidden flex items-center gap-1'}>{title}</p>
          </div>
          {loading && <Spinner size={SpinnerSize.XSmall} />}
          {!loading && (
            <div className='flex items-center'>
              <CaretUpDown size={20} className={'opacity-60'} />
            </div>
          )}
        </Listbox.Button>
        <Transition as={Fragment} leave='transition ease-in duration-100' leaveFrom='opacity-100' leaveTo='opacity-0'>
          <Listbox.Options className='absolute mt-8 overflow-auto rounded-md bg-white text-gray-900 py-1 shadow-lg z-10'>
            {organizations.map(option => (
              <Listbox.Option
                key={option.uid}
                className={({ active }) =>
                  `relative cursor-default select-none py-2 px-10 ${active ? 'bg-gray-100' : ''} border-b last:border-b-0`
                }
                value={option.uid}
              >
                <span className={`flex gap-1 items-center`}>{option.name}</span>

                {selectedOrganization?.uid === option.uid && (
                  <span className='absolute inset-y-0 left-0 flex items-center pl-3 text-primary'>
                    <Check className='h-5 w-5 text-primary' aria-hidden='true' />
                  </span>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </Listbox>
      {switchErrorMessage && (
        <ActionModal
          open={switchErrorMessage !== undefined}
          actions={[
            {
              text: 'Close',
              variant: ButtonVariant.Danger,
              onClick: () => {
                setSwitchErrorMessage(undefined);
              },
            },
          ]}
          onClose={() => setSwitchErrorMessage(undefined)}
          title={t('switch-org-error', 'Failed to switch organization')}
        >
          <p>{t('switch-org-error-desc', 'Could not switch due to the following error message')}:</p>
          <p className='mt-2 font-medium w-full'>{switchErrorMessage}</p>
        </ActionModal>
      )}
    </>
  );
}
