import { zodResolver } from '@hookform/resolvers/zod';
import { Pencil } from '@phosphor-icons/react';
import ApiErrorParser from 'api/ApiErrorParser';
import useFormError from 'api/hooks/useFormError';
import { useOrganization } from 'context/OrganizationContext';
import { Organisation, OrganisationService, PatchedOrganisation, TimezoneEnum } from 'openapi';
import { schemas } from 'openapi/zod-schemas';
import React, { useEffect, useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Badge from 'ui/Badge';
import { ButtonVariant } from 'ui/Button';
import { ErrorSection } from 'ui/Error';
import { SelectInput, TextInput } from 'ui/Inputs';
import { Tile } from 'ui/Layout/Tile';
import TileDescriptionList from 'ui/Layout/Tile/TileDescriptionList';
import { PageModal } from 'ui/Modals';
import { PageModalActions, PageModalContent, PageModalTitle } from 'ui/Modals/PageModal';
import useModal from 'ui/Modals/UseModal';
import { zodInputIsRequired } from 'utilities/zod';
import { z } from 'zod';

export default function InfoTile(): JSX.Element {
  const [loading, setLoading] = useState<boolean>(false);

  const { t } = useTranslation();
  const { selectedOrganizationDetails, selectedOrganizationUid, refresh } = useOrganization();

  /**
   * Define the default values
   */
  const defaultValues = useMemo(() => {
    return {
      name: selectedOrganizationDetails?.name,
      phone_number: selectedOrganizationDetails?.phone_number,
      website: selectedOrganizationDetails?.website,
      timezone: selectedOrganizationDetails?.timezone,
    };
  }, [selectedOrganizationDetails]);

  const schema = useMemo(() => {
    return schemas.Organisation.pick({
      name: true,
      phone_number: true,
      timezone: true,
    }).extend({
      // We cannot use the current .url() schema as this will fail to parse an empty string
      // See https://github.com/colinhacks/zod/issues/2513#issuecomment-1732405993
      website: z.union([z.literal(''), z.string().max(200).url().optional()]),
    });
  }, []);

  const { showModal, closeModal, modalIsVisible } = useModal();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    clearErrors,
  } = useForm<Organisation>({
    resolver: zodResolver(schema),
    reValidateMode: 'onChange',
    defaultValues,
  });

  const { fieldError, nonFieldErrors, setApiError } = useFormError(schema, errors);

  const timezones = useMemo(() => {
    return Object.values(TimezoneEnum).map(timezone => ({ id: timezone, name: timezone }));
  }, []);

  /**
   * Close event for the modal
   */
  const onClose = () => {
    // clear the errors
    clearErrors();
    setApiError(undefined);
    closeModal();
  };

  /**
   * Submit event handler, update the data via the API for this organization
   */
  const onSubmit: SubmitHandler<PatchedOrganisation> = async (data: PatchedOrganisation) => {
    if (!selectedOrganizationUid) return;

    setLoading(true);

    try {
      await OrganisationService.rootPartialUpdate({ uid: selectedOrganizationUid, requestBody: data });
      // refresh the organization
      refresh();

      // close the modal
      onClose();
    } catch (error) {
      setApiError(new ApiErrorParser<PatchedOrganisation>(error));
    }

    setLoading(false);
  };

  /**
   * Return the actions for the tile
   */
  const tileActions = useMemo(() => {
    return [
      {
        onClick: showModal,
        text: t('edit', 'Edit'),
        buttonVariant: ButtonVariant.Default,
        icon: <Pencil />,
      },
    ];
  }, [showModal, t]);

  /**
   * Type of organization
   */
  const type = useMemo((): string | undefined => {
    if (!selectedOrganizationDetails?.type_of_organisation) {
      return undefined;
    }
    const types = ['Unknown', 'Private', 'Professional'];
    return types[Number(selectedOrganizationDetails?.type_of_organisation)];
  }, [selectedOrganizationDetails]);

  /**
   * Fill in the form with the selected organization
   */
  useEffect(() => {
    reset(defaultValues);
  }, [reset, defaultValues]);

  return (
    <Tile title={t('general', 'General')} loading={!selectedOrganizationDetails} actions={tileActions}>
      <TileDescriptionList
        list={[
          {
            term: t('organization-name', 'Organization name'),
            definition: (
              <>
                {selectedOrganizationDetails?.name || '-'} {type && <Badge>{type}</Badge>}
              </>
            ),
          },
          {
            term: t('website', 'Website'),
            definition: selectedOrganizationDetails?.website || '-',
          },
          {
            term: t('phone-number', 'Phone number'),
            definition: selectedOrganizationDetails?.phone_number || '-',
          },
          {
            term: t('timezone', 'Timezone'),
            definition: selectedOrganizationDetails?.timezone || '-',
          },
        ]}
      />

      <PageModal
        open={modalIsVisible}
        parentElement='form'
        parentProps={{ id: 'updateOrganization', noValidate: true, onSubmit: handleSubmit(onSubmit) }}
      >
        <PageModalTitle title={t('update-organization', 'Update organization')} onClose={onClose} />
        <PageModalContent>
          <ErrorSection errors={nonFieldErrors} />
          <div className='space-y-4'>
            <TextInput
              error={fieldError('name')}
              required={zodInputIsRequired<Organisation>(schema, 'name')}
              label={t('organization-name', 'Organization name')}
              {...register('name')}
            />
            <TextInput
              error={fieldError('website')}
              required={zodInputIsRequired<Organisation>(schema, 'website')}
              hint={t('website-hint', 'http://example.com')}
              label={t('website', 'Website')}
              {...register('website')}
            />
            <TextInput
              error={fieldError('phone_number')}
              required={zodInputIsRequired<Organisation>(schema, 'phone_number')}
              label={t('phone_number', 'Phone number')}
              {...register('phone_number')}
            />
            <SelectInput
              label={t('timezone', 'Timezone')}
              required={zodInputIsRequired<Organisation>(schema, 'timezone')}
              options={timezones}
              error={fieldError('timezone')}
              nullableValue=''
              {...register('timezone')}
            />
          </div>
        </PageModalContent>
        <PageModalActions
          actions={[
            {
              onClick: onClose,
              variant: ButtonVariant.Default,
              type: 'button',
              text: t('cancel', 'Cancel'),
            },
            {
              formId: 'updateOrganization',
              loading: loading,
              variant: ButtonVariant.Primary,
              type: 'submit',
              text: t('save', 'Save'),
            },
          ]}
        />
      </PageModal>
    </Tile>
  );
}
