import { Barn, DotsThreeVertical, House } from '@phosphor-icons/react';
import { Contact } from 'openapi';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { AvatarIcon, AvatarSize } from 'ui/Avatar';
import Badge from 'ui/Badge';
import { tableTbodyTrNoClick } from 'ui/Const';
import useModal from 'ui/Modals/UseModal';
import DropdownMenu, { DropdownMenuItemArray } from 'ui/DropdownMenu';
import { contactAddress, contactName } from 'utilities/Contact';
import { AppRoutes } from 'AppRoutes';
import { generatePath, useNavigate } from 'react-router-dom';
import DeleteLocationModal from '../DeleteLocationModal';
import { Country } from 'hooks/UseCountries/countries.data';

interface Props {
  location: Contact;
  onDeleted?: () => void;
  countries: Country[];
}

function Row({ location, onDeleted, countries }: Props): JSX.Element {
  const { modalIsVisible: deleteModalIsVisible, closeModal: closeDeleteModal, showModal: showDeleteModal } = useModal();

  const { t } = useTranslation();
  const navigate = useNavigate();

  const isStable = location.stable_location_uid !== null;

  /**
   * Build the dropdown with RVO actions included if needed
   */
  const dropdownMenuItems = useMemo((): DropdownMenuItemArray[] => {
    return [
      [
        {
          element: t('view-edit', 'View / Edit'),
          onClick: () => navigate(generatePath(AppRoutes.LocationDetail.path, { contactUid: location.uid })),
        },
      ],
      [{ element: t('remove', 'Remove'), onClick: showDeleteModal, className: 'text-red-600' }],
    ];
  }, [location.uid, navigate, showDeleteModal, t]);

  return (
    <>
      <tr className={tableTbodyTrNoClick} key={location.uid}>
        <td className='text-center w-12 md:w-14'>
          <AvatarIcon size={AvatarSize.Small} icon={isStable ? <Barn /> : <House />} uuid={location.uid} />
        </td>
        <td>
          <div className='flex gap-x-2 items-center'>
            {location.business_name || contactName(location)}
            {location.machtiging_rvo_gegeven !== '' && location.machtiging_rvo_gegeven !== null && (
              <Badge>{t('rvo-connected', 'RvO connected')}</Badge>
            )}
          </div>
          <p className='hidden md:block text-gray-500 text-sm'>{contactAddress(location, countries).join(', ')}</p>
        </td>

        <td align='right'>
          <div className='flex justify-end gap-x-3'>
            <DropdownMenu menuPlacement='right' menuItems={dropdownMenuItems}>
              <div className='w-full h-full text-center cursor-pointer pt-2 pb-1'>
                <DotsThreeVertical size={24} weight='bold' className='inline' />
              </div>
            </DropdownMenu>
          </div>

          <DeleteLocationModal
            isVisible={deleteModalIsVisible}
            onRequestClose={closeDeleteModal}
            onDeleted={() => onDeleted?.()}
            location={location}
          />
        </td>
      </tr>
    </>
  );
}

export default Row;
