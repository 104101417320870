import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppRoutes } from '../AppRoutes';
import usePermissions from 'hooks/UsePermissions';
import { useAccount } from 'context/AccountContext';
import { ModulePermissionsEnum } from 'openapi';

/**
 * The home page is a redirect page that will redirect the user to the correct page based on the permissions
 */
export default function Home(): undefined {
  const navigate = useNavigate();
  const { hasPermission, loaded: permissionsLoaded } = usePermissions();
  const { session } = useAccount();
  const location = useLocation();

  useEffect(() => {
    if (permissionsLoaded) {
      if (session) {
        if (
          hasPermission([ModulePermissionsEnum.VIEW_HORSES, ModulePermissionsEnum.VIEW_OWN_HORSES, ModulePermissionsEnum.MANAGE_HORSES])
        ) {
          return navigate(AppRoutes.HorsesList.path);
        } else if (hasPermission(ModulePermissionsEnum.VIEW_SCHEDULE)) {
          return navigate(AppRoutes.MyPlanning.path);
        } else {
          return navigate(AppRoutes.Profile.path);
        }
      } else {
        return navigate(`${AppRoutes.Login.path}?redirect=${location.pathname}${location.search}`);
      }
    }
  }, [hasPermission, location.pathname, location.search, navigate, permissionsLoaded, session]);
}
