import { PencilSimple, TrashSimple } from '@phosphor-icons/react';
import ApiErrorParser from 'api/ApiErrorParser';
import { AppRoutes } from 'AppRoutes';
import { useAccount } from 'context/AccountContext';
import { useOrganization } from 'context/OrganizationContext';
import { Horse, HeatCheck, SexEnum, HeatcheckService } from 'openapi';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { ButtonVariant } from 'ui/Button';
import { ErrorSection } from 'ui/Error';
import DescriptionList from 'ui/Layout/DescriptionList';
import Page, { navBackToThisPage, PageMaxWidth } from 'ui/Layout/Page';
import { Tile } from 'ui/Layout/Tile';
import { ActionModal } from 'ui/Modals';
import { NavLink, generatePath } from 'react-router-dom';
import useModal from 'ui/Modals/UseModal';
import { ApiPromises } from 'utilities/ApiPromises';
import { organizationHorses } from 'utilities/ApiRequests';
import { SaveHeatCheck } from 'components/Breeding/SaveHeatCheck';
import { eggLocationEnumToString, softnessEnumToString, uterusEnumToString } from 'utilities/Breeding';
import { table, tableTbodyTrNoClick } from 'ui/Const';
import CystMap from 'components/Breeding/CystMap';

export default function HeatCheckDetailsPage(): JSX.Element {
  const [horses, setHorses] = useState<Horse[]>();
  const [apiPromises, setApiPromises] = useState<ApiPromises>();
  const [removeHeatCheckDialogError, setRemoveHeatCheckDialogError] = useState<ApiErrorParser<void> | undefined>();
  const [heatCheck, setHeatCheck] = useState<HeatCheck>();

  const { t } = useTranslation();
  const { selectedOrganizationUid, generateCacheKey } = useOrganization();
  const { formatDate } = useAccount();
  const {
    closeModal: closeUpdateHeatCheckModal,
    modalIsVisible: updateHeatCheckModalIsVisible,
    showModal: showUpdateHeatCheckModal,
  } = useModal();
  const { uid } = useParams();
  const {
    closeModal: closeRemoveHeatCheckDialog,
    modalIsVisible: updateRemoveHeatCheckDialog,
    showModal: showRemoveHeatCheckDialog,
  } = useModal();
  const navigate = useNavigate();

  const mare = useMemo(() => {
    return horses?.find(horse => horse.uid === heatCheck?.mare_uid);
  }, [horses, heatCheck]);

  // Load data from the api/cache
  const loadApiData = useCallback((): ApiPromises => {
    const promises = new ApiPromises();
    if (!selectedOrganizationUid || !uid) {
      return promises;
    }
    promises.appendSingle<HeatCheck>(
      'heat-check',
      () =>
        HeatcheckService.heatcheckRetrieve({
          mareOrganisationUid: selectedOrganizationUid,
          uid: uid,
        }),
      setHeatCheck,
    );
    promises.appendListObj<Horse>('horses', setHorses, organizationHorses(selectedOrganizationUid, generateCacheKey));
    setApiPromises(promises);
    return promises;
  }, [selectedOrganizationUid, uid, generateCacheKey]);

  const actions = useMemo(() => {
    return [
      {
        onClick: showUpdateHeatCheckModal,
        text: t('edit', 'Edit'),
        icon: <PencilSimple />,
      },
      {
        onClick: showRemoveHeatCheckDialog,
        text: t('remove', 'Remove'),
        buttonVariant: ButtonVariant.Danger,
        icon: <TrashSimple />,
      },
    ];
  }, [t, showRemoveHeatCheckDialog, showUpdateHeatCheckModal]);

  // Remove the heat check and navigate back to the heat check list.
  const removeHeatCheck = async (): Promise<void> => {
    const promise = HeatcheckService.heatcheckDestroy({
      mareOrganisationUid: selectedOrganizationUid ?? '',
      uid: uid ?? '',
    });
    try {
      await promise;
      closeRemoveHeatCheckDialog();
      navigate(AppRoutes.BreedingHeatCheckList.path);
    } catch (e) {
      setRemoveHeatCheckDialogError(new ApiErrorParser(e));
    }
  };

  const breadCrumbs = useMemo(() => [{ name: t('heat-check-list', 'Heat check list'), path: AppRoutes.BreedingHeatCheckList.path }], [t]);

  // Load from the api
  useEffect(() => {
    if (selectedOrganizationUid) {
      const promise = loadApiData();
      return () => promise.cancel();
    }
  }, [selectedOrganizationUid, uid]); //eslint-disable-line

  return (
    <Page
      title={t('heat-check', 'Heat check')}
      breadCrumbs={breadCrumbs}
      maxWidth={PageMaxWidth.Tile}
      actions={actions}
      loading={apiPromises}
    >
      <div className='space-y-4'>
        <Tile title={t('general', 'General')}>
          {heatCheck && (
            <DescriptionList
              list={[
                { term: t('date', 'Date'), definition: formatDate(heatCheck.date ?? '') },
                {
                  term: t('mare', 'Mare'),
                  definition: (
                    <NavLink
                      className='text-blue-500'
                      to={{
                        pathname: generatePath(AppRoutes.HorsesDetails.path, { uid: mare?.uid ?? '' }),
                        search: navBackToThisPage().toString(),
                      }}
                    >
                      {mare?.name}
                    </NavLink>
                  ),
                },
                { term: t('uterus', 'Uterus'), definition: uterusEnumToString(heatCheck.uterus) },
                {
                  term: t('uterus-has-fluid', 'Uterus has fluid'),
                  definition: heatCheck.uterus_has_fluid === true ? t('yes', 'Yes') : t('no', 'No'),
                },
                { term: t('cervix', 'Cervix'), definition: softnessEnumToString(heatCheck.cervix) },
                { term: t('note', 'Note'), definition: heatCheck.extra_info },
              ]}
            />
          )}
        </Tile>
        <Tile title={t('eggs', 'Eggs')}>
          {(heatCheck?.egg_set?.length ?? 0) === 0 && (
            <p className='text-gray-500 italic text-center py-4'>{t('no-eggs-located', 'No eggs were located.')}</p>
          )}
          {(heatCheck?.egg_set?.length ?? 0) > 0 && (
            <table className={table}>
              <thead>
                <tr>
                  <td className='text-sm font-medium text-gray-600'>{t('egg-location', 'Location')}</td>
                  <td className='text-sm font-medium text-gray-600'>{t('egg-size', 'Size')}</td>
                  <td className='text-sm font-medium text-gray-600'>{t('egg-hardness', 'Hardness')}</td>
                </tr>
              </thead>
              <tbody>
                {heatCheck?.egg_set?.map(egg => (
                  <tr className={tableTbodyTrNoClick} key={egg.uid}>
                    <td>{eggLocationEnumToString(egg.location)}</td>
                    <td>
                      {egg.size_metric} {t('cm-metric', 'cm')}
                    </td>
                    <td>{softnessEnumToString(egg.hardness)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </Tile>
        <Tile title={t('cysts', 'Cysts')}>
          {(heatCheck?.cyst_set?.length ?? 0) === 0 && (
            <p className='text-gray-500 italic text-center py-4'>{t('no-cysts-located', 'No cysts were located.')}</p>
          )}
          {(heatCheck?.cyst_set?.length ?? 0) > 0 && <CystMap cysts={heatCheck?.cyst_set ?? []} />}
        </Tile>
      </div>
      {heatCheck && horses && (
        <SaveHeatCheck
          mares={horses.filter(horse => horse.sex === SexEnum._2)}
          existingHeatCheck={heatCheck}
          open={updateHeatCheckModalIsVisible}
          onSaved={(reload: boolean) => {
            if (reload) {
              loadApiData();
            }
          }}
          onRequestClose={() => closeUpdateHeatCheckModal()}
        />
      )}
      <ActionModal
        open={updateRemoveHeatCheckDialog}
        actions={[
          {
            text: t('cancel', 'Cancel'),
            variant: ButtonVariant.Default,
            onClick: closeRemoveHeatCheckDialog,
          },
          {
            text: t('remove', 'Remove'),
            variant: ButtonVariant.PrimaryDanger,
            onClick: removeHeatCheck,
          },
        ]}
        title={t('remove-heat-check-confirm-title', 'Remove heat check')}
      >
        <>
          <ErrorSection errors={removeHeatCheckDialogError} />
          <p>{t('remove-heat-confirm-text', 'Are you sure you want to remove this heat check?')}</p>
        </>
      </ActionModal>
    </Page>
  );
}
