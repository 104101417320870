import { zodResolver } from '@hookform/resolvers/zod';
import { Plus, Trash } from '@phosphor-icons/react';
import ApiErrorParser from 'api/ApiErrorParser';
import useFormError from 'api/hooks/useFormError';
import { useOrganization } from 'context/OrganizationContext';
import { CancelablePromise, Horse, LocationEnum, HeatCheck, SoftnessEnum, UterusEnum, HeatcheckService } from 'openapi';
import { schemas } from 'openapi/zod-schemas';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useFieldArray, useForm, get } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Button, { ButtonVariant } from 'ui/Button';
import { ErrorSection } from 'ui/Error';
import { CheckboxInput, DateInput, SelectInput, TextAreaInput, TextInput } from 'ui/Inputs';
import { OptionItemInterface } from 'ui/Inputs/SelectInput';
import { PageModal } from 'ui/Modals';
import { ActionProps, PageModalActions, PageModalContent, PageModalTitle } from 'ui/Modals/PageModal';
import { eggLocationEnumToString, softnessEnumToString, uterusEnumToString } from 'utilities/Breeding';
import { transformEmptyToUndefined } from 'utilities/zod';
import CystMap from './CystMap';
import { useAccount } from 'context/AccountContext';
import { CalendarActivity } from 'utilities/Planning';
import { formatDate } from 'utilities/date.utilities';
import { addDays, startOfToday } from 'date-fns';
import { z } from 'zod';

interface Props {
  mares?: Horse[];
  skippable?: boolean;
  selectedMare?: Horse;
  heatCheckActivity?: CalendarActivity;
  existingHeatCheck?: HeatCheck;
  open: boolean;
  onSaved: (reload: boolean, isSkipped: boolean, heatCheck?: HeatCheck) => void;
  onRequestClose: () => void;
}

// Return date of today in YYYY-MM-DD format.
const today = (): string => {
  return new Date().toISOString().substr(0, 10);
};

// Simplify the add_followup_insemination field of HeatCheck to a date.
// This is translated to a FollowUpInseminationCheck in the submit handler.
interface ExtendedHeatCheck extends HeatCheck {
  add_insemination_date: string;
}

// A heat check is checks if the mare is ready for insemination.
export function SaveHeatCheck({
  mares,
  skippable,
  selectedMare,
  existingHeatCheck,
  heatCheckActivity,
  open,
  onRequestClose,
  onSaved,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const { selectedOrganization } = useOrganization();
  const { accountDetails, formatDate: formatDatePretty } = useAccount();

  const schema = useMemo(() => {
    return schemas.HeatCheck.extend({ add_insemination_date: z.string().optional().nullable() }).omit({
      uid: true,
      activity_uid: true,
      created_by: true,
      egg_set: true,
      cyst_set: true,
      date: heatCheckActivity ? true : undefined,
      mare_uid: heatCheckActivity ? true : undefined,
      add_insemination_date: heatCheckActivity ? undefined : true,
      add_followup_insemination: true,
      order_item_uid: true, // @TODO Optionally connect to the order.
      created_on: true,
      last_modified_on: true,
    });
  }, [heatCheckActivity]);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    control,
    reset,
  } = useForm<ExtendedHeatCheck>({
    reValidateMode: 'onChange',
    resolver: zodResolver(schema),
  });

  // Use a fieldArray for the egg items.
  const {
    fields: eggFields,
    append: appendEgg,
    remove: removeEgg,
  } = useFieldArray({
    name: 'egg_set',
    control,
  });

  // Use a fieldArray for the cyst items.
  const {
    fields: cystFields,
    append: appendCyst,
    remove: removeCyst,
    update: updateCyst,
  } = useFieldArray({
    name: 'cyst_set',
    control,
  });

  const { fieldError, nonFieldErrors, setApiError } = useFormError(schema, errors);

  const onSubmit = async (data: ExtendedHeatCheck) => {
    if (!selectedOrganization) {
      console.error('Save heat check without selected organization.');
      return;
    }

    if (data.add_insemination_date) {
      data.add_followup_insemination = {
        date: data.add_insemination_date,
        // TODO: The order_item_uid can be assigned to connect this HeatCheck to an Order.
        // order_item_uid?: 'id'
      };
    }

    if (heatCheckActivity) {
      data.activity_uid = heatCheckActivity.uid;
      data.date = formatDate(heatCheckActivity.startTime);
    }

    let promise: CancelablePromise<HeatCheck>;
    if (existingHeatCheck) {
      promise = HeatcheckService.heatcheckPartialUpdate({
        mareOrganisationUid: selectedOrganization.uid,
        uid: existingHeatCheck.uid,
        requestBody: data,
      });
    } else {
      promise = HeatcheckService.heatcheckCreate({
        mareOrganisationUid: selectedOrganization.uid,
        requestBody: data,
      });
    }

    try {
      onSaved(true, false, await promise);
      onRequestClose();
    } catch (e) {
      setApiError(new ApiErrorParser<HeatCheck>(e));
    }
  };

  const resetToDefaults = useCallback(() => {
    if (existingHeatCheck) {
      reset(existingHeatCheck);
    } else {
      if (heatCheckActivity) {
        reset({
          mare_uid: heatCheckActivity.horseUid,
          date: formatDate(heatCheckActivity.startTime),
          extra_info: heatCheckActivity.extraInfo,
        });
      } else {
        reset({
          mare_uid: selectedMare?.uid,
          date: today(),
        });
      }
    }
  }, [reset, existingHeatCheck, selectedMare, heatCheckActivity]);

  // Event that will be fired after the modal has been closed
  const resetForm = () => {
    setApiError(undefined);
    removeEgg(0); // Because the default value is {null, null} we need to manually trigger a remove.
    removeCyst(0); // Because the default value is {null, null} we need to manually trigger a remove.

    resetToDefaults();
  };

  const uterusEnumOptions = useMemo(() => {
    const options: OptionItemInterface[] = [];
    Object.values(UterusEnum).forEach(value => {
      options.push({ id: value, name: uterusEnumToString(value) });
    });
    return options;
  }, []);

  const softnessEnumOptions = useMemo(() => {
    return Object.values(SoftnessEnum).reduce<OptionItemInterface[]>((prevVal, currentVal) => {
      prevVal.push({ id: currentVal, name: softnessEnumToString(currentVal) });
      return prevVal;
    }, []);
  }, []);

  const eggLocationEnumOptions = useMemo(() => {
    return Object.values(LocationEnum).reduce<OptionItemInterface[]>((prevVal, currentVal) => {
      prevVal.push({ id: currentVal, name: eggLocationEnumToString(currentVal) });
      return prevVal;
    }, []);
  }, []);

  const mareOptions = useMemo((): OptionItemInterface[] | undefined => {
    if (!mares) {
      return undefined;
    }
    return mares
      .filter(mare => !mare.hidden || mare.uid === existingHeatCheck?.mare_uid)
      .map(horse => {
        return { id: horse.uid, name: horse.name };
      });
  }, [mares, existingHeatCheck]);

  const planInseminationOptions = useMemo((): OptionItemInterface[] | undefined => {
    const today = startOfToday();
    const tomorrow = addDays(today, 1);
    const dayAfterTomorrow = addDays(today, 2);
    const threeDays = addDays(today, 3);
    return [
      {
        id: formatDate(today),
        name: t('today', 'Today'),
      },
      {
        id: formatDate(tomorrow),
        name: t('tomorrow', 'Tomorrow'),
      },
      {
        id: formatDate(dayAfterTomorrow),
        name: t('day-after-tomorrow', 'Day after tomorrow'),
      },
      {
        id: formatDate(threeDays),
        name: t('three-days', 'Three days'),
      },
    ];
  }, [t]);

  // Reset when the modal visibility is changed or when the existingCategory is changed.
  useEffect(() => {
    resetToDefaults();
  }, [reset, existingHeatCheck, open, selectedMare, resetToDefaults]);

  const modalActions = useMemo((): ActionProps[] => {
    const array: ActionProps[] = [];

    if (skippable) {
      array.push({
        variant: ButtonVariant.Default,
        text: t('skip', 'Skip'),
        onClick: () => {
          onSaved(false, true);
          onRequestClose();
        },
      });
    }

    array.push({
      variant: ButtonVariant.Primary,
      text: t('save', 'Save'),
      type: 'submit',
      formId: 'addHeatCheck',
      loading: isSubmitting,
    });

    return array;
  }, [t, isSubmitting, skippable, onRequestClose, onSaved]);

  return (
    <PageModal
      open={open}
      onClosed={resetForm}
      parentElement='form'
      parentProps={{ id: 'addHeatCheck', noValidate: true, onSubmit: handleSubmit(onSubmit) }}
    >
      <PageModalTitle title={t('new-heat-check', 'New heat check')} onClose={() => onRequestClose()} />
      <PageModalContent>
        <ErrorSection className='mb-4' errors={nonFieldErrors} />
        <div className='flex flex-col md:flex-row gap-4 mb-4'>
          <div className='flex flex-col gap-4 grow'>
            {mareOptions && (
              <SelectInput
                label={t('mare', 'Mare')}
                nullable={true}
                required={true}
                options={mareOptions}
                error={fieldError('mare_uid')}
                {...register('mare_uid', { setValueAs: (val: unknown): unknown => (val === '----' ? undefined : val) })}
              />
            )}
            {!mareOptions && (
              <div>
                <p className='block text-sm font-medium leading-4 text-gray-600 mb-2'>{t('mare', 'Mare')} *</p>
                <p>{selectedMare?.name ?? t('unknown-mare', 'Unknown mare')}</p>
              </div>
            )}
            {heatCheckActivity && (
              <div>
                <p className='block text-sm font-medium leading-4 text-gray-600 mb-2'>{t('date', 'Date')} *</p>
                <p>{formatDatePretty(heatCheckActivity.startTime)}</p>
              </div>
            )}
            {!heatCheckActivity && (
              <DateInput control={control} required={true} label={t('date', 'Date')} name='date' error={fieldError('date')} />
            )}
            <SelectInput
              required={true}
              nullable={true}
              options={uterusEnumOptions}
              error={fieldError('uterus')}
              label={t('uterus', 'Uterus')}
              {...register('uterus', { setValueAs: (val: unknown): unknown => (val === '----' ? undefined : val) })}
            />
            <CheckboxInput
              label={t('uterus_has_fluid', 'Uterus has fluid')}
              {...register('uterus_has_fluid')}
              error={fieldError('uterus_has_fluid')}
            />
            <SelectInput
              required={true}
              nullable={true}
              options={softnessEnumOptions}
              error={fieldError('cervix')}
              label={t('cervix', 'Cervix')}
              {...register('cervix', { setValueAs: (val: unknown): unknown => (val === '----' ? undefined : val) })}
            />
            <TextAreaInput
              label={t('note', 'Note')}
              error={fieldError('extra_info')}
              {...register('extra_info', { setValueAs: transformEmptyToUndefined() })}
            />
            <div>
              <p className='text-sm font-medium text-gray-600 pb-1'>{t('eggs', 'Eggs')}</p>
              <div className='p-2 rounded-lg border'>
                {eggFields.length === 0 && <p className='text-gray-500 italic text-center py-4'>{t('no-eggs', 'No eggs')}</p>}
                <table className='w-full'>
                  {eggFields.length > 0 && (
                    <thead>
                      <tr>
                        <td className='text-sm font-medium text-gray-600 pb-1'>{t('egg-location', 'Location')}</td>
                        <td className='text-sm font-medium text-gray-600 pl-2 pb-1'>{t('egg-size', 'Size')}</td>
                        <td className='text-sm font-medium text-gray-600 pl-2 pb-1'>{t('egg-hardness', 'Hardness')}</td>
                      </tr>
                    </thead>
                  )}
                  <tbody>
                    {eggFields.map((field, index) => (
                      <tr key={field.id} className='border-t'>
                        <td className='py-2'>
                          <SelectInput
                            nullable={true}
                            options={eggLocationEnumOptions}
                            required={true}
                            nullableValue=''
                            error={get(errors, `egg_set.[${index}].location`)?.message}
                            {...register(`egg_set.${index}.location`, { setValueAs: transformEmptyToUndefined() })}
                          />
                        </td>
                        <td>
                          <TextInput
                            error={get(errors, `egg_set.[${index}].size`)?.message}
                            style={{ width: 50 }}
                            type='number'
                            lang={accountDetails?.language}
                            step='0.01'
                            {...register(`egg_set.${index}.size_metric`, { setValueAs: transformEmptyToUndefined() })}
                            postText={t('cm-metric', 'cm')}
                          />
                        </td>
                        <td className='p-2'>
                          <SelectInput
                            nullable={true}
                            options={softnessEnumOptions}
                            required={true}
                            nullableValue=''
                            error={get(errors, `egg_set.[${index}].hardness`)?.message}
                            {...register(`egg_set.${index}.hardness`, { setValueAs: transformEmptyToUndefined() })}
                          />
                        </td>
                        <td>
                          <button
                            type='button'
                            onClick={() => removeEgg(index)}
                            className='inline text-center cursor-pointer rounded hover:bg-red-700 hover:text-white px-1 py-1 focus:ring-0 focus:ring-offset-0'
                          >
                            <Trash className='inline' />
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colSpan={3} className='pt-2'>
                        <Button
                          icon={<Plus />}
                          type='button'
                          onClick={() => {
                            // Ignore ts error to have the newly added line show empty dropdown values.
                            // eslint-disable-next-line
                            // @ts-ignore
                            appendEgg({});
                          }}
                        >
                          {t('add-egg', 'Add egg')}
                        </Button>
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
              <p>{fieldError('egg_set')}</p>
            </div>
          </div>
          <div className='space-y-4'>
            <div>
              <p className='text-sm font-medium text-gray-600 pb-1'>{t('cyst-map', 'Cyst map')}</p>
              <CystMap cysts={cystFields} cystAdded={appendCyst} cystRemoved={removeCyst} cystUpdated={updateCyst} />
              <p className='text-xs text-red-500'>{fieldError('cyst_set')}</p>
            </div>
            {!existingHeatCheck && (
              <SelectInput
                nullable={true}
                options={planInseminationOptions}
                error={fieldError('add_insemination_date')}
                label={t('plan-insemination', 'Plan insemination')}
                nullableLabel={t('no', 'No')}
                {...register('add_insemination_date', { setValueAs: (val: unknown): unknown => (val === t('no', 'No') ? null : val) })}
              />
            )}
          </div>
        </div>
      </PageModalContent>
      <PageModalActions actions={modalActions} />
    </PageModal>
  );
}
