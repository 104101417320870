import { House, PencilSimple } from '@phosphor-icons/react';
import { useAccount } from 'context/AccountContext';
import { Contact, HorseLocation } from 'openapi';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { contactName } from 'utilities/Contact';

interface Props {
  location: HorseLocation | undefined;
  contacts: Contact[] | undefined;
  isCurrent: boolean; // flag if this is the current location
  onEdit?: () => void;
}

function ListItem({ location, contacts, isCurrent, onEdit }: Props): JSX.Element {
  const { formatDate } = useAccount();
  const { t } = useTranslation();

  /**
   * Get the location name
   */
  const getLocationName = useCallback(
    (locationUid: string) => {
      const find = contacts?.find(contact => contact.uid === locationUid);
      if (find) {
        return contactName(find);
      }

      return `<${t('unknown', 'Unknown')}>`;
    },
    [contacts, t],
  );

  // no location, return just a fragment
  if (!location) return <></>;

  return (
    <div className='flex flex-col w-full'>
      <div className='flex gap-x-1 items-center'>
        {getLocationName(location.location_uid)} {isCurrent && <House />}
        {onEdit && (
          <button className='ml-auto' onClick={onEdit}>
            <PencilSimple />
          </button>
        )}
      </div>
      <div className='flex flex-col text-sm'>
        <span>
          {t('arrival', 'Arrival')}: {formatDate(location.arrival_date)}
        </span>
        {location.departure_date && (
          <span>
            {t('depature', 'Departure')}: {location.departure_date ? formatDate(location.departure_date) : ''}
          </span>
        )}
      </div>
    </div>
  );
}

export default ListItem;
