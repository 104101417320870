/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { HeatCheck } from '../models/HeatCheck';
import type { PaginatedHeatCheckList } from '../models/PaginatedHeatCheckList';
import type { PatchedHeatCheck } from '../models/PatchedHeatCheck';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class HeatcheckService {
  /**
   * The pregnancy_check_term is required when there is no activity given AND no activity can be found on the given date.
   *
   * For the update if you change the activity.uid, you will swap out the activity.
   * This means the pregnancy_check_term and extra_info from the old activity will be copied, unless (one of) these are given in the data.
   * The old activity will also be made empty in that case.
   * @returns PaginatedHeatCheckList
   * @throws ApiError
   */
  public static heatcheckList({
    mareOrganisationUid,
    activityOrderItemUid,
    activityOrderItemUidIn,
    date,
    dateGt,
    dateGte,
    dateLt,
    dateLte,
    mareUid,
    mareUidIn,
    o,
    page,
    pageSize,
  }: {
    mareOrganisationUid: string,
    activityOrderItemUid?: string,
    /**
     * Multiple values may be separated by commas.
     */
    activityOrderItemUidIn?: Array<string>,
    date?: string,
    dateGt?: string,
    dateGte?: string,
    dateLt?: string,
    dateLte?: string,
    mareUid?: string,
    /**
     * Multiple values may be separated by commas.
     */
    mareUidIn?: Array<string>,
    /**
     * Which field to use when ordering the results.
     */
    o?: string,
    /**
     * A page number within the paginated result set.
     */
    page?: number,
    /**
     * Number of results to return per page.
     */
    pageSize?: number,
  }): CancelablePromise<PaginatedHeatCheckList> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v5/organisations/{mare__organisation__uid}/heatcheck',
      path: {
        'mare__organisation__uid': mareOrganisationUid,
      },
      query: {
        'activity__order_item__uid': activityOrderItemUid,
        'activity__order_item__uid__in': activityOrderItemUidIn,
        'date': date,
        'date__gt': dateGt,
        'date__gte': dateGte,
        'date__lt': dateLt,
        'date__lte': dateLte,
        'mare__uid': mareUid,
        'mare__uid__in': mareUidIn,
        'o': o,
        'page': page,
        'page_size': pageSize,
      },
    });
  }
  /**
   * The pregnancy_check_term is required when there is no activity given AND no activity can be found on the given date.
   *
   * For the update if you change the activity.uid, you will swap out the activity.
   * This means the pregnancy_check_term and extra_info from the old activity will be copied, unless (one of) these are given in the data.
   * The old activity will also be made empty in that case.
   * @returns HeatCheck
   * @throws ApiError
   */
  public static heatcheckCreate({
    mareOrganisationUid,
    requestBody,
  }: {
    mareOrganisationUid: string,
    requestBody: HeatCheck,
  }): CancelablePromise<HeatCheck> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v5/organisations/{mare__organisation__uid}/heatcheck',
      path: {
        'mare__organisation__uid': mareOrganisationUid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * The pregnancy_check_term is required when there is no activity given AND no activity can be found on the given date.
   *
   * For the update if you change the activity.uid, you will swap out the activity.
   * This means the pregnancy_check_term and extra_info from the old activity will be copied, unless (one of) these are given in the data.
   * The old activity will also be made empty in that case.
   * @returns HeatCheck
   * @throws ApiError
   */
  public static heatcheckRetrieve({
    mareOrganisationUid,
    uid,
  }: {
    mareOrganisationUid: string,
    uid: string,
  }): CancelablePromise<HeatCheck> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v5/organisations/{mare__organisation__uid}/heatcheck/{uid}',
      path: {
        'mare__organisation__uid': mareOrganisationUid,
        'uid': uid,
      },
    });
  }
  /**
   * The pregnancy_check_term is required when there is no activity given AND no activity can be found on the given date.
   *
   * For the update if you change the activity.uid, you will swap out the activity.
   * This means the pregnancy_check_term and extra_info from the old activity will be copied, unless (one of) these are given in the data.
   * The old activity will also be made empty in that case.
   * @returns HeatCheck
   * @throws ApiError
   */
  public static heatcheckUpdate({
    mareOrganisationUid,
    uid,
    requestBody,
  }: {
    mareOrganisationUid: string,
    uid: string,
    requestBody: HeatCheck,
  }): CancelablePromise<HeatCheck> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/v5/organisations/{mare__organisation__uid}/heatcheck/{uid}',
      path: {
        'mare__organisation__uid': mareOrganisationUid,
        'uid': uid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * The pregnancy_check_term is required when there is no activity given AND no activity can be found on the given date.
   *
   * For the update if you change the activity.uid, you will swap out the activity.
   * This means the pregnancy_check_term and extra_info from the old activity will be copied, unless (one of) these are given in the data.
   * The old activity will also be made empty in that case.
   * @returns HeatCheck
   * @throws ApiError
   */
  public static heatcheckPartialUpdate({
    mareOrganisationUid,
    uid,
    requestBody,
  }: {
    mareOrganisationUid: string,
    uid: string,
    requestBody?: PatchedHeatCheck,
  }): CancelablePromise<HeatCheck> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/api/v5/organisations/{mare__organisation__uid}/heatcheck/{uid}',
      path: {
        'mare__organisation__uid': mareOrganisationUid,
        'uid': uid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * The pregnancy_check_term is required when there is no activity given AND no activity can be found on the given date.
   *
   * For the update if you change the activity.uid, you will swap out the activity.
   * This means the pregnancy_check_term and extra_info from the old activity will be copied, unless (one of) these are given in the data.
   * The old activity will also be made empty in that case.
   * @returns void
   * @throws ApiError
   */
  public static heatcheckDestroy({
    mareOrganisationUid,
    uid,
  }: {
    mareOrganisationUid: string,
    uid: string,
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/v5/organisations/{mare__organisation__uid}/heatcheck/{uid}',
      path: {
        'mare__organisation__uid': mareOrganisationUid,
        'uid': uid,
      },
    });
  }
}
