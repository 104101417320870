import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PageMaxWidth } from 'ui/Layout/Page';
import { Page } from 'ui/Layout';
import InvitationList from 'components/Users/InvitationList';
import { PageAction } from 'context/PageContext';
import { useOrganization } from 'context/OrganizationContext';
import { Contact, InvitationsService, Role, RolesService, SentInvitation } from 'openapi';
import InviteAccountModal from 'components/Contacts/Modals/InviteAccountModal';
import useModal from 'ui/Modals/UseModal';
import { ButtonVariant } from 'ui/Button';
import { Plus } from '@phosphor-icons/react';
import { Tile } from 'ui/Layout/Tile';
import UserList from 'components/Users/UserList';
import { ApiPromises } from 'utilities/ApiPromises';
import { activeContacts } from 'utilities/ApiRequests';
import useApiPromises from 'api/hooks/useApiPromises';
import FilterWrapper from 'components/Common/ListFilter/FilterWrapper';
import { listFilter, textFilter } from 'utilities/Contact';
import useListFilter from 'components/Common/ListFilter/useListFilter';
import { defaultApiPageSize } from 'ui/Const';
import PullScrollWrapper from 'ui/PullScrollWrapper';

export default function Users(): JSX.Element {
  const [searchText, setSearchText] = useState<string>('');
  const [contacts, setContacts] = useState<Contact[]>();
  const [roles, setRoles] = useState<Role[]>();
  const [invitations, setInvitations] = useState<SentInvitation[]>();
  const [apiPromises, setApiPromises] = useState<ApiPromises>();
  const { loading: loadingApiPromises } = useApiPromises({ apiPromises });
  const [itemsVisible, setItemsVisible] = useState<number>(defaultApiPageSize);

  const { t } = useTranslation();
  const { selectedOrganizationUid, generateCacheKey } = useOrganization();

  const {
    closeModal: closeCreateAccountModal,
    modalIsVisible: createAccountModalIsVisible,
    showModal: showCreateAccountModal,
  } = useModal();

  const userContacts = useMemo(() => {
    return contacts?.filter(contact => contact.user_uid !== null && contact.user_uid !== undefined);
  }, [contacts]);

  /**
   * Get only the stables from the contact list
   */
  const stables = useMemo(() => {
    return contacts?.filter(contact => contact.stable_location_uid);
  }, [contacts]);

  const { filters } = useListFilter([]);

  // We don't show the full list directly. Make use of the fetch more strategy of PullToRefresh.
  const visibleUsers = useMemo((): { users: Contact[]; canFetchMore: boolean } => {
    if (!userContacts) {
      return { users: [], canFetchMore: false };
    }
    const all = textFilter(listFilter(userContacts ?? [], filters), searchText);
    return { users: all.slice(0, itemsVisible), canFetchMore: itemsVisible < all.length };
  }, [userContacts, filters, searchText, itemsVisible]);

  // Load data from the api/cache
  const loadApiData = useCallback((): ApiPromises => {
    const promises = new ApiPromises();
    if (!selectedOrganizationUid) {
      return promises;
    }

    promises.appendList<SentInvitation>(
      'invitations',
      () =>
        InvitationsService.invitationsList({
          inviterOrganisationUid: selectedOrganizationUid,
          status: 'pending', // status={pending|declined|accepted},
        }),
      setInvitations,
    );

    promises.appendList<Role>(
      'roles',
      () =>
        RolesService.rolesList({
          organisationUid: selectedOrganizationUid,
        }),
      setRoles,
      generateCacheKey('roles'),
    );

    promises.appendListObj<Contact>('contacts', setContacts, activeContacts(selectedOrganizationUid, generateCacheKey));
    setItemsVisible(defaultApiPageSize);

    setApiPromises(promises);
    return promises;
  }, [selectedOrganizationUid, generateCacheKey]);

  // Load from the api
  useEffect(() => {
    if (selectedOrganizationUid) {
      const promise = loadApiData();
      return () => promise.cancel();
    }
  }, [selectedOrganizationUid]); //eslint-disable-line

  /**
   * Show the invite action only when there are no invitations
   * as we do not show the tile when there are no invitations
   */
  const actions = useMemo((): PageAction[] | undefined => {
    return [
      {
        onClick: showCreateAccountModal,
        buttonVariant: ButtonVariant.Primary,
        icon: <Plus />,
        text: t('invite-user', 'Invite user'),
        isMobileAddAction: true,
      },
    ];
  }, [showCreateAccountModal, t]);

  return (
    <Page title={t('users', 'Users')} maxWidth={PageMaxWidth.Default} actions={actions} loading={apiPromises}>
      <PullScrollWrapper
        onRefresh={() => loadApiData().watchAll()}
        canFetchMore={visibleUsers.canFetchMore}
        onFetchMore={() => {
          setItemsVisible(itemsVisible + defaultApiPageSize);
          return Promise.resolve();
        }}
      >
        <div className='space-y-10'>
          {invitations && invitations.length > 0 && (
            <InvitationList
              invitations={invitations}
              contacts={contacts}
              roles={roles}
              onDeleteInvitation={loadApiData}
              onResendInvitation={loadApiData}
            />
          )}

          <Tile loading={loadingApiPromises} noBoxOnMobile={true}>
            <FilterWrapper>
              <input
                type='search'
                onChange={e => setSearchText(e.currentTarget.value)}
                size={10}
                placeholder={t('search-contact-placeholder', 'Search by name, email, etc...')}
                className='placeholder:italic placeholder:text-sm px-2 max-w-md grow h-10 rounded-md border'
              />
            </FilterWrapper>

            {userContacts && <UserList roles={roles} contacts={visibleUsers.users} stables={stables} />}
          </Tile>
        </div>
      </PullScrollWrapper>
      <InviteAccountModal
        modalTitle={t('invite-user', 'Invite user')}
        isVisible={createAccountModalIsVisible}
        onRequestCloseModal={closeCreateAccountModal}
        onInvited={loadApiData}
        contacts={contacts}
      />
    </Page>
  );
}
