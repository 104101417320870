import 'react-datepicker/dist/react-datepicker.css';
import './Styles.css';
import React, { HTMLInputAutoCompleteAttribute, useEffect, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { Control, FieldPath, FieldValues, useController, UseControllerProps } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TextInput } from 'ui/Inputs';
import DateInputNative, { DateProps } from '../DateInputNative';
import useUserAgent from 'api/hooks/useUserAgent';

/**
 * Complex type that extend some magic types from React Form hooks and include own fields
 *
 * See --> https://github.com/orgs/react-hook-form/discussions/7851#discussioncomment-2219298
 */
interface Props<TFieldValues extends FieldValues = FieldValues, TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>>
  extends Pick<DateProps, 'label' | 'hint' | 'error' | 'invalid' | 'required' | 'tabIndex'>,
    UseControllerProps<TFieldValues, TName> {
  name: TName; // override from UseControllerProps and make it required
  control: Control<TFieldValues>; // override from UseControllerProps and make it required
  className?: string;
  autoComplete?: HTMLInputAutoCompleteAttribute | undefined;
}

/**
 * Create a Date Input field
 *
 * As i.e. Safari lacks a good UI, we implement a custom Date picker (react-datepicker)
 */
function DateInput<TFieldValues extends FieldValues = FieldValues, TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>>({
  label,
  hint,
  error,
  invalid,
  required,
  className,
  disabled,
  tabIndex,
  autoComplete,
  ...props
}: Props<TFieldValues, TName>): JSX.Element {
  const [startDate, setStartDate] = useState<Date | null>(null);

  const { field } = useController({
    name: props.name,
    control: props.control,
    defaultValue: props.defaultValue,
    rules: props.rules,
    shouldUnregister: props.shouldUnregister,
  });
  const { t } = useTranslation();
  const { useOwnDatePicker } = useUserAgent();

  /**
   * Debug message what we use
   */
  useEffect(() => {
    const message = useOwnDatePicker ? 'Use native DatePicker' : 'Use custom DatePicker';
    console.debug(`${message} for <DateInput />`);
  }, [useOwnDatePicker]);

  /**
   * Date Validation
   */
  useEffect(() => {
    if (typeof field.value === 'string' && field.value !== '') {
      const newDate = new Date(field.value);
      // set the valye only if the date is valid
      !isNaN(newDate.getTime()) && setStartDate(newDate);
    }
  }, [field.value]); //eslint-disable-line

  // If we should use the native datepicker, return just the <DateInput /> component
  if (useOwnDatePicker) {
    return (
      <ReactDatePicker
        wrapperClassName='w-full'
        showPopperArrow={false}
        showYearDropdown={true}
        yearDropdownItemNumber={30}
        scrollableYearDropdown={true}
        tabIndex={tabIndex}
        selected={startDate}
        dateFormat='dd/MM/yyyy'
        placeholderText='dd/mm/yyyy'
        autoComplete={autoComplete}
        disabled={disabled}
        onChange={date => {
          date && setStartDate(date);

          // the React-Datepicker removes the name from the input
          // therefor we should update the value via the onchange manually
          // We also only send the date part, and not the time/timezone etc...
          field.onChange(date?.toISOString().substring(0, 10));
        }}
        customInput={
          <TextInput
            {...field}
            autoComplete={autoComplete}
            disabled={disabled}
            required={required}
            hint={hint}
            label={label}
            error={error}
            invalid={invalid}
          />
        }
        // place the datapicker always on top
        popperClassName='!z-[9999]'
        // place the picker in a portal with ID=date-picker-portal
        portalId='date-picker-portal'
        todayButton={t('today', 'Today')}
      />
    );
  } else {
    return (
      <DateInputNative
        {...field}
        disabled={disabled}
        required={required}
        hint={hint}
        tabIndex={tabIndex}
        label={label}
        error={error}
        invalid={invalid}
        className={className}
        autoComplete={autoComplete}
      />
    );
  }
}

export default DateInput;
