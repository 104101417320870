import React from 'react';
import Tile from '../Tile';
import { PublicHorsePage } from 'openapi';
import { useTranslation } from 'react-i18next';
import { BuildingOffice, EnvelopeSimple, Globe, MapPin, Phone } from '@phosphor-icons/react';
import DetailRow from '../DetailRow';
import { contactAddress } from 'utilities/Contact';
import UseCountries from 'hooks/UseCountries';

interface Props {
  horsePage: PublicHorsePage | undefined;
  loading: boolean;
}

function OrganizationTile({ horsePage, loading }: Props): JSX.Element {
  const { t } = useTranslation();
  const { countries } = UseCountries();

  return (
    <Tile title={t('contact', 'Contact')}>
      <ul className='mt-4 space-y-4'>
        <DetailRow loading={loading} icon={<BuildingOffice />} name={t('name', 'Name')} value={horsePage?.horse.organisation.name || '-'} />
        <DetailRow
          loading={loading}
          icon={<EnvelopeSimple />}
          name={t('email-address', 'Email address')}
          value={horsePage?.horse.organisation.email_address || '-'}
        />
        <DetailRow
          loading={loading}
          icon={<Phone />}
          name={t('phone-number', 'Phone number')}
          value={horsePage?.horse.organisation.phone_number || '-'}
        />
        <DetailRow loading={loading} icon={<Globe />} name={t('website', 'Website')} value={horsePage?.horse.organisation.website || '-'} />
        <DetailRow
          loading={loading}
          icon={<MapPin />}
          name={t('address', 'Address')}
          value={horsePage?.horse.organisation ? contactAddress(horsePage.horse.organisation, countries).join(', ') : '-'}
        />
      </ul>
    </Tile>
  );
}

export default OrganizationTile;
