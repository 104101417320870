import { Contact, NameAndAddress, PublichHorsePageOrganisation, Stable } from 'openapi';
import { AppliedListFilter } from '../components/Common/ListFilter';
import { Country } from 'hooks/UseCountries/countries.data';
import fuzzysort from 'fuzzysort';

export enum ContactType {
  Stable,
  User,
  Contact,
  Business,
}

export interface InvoiceReceiver {
  business_name?: string;
  first_name?: string;
  last_name?: string;
  address_line1?: string;
  address_line2?: string;
  address_line3?: string;
  city?: string;
  postcode?: string;
  state?: string;
  country?: string;
}

export interface InvoiceSender {
  name: string;
  address_line1?: string;
  address_line2?: string;
  address_line3?: string;
  logo?: string | null;
  city?: string;
  postcode?: string;
  phone_number?: string;
  invoice_mail_address?: string;
  bank_code?: string;
  bank_name?: string;
  account_number?: string;
  vat_number?: string;
  state?: string;
  country?: string;
}

export const getContactType = (contact: Contact): ContactType => {
  if (contact.stable_location_uid) {
    return ContactType.Stable;
  } else if (contact.user_uid) {
    return ContactType.User;
  }
  if (contact.business_name) {
    return ContactType.Business;
  }
  return ContactType.Contact;
};

export const contactName = (contact: Contact | InvoiceReceiver | NameAndAddress): string | undefined => {
  let first: string | undefined = undefined;
  let second: string | undefined = undefined;

  if (contact.business_name) {
    first = contact.business_name;
  }

  if (contact.first_name || contact.last_name) {
    let contactName = '';
    contactName += contact.first_name;
    if (contact.first_name && contact.last_name) {
      contactName += ' ';
    }
    contactName += contact.last_name;

    if (first) {
      second = first;
    }
    first = contactName;
  }

  if (!first && !second) {
    return undefined;
  } else if (first && second) {
    return `${first} (${second})`;
  } else {
    return first ?? second ?? '';
  }
};

// An ordered list of all address info of the contact.
export const contactAddress = (
  contact: Contact | InvoiceReceiver | InvoiceSender | NameAndAddress | PublichHorsePageOrganisation,
  countries: Country[],
): string[] => {
  const result: string[] = [];
  if (contact.address_line1) {
    if (contact.address_line3) {
      result.push(`${contact.address_line1} ${contact.address_line3}`);
    } else {
      result.push(contact.address_line1);
    }
  }
  if (contact.address_line2) {
    result.push(contact.address_line2);
  }
  if (contact.city || contact.postcode) {
    if (contact.city && contact.postcode) {
      result.push(`${contact.postcode} ${contact.city}`);
    } else {
      result.push(contact.city ?? contact.postcode ?? '');
    }
  }
  if (contact.state) {
    result.push(contact.state);
  }
  if (contact.country) {
    const foundCountry = countries.find(country => country.id === contact.country);
    if (foundCountry) {
      result.push(foundCountry.name);
    } else {
      result.push(contact.country.toString());
    }
  }
  return result;
};

export const contactInitials = (contact: Contact): string => {
  if (contact.business_name) {
    // If it's a business then return the first char of the business name.
    return contact.business_name[0].toUpperCase();
  } else {
    if (contact.first_name && contact.last_name) {
      return contact.first_name[0].toUpperCase() + contact.last_name[0].toUpperCase();
    } else if (contact.first_name || contact.last_name) {
      const name = contact.first_name ?? contact.last_name;
      if (name) return name.slice(0, 2).toUpperCase();
    }
    return '??';
  }
};

// Filter by Applied List Filters
export const listFilter = (contacts: Contact[], appliedListFilter: AppliedListFilter[]): Contact[] => {
  if (appliedListFilter.length === 0) {
    return contacts;
  }

  return contacts.filter(contact => {
    for (const alf of appliedListFilter) {
      if (alf.type.id === 'type') {
        if (
          !alf.options.find(o => {
            return (
              (o.id === 'business' && ContactType.Business === getContactType(contact)) ||
              (o.id === 'stable' && ContactType.Stable === getContactType(contact)) ||
              (o.id === 'personal' && [ContactType.Contact, ContactType.User].includes(getContactType(contact)))
            );
          })
        ) {
          return false;
        }
      }

      if (alf.type.id === 'hasAccount') {
        if (
          !alf.options.find(o => {
            return (o.id === 'yes' && contact.user_uid) || (o.id === 'no' && !contact.user_uid);
          })
        ) {
          return false;
        }
      }

      if (alf.type.id === 'stable') {
        if (
          !alf.options.find(o => {
            return contact.stables?.includes(o.id);
          })
        ) {
          return false;
        }
      }
    }

    return true;
  });
};

// Filter by Applied List Filters
export const listFilterStable = (stables: Stable[], appliedListFilter: AppliedListFilter[]): Stable[] => {
  if (appliedListFilter.length === 0) {
    return stables;
  }

  return stables.filter(stable => {
    for (const alf of appliedListFilter) {
      if (alf.type.id === 'stable') {
        if (!alf.options.find(o => stable.uid === o.id)) {
          return false;
        }
      }
    }

    return true;
  });
};

// Filter and sort contacts by an input string.
export const textFilter = (contacts: Contact[], textFilter: string): Contact[] => {
  if (!textFilter || textFilter === '') {
    return contacts;
  }

  // Use fuzzysort to filter and sort the contacts.
  return fuzzysort
    .go<Contact>(textFilter, contacts, {
      keys: [
        'first_name',
        'last_name',
        'business_name',
        'address_line1',
        'address_line2',
        'address_line3',
        'email',
        'state',
        'postcode',
        'city',
        'phone_number',
        'second_phone_number',
        'vat_number',
        'company_registration_number',
      ],
    })
    .map(found => found.obj);
};

/**
 * Get the location name
 */
export const getLocationName = (locationUid: string | undefined | null, contacts: Contact[] | undefined): string => {
  const find = contacts?.find(contact => contact.uid === locationUid);
  if (find) {
    return contactName(find) ?? '-';
  }

  return '-';
};
