import { Truck } from '@phosphor-icons/react';
import useApiPromises from 'api/hooks/useApiPromises';
import Picklist from 'components/Breeding/Picklist';
import PickSemenOrder from 'components/Breeding/PickSemenOrder';
import FilterSelectButton from 'components/Common/ListFilter/FilterSelectButton';
import FilterWrapper from 'components/Common/ListFilter/FilterWrapper';
import HorseSelectButton from 'components/Horses/HorseSelectButton';
import { useAccount } from 'context/AccountContext';
import { useOrganization } from 'context/OrganizationContext';
import { PageAction } from 'context/PageContext';
import { addDays, isToday } from 'date-fns';
import useDayNavigationFilter from 'hooks/UseDayNavigationFilter';
import {
  CategoriesService,
  Category,
  Horse,
  HorsesService,
  OrdersService,
  Product,
  ProductsService,
  SemenTypeEnum,
  SupplierOrder,
} from 'openapi';
import { SexEnum } from 'openapi/models/SexEnum';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Page from 'ui/Layout/Page';
import { Tile } from 'ui/Layout/Tile';
import PullScrollWrapper from 'ui/PullScrollWrapper';
import { ApiPromises } from 'utilities/ApiPromises';
import { formatDate } from 'utilities/date.utilities';

export default function PicklistPage(): JSX.Element {
  const { t } = useTranslation();
  const [horses, setHorses] = useState<Horse[]>();
  const [productCategories, setProductCategories] = useState<Category[]>();
  const { selectedOrganization } = useOrganization();
  const [pickOrder, setPickOrder] = useState<SupplierOrder | undefined>();
  const [pickOrderModalOpen, setPickOrderModalOpen] = useState<boolean>(false);
  const [orders, setOrders] = useState<SupplierOrder[]>();
  const [nonPickedOlderOrders, setNonPickedOlderOrders] = useState<SupplierOrder[]>();
  const [products, setProducts] = useState<Product[]>();
  // When the selected day is undefined then it's 'today'.
  // null means that the user selected a date but now resetting it to 'today'
  const [selectedDay, setSelectedDay] = useState<Date | null | undefined>(undefined);
  const { formatDate: formatUserDate } = useAccount();
  const [apiPromises, setApiPromises] = useState<ApiPromises>();
  const { loading } = useApiPromises({ apiPromises });

  // filter options
  // we use null to indicate that the user selected a horse but now reset it
  const [selectedHorse, setSelectedHorses] = useState<Horse | null | undefined>(undefined);
  // we use null to indicate that the user selected a horse but now reset it
  const [selectedShippingProduct, setSelectedShippingProduct] = useState<Product | null | undefined>(undefined);

  // Todo: Implement useNow() hook to refresh 'today' after midnight.

  const selectedDayString = selectedDay ? formatUserDate(selectedDay) : t('today', 'Today');

  const { element: dayNavigationFilterElement } = useDayNavigationFilter({
    onPrevDay: () => {
      // reset the current orders
      setOrders(undefined);
      setNonPickedOlderOrders(undefined);

      setSelectedDay(prevState => {
        const newDate = addDays(prevState ?? new Date(), -1);
        setOrders(undefined);
        setNonPickedOlderOrders(undefined);
        return isToday(newDate) ? null : newDate;
      });
    },
    onNextDay: () => {
      // reset the current orders
      setOrders(undefined);
      setNonPickedOlderOrders(undefined);

      setSelectedDay(prevState => {
        const newDate = addDays(prevState ?? new Date(), 1);
        return isToday(newDate) ? null : newDate;
      });
    },
    onSelectDate: date => {
      // reset the current orders
      setOrders(undefined);
      setNonPickedOlderOrders(undefined);

      setSelectedDay(isToday(date) ? null : date);
    },
    selectDate: selectedDay ?? new Date(),
  });

  const stallions = useMemo(() => {
    if (!horses) return [];
    return horses.filter(horse => horse.sex === SexEnum._1);
  }, [horses]);

  const stallionProducts = useMemo(() => {
    if (!products) return [];
    return products.filter(product => product.stallion !== undefined);
  }, [products]);

  /**
   * Return the stallions that only are products for filtering
   */
  const stallionProductsAsHorse = useMemo(() => {
    if (!stallionProducts) return [];
    return stallionProducts
      .map(product => {
        return horses?.find(horse => horse.uid === product.stallion);
      })
      .filter(horse => horse !== undefined);
  }, [horses, stallionProducts]);

  /**
   * List the shipping products
   */
  const shippingProducts = useMemo((): Product[] => {
    if (!products || !productCategories) return [];

    const shippingProductCategory = productCategories.find(cat => cat.default === 'SHIPPING');
    return products.filter(prod => prod.category === shippingProductCategory?.uid);
  }, [products, productCategories]);

  const frozenOrders = useMemo((): SupplierOrder[] => {
    if (!orders) {
      return [];
    }
    let result = orders;
    // Add the non-picked overdue orders when in the 'today' view.
    if (!selectedDay && nonPickedOlderOrders) {
      result = result.concat(nonPickedOlderOrders);
    }
    return (
      result?.filter(order => {
        return (order.order_items ?? []).filter(item => item.semen_type === SemenTypeEnum.FROZEN).length > 0;
      }) ?? []
    );
  }, [orders, nonPickedOlderOrders, selectedDay]);

  const freshOrders = useMemo((): SupplierOrder[] => {
    if (!orders) {
      return [];
    }
    let result = orders;
    // Add the non-picked overdue orders when in the 'today' view.
    if (!selectedDay && nonPickedOlderOrders) {
      result = result.concat(nonPickedOlderOrders);
    }
    return (
      result?.filter(order => {
        return (order.order_items ?? []).filter(item => item.semen_type === SemenTypeEnum.FRESH).length > 0;
      }) ?? []
    );
  }, [orders, nonPickedOlderOrders, selectedDay]);

  const pageActions = useMemo((): PageAction[] => {
    return [];
  }, []);

  // Load data from the api/cache
  const loadApiData = useCallback(
    (options: { loadOrders: boolean; loadNonPickedOrders: boolean } | undefined): ApiPromises => {
      const promises = new ApiPromises();
      if (!selectedOrganization) {
        return promises;
      }

      let orderitemProductUidAnd: string[] | undefined;

      // find the actual product for the selected horse
      // and build the orderitemProductUidIn array for the api call
      const selectedHorseProducts = stallionProducts.find(product => product.stallion === selectedHorse?.uid);
      if (selectedHorseProducts) {
        orderitemProductUidAnd =
          orderitemProductUidAnd === undefined ? [selectedHorseProducts.uid] : [...orderitemProductUidAnd, selectedHorseProducts.uid];
      }

      // Build the orderitemProductUidIn array for the api call
      if (selectedShippingProduct) {
        orderitemProductUidAnd =
          orderitemProductUidAnd === undefined ? [selectedShippingProduct.uid] : [...orderitemProductUidAnd, selectedShippingProduct.uid];
      }

      // Load the orders from api
      if (!options || options.loadOrders || selectedHorse) {
        promises.appendList<SupplierOrder>(
          'orders',
          () =>
            OrdersService.ordersSuppliedList({
              supplierUid: selectedOrganization?.uid ?? '',
              o: '-created_on,-id',
              shippingDate: selectedDay ? formatDate(selectedDay) : formatDate(new Date()),
              orderitemProductUidAnd: orderitemProductUidAnd,
            }),
          setOrders,
        );
      }

      // Load the orders from api that are not picked. We use this orders in the
      // today-view so we always keep reminded of non-picked orders. The orders in
      // this list are older then today.
      if (!options || options.loadNonPickedOrders || selectedHorseProducts) {
        promises.appendList<SupplierOrder>(
          'nonPickedOrders',
          () => {
            const yesterday = new Date(Date.now() - 86400000); // that is: 24 * 60 * 60 * 1000
            return OrdersService.ordersSuppliedList({
              supplierUid: selectedOrganization?.uid ?? '',
              o: '-created_on,-id',
              isPicked: false,
              shippingDateLte: formatDate(yesterday),
              orderitemProductUidAnd,
            });
          },
          setNonPickedOlderOrders,
        );
      }

      if (!options && !selectedHorseProducts) {
        // Load horses
        promises.appendList<Horse>(
          'horses',
          () =>
            HorsesService.horsesList({
              organisationUid: selectedOrganization?.uid ?? '',
              onUnknownLocation: false,
            }),
          setHorses,
        );

        promises.appendList<Product>(
          'products',
          () =>
            ProductsService.productsList({
              organisationUid: selectedOrganization?.uid ?? '',
              // @todo: Maybe already filter here on semen stallion products
            }),
          setProducts,
        );

        promises.appendList<Category>(
          'categories',
          () =>
            CategoriesService.categoriesList({
              organisationUid: selectedOrganization.uid,
            }),
          setProductCategories,
        );
      }

      setApiPromises(promises);
      return promises;
    },
    [selectedDay, selectedHorse, selectedOrganization, selectedShippingProduct, stallionProducts],
  );

  /**
   * Load from the api
   */
  useEffect(() => {
    if (selectedOrganization && selectedDay === undefined) {
      const promise = loadApiData(undefined);
      return () => promise.cancel();
    }
  }, [selectedOrganization, selectedDay]); //eslint-disable-line

  /**
   * Load (only non-picked orders and orders) from the api when the selected day changes
   * in either a value or null (null means that the user selected a date but now resetting it to 'today').
   */
  useEffect(() => {
    if (selectedDay || selectedDay === null) {
      const promise = loadApiData({ loadNonPickedOrders: true, loadOrders: true });
      return () => promise.cancel();
    }
  }, [selectedDay]); // eslint-disable-line

  /**
   * Trigger the loading of the orders when the selected horse changes.
   */
  useEffect(() => {
    if (selectedHorse !== undefined || selectedShippingProduct !== undefined) {
      const promise = loadApiData({ loadNonPickedOrders: true, loadOrders: true });
      return () => promise.cancel();
    }
  }, [selectedHorse, selectedShippingProduct]); // eslint-disable-line

  const pick = (order: SupplierOrder) => {
    setPickOrder(order);
    setPickOrderModalOpen(true);
  };

  return (
    <Page title={`${t('semen-orders-picklist-title', 'Picklist for')} ${selectedDayString}`} loading={apiPromises} actions={pageActions}>
      <PullScrollWrapper apiPromises={apiPromises}>
        <Tile noBoxOnMobile={true}>
          <FilterWrapper listFilterTypes={undefined}>
            <HorseSelectButton
              stallionsOnly={true}
              selectedHorse={selectedHorse || undefined}
              horses={stallionProductsAsHorse}
              horseSelected={horse => {
                setOrders(undefined);
                setNonPickedOlderOrders(undefined);
                // reset it to null to indicate that the user selected a horse but now reset it
                setSelectedHorses(horse ?? null);
              }}
              reduceSpaceOnMobile={true}
            />
            <FilterSelectButton<Product>
              options={shippingProducts}
              display={product => {
                if (!product) return '<no-name>';
                return `${product.shipping_provider_name} (${product.shipping_service_type})`;
              }}
              idField='uid'
              onFilter={(list, search) => list.filter(prov => (prov.shipping_provider_name?.toLocaleLowerCase() ?? '').includes(search))}
              onSelected={selected => {
                setOrders(undefined);
                setNonPickedOlderOrders(undefined);
                // reset it to null to indicate that the user selected a horse but now reset it
                setSelectedShippingProduct(selected ?? null);
              }}
              selectedOption={selectedShippingProduct || undefined}
              title={t('select-shipping-service', 'Select a shipping service')}
              buttonText={t('choose-shipping-service', 'Shipping service')}
              icon={<Truck />}
              reduceSpaceOnMobile={true}
            />
            <div className='ml-auto'>{dayNavigationFilterElement}</div>
          </FilterWrapper>
          <Picklist
            loading={loading}
            products={stallionProducts}
            stallions={stallions}
            productCategories={productCategories ?? []}
            freshOrders={freshOrders}
            frozenOrders={frozenOrders}
            pick={pick}
          />
        </Tile>
      </PullScrollWrapper>

      <PickSemenOrder
        products={stallionProducts}
        stallions={stallions}
        productCategories={productCategories ?? []}
        order={pickOrder}
        open={pickOrderModalOpen}
        onClose={(reload: boolean) => {
          if (reload) {
            loadApiData({ loadNonPickedOrders: true, loadOrders: true });
          }
          setPickOrderModalOpen(false);
        }}
      />
    </Page>
  );
}
