import { Check, Package } from '@phosphor-icons/react';
import classNames from 'classnames';
import { useAccount } from 'context/AccountContext';
import { Category, Horse, Product, SupplierOrder } from 'openapi';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import Badge from 'ui/Badge';
import Button, { ButtonVariant } from 'ui/Button';
import { AllColors } from 'utilities/colors';
import {
  table,
  tableHiddenColumnMd,
  tableHiddenHeaderMd,
  tableTbody,
  tableTbodyTrNoClick,
  tableTheadTd,
  tableTheadTdSticky,
} from 'ui/Const';
import { deliveryAddress, deliveryName, shippingName } from './Helpers';
import { Spinner } from 'ui/Loading';
import { SpinnerSize } from 'ui/Loading/Spinner';

interface Props {
  loading: boolean;
  freshOrders: SupplierOrder[];
  frozenOrders: SupplierOrder[];
  stallions: Horse[];
  products: Product[]; // Only pass the semen stallion products here
  productCategories: Category[];
  pick: (order: SupplierOrder) => void;
}

export default function Picklist({ loading, freshOrders, frozenOrders, stallions, products, productCategories, pick }: Props): JSX.Element {
  const { t } = useTranslation();
  const { formatDate } = useAccount();

  const productName = useCallback(
    (order: SupplierOrder) => {
      if (!order.order_items) {
        return '';
      }
      const breedingProductCategory = productCategories.find(cat => cat.default === 'BREEDING');
      const result: string[] = [];
      order.order_items.forEach(orderItem => {
        const product = products.find(prod => prod.uid === orderItem.product_uid);
        if (!product || product.category !== breedingProductCategory?.uid) return;
        const stallionName = stallions.find(horse => horse.uid === product.stallion)?.name ?? 'unknown';
        result.push(stallionName);
      });
      return result.join(', ');
    },
    [products, stallions, productCategories],
  );

  const isPicked = (order: SupplierOrder): boolean => {
    if (!order.order_items) {
      return false;
    }
    return order.order_items
      .filter(orderItem => {
        const breedingProductCategory = productCategories.find(cat => cat.default === 'BREEDING');
        const product = products.find(product => product.uid === orderItem.product_uid);
        // DEFAULT product types are also pickable. Let's include them too.
        return product && product.category === breedingProductCategory?.uid;
      })
      .every(line => line.picked_on);
  };

  // True when the shipping date is before today.
  const shippingIsOverdue = (order: SupplierOrder): boolean => {
    if (!order.shipping_date) {
      return false;
    }
    // When the order is picked, it's not overdue.
    if (isPicked(order)) {
      return false;
    }
    const shippingDate = new Date(Date.parse(order.shipping_date));
    const today = new Date(new Date().toISOString().substring(0, 10));
    return shippingDate < today;
  };

  const headingClass = 'text-lg md:text-xl font-medium my-2 md:my-6 w-full ml-2 md:ml-0 md:pl-3';

  return (
    <table className={table}>
      <thead>
        <tr>
          <td colSpan={8}>
            <h2 className={headingClass}>{t('semen-fresh', 'Fresh')}</h2>
          </td>
        </tr>
        <tr className={tableHiddenHeaderMd}>
          <td className={classNames('w-10', tableTheadTdSticky)} />
          <td className={classNames(tableTheadTd, tableTheadTdSticky)}>
            {t('requested-shipping-shipping-date', 'Shipping date (requested)')}
          </td>
          <td className={classNames(tableTheadTd, tableTheadTdSticky)}>{t('stallion', 'Stallion')}</td>
          <td className={classNames(tableTheadTd, tableTheadTdSticky)}>{t('order-delivery-address', 'Delivery address')}</td>
          <td className={classNames(tableTheadTd, tableTheadTdSticky)}>{t('shipping', 'Shipping')}</td>
          <td className={classNames(tableTheadTd, tableTheadTdSticky)}>{t('order-no', 'Order No.')}</td>
          <td className={classNames(tableTheadTd, tableTheadTdSticky, 'text-center')}>{t('order-pick-table-title', 'Picked')}</td>
        </tr>
      </thead>
      <tbody className={tableTbody}>
        {!loading && freshOrders.length === 0 && (
          <tr>
            <td className='md:text-center p-2 md:p-12 text-gray-600 italic' colSpan={7}>
              {t('no-orders', 'No orders')}
            </td>
          </tr>
        )}
        {loading && (
          <tr>
            <td className='md:text-center p-2 md:p-12 text-gray-600 italic' colSpan={7}>
              <div className='flex items-center justify-center gap-x-1'>
                <Spinner size={SpinnerSize.XSmall} />
                {t('loading', 'Loading')}
              </div>
            </td>
          </tr>
        )}
        {freshOrders.map(order => (
          <tr className={tableTbodyTrNoClick} key={order.uid}>
            <td className='text-center w-10'>
              <Package size={24} weight='light' className='inline' />
            </td>
            <td>
              {order.shipping_date && formatDate(new Date(Date.parse(order.shipping_date)))}{' '}
              {shippingIsOverdue(order) && <Badge color={AllColors.Rose}>{t('overdue', 'Overdue')}</Badge>}
            </td>
            <td className={tableHiddenColumnMd}>{productName(order)}</td>
            <td className={tableHiddenColumnMd}>
              {deliveryName(order)} - {deliveryAddress(order)}
            </td>
            <td className={tableHiddenColumnMd}>{shippingName(t, products, productCategories, order)}</td>
            <td>{order.uid}</td>
            <td className='text-right md:text-center pr-2'>
              {!isPicked(order) && (
                <Button onClick={() => pick(order)} variant={ButtonVariant.Primary}>
                  {t('pick-order', 'Pick')}
                </Button>
              )}
              {isPicked(order) && <Check size={22} className='inline' />}
            </td>
          </tr>
        ))}
      </tbody>

      <thead>
        <tr>
          <td colSpan={7}>
            <h2 className={headingClass}>{t('semen-frozen', 'Frozen')}</h2>
          </td>
        </tr>
        <tr className={tableHiddenHeaderMd}>
          <td className={classNames('w-10', tableTheadTdSticky)} />
          <td className={classNames(tableTheadTd, tableTheadTdSticky)}>
            {t('requested-shipping-shipping-date', 'Shipping date (requested)')}
          </td>
          <td className={classNames(tableTheadTd, tableTheadTdSticky)}>{t('stallion', 'Stallion')}</td>
          <td className={classNames(tableTheadTd, tableTheadTdSticky)}>{t('order-delivery-address', 'Delivery address')}</td>
          <td className={classNames(tableTheadTd, tableTheadTdSticky)}>{t('shipping', 'Shipping')}</td>
          <td className={classNames(tableTheadTd, tableTheadTdSticky)}>{t('order-no', 'Order No.')}</td>
          <td className={classNames(tableTheadTd, tableTheadTdSticky, 'text-center')}>{t('order-pick-table-title', 'Picked')}</td>
        </tr>
      </thead>
      <tbody className={tableTbody}>
        {!loading && frozenOrders.length === 0 && (
          <tr>
            <td className='md:text-center p-2 md:p-12 text-gray-600 italic' colSpan={7}>
              {t('no-orders', 'No orders')}
            </td>
          </tr>
        )}

        {loading && (
          <tr>
            <td className='md:text-center p-2 md:p-12 text-gray-600 italic' colSpan={7}>
              <div className='flex items-center justify-center gap-x-1'>
                <Spinner size={SpinnerSize.XSmall} />
                {t('loading', 'Loading')}
              </div>
            </td>
          </tr>
        )}

        {frozenOrders.map(order => (
          <tr className={tableTbodyTrNoClick} key={order.uid}>
            <td className='text-center w-10'>
              <Package size={24} weight='light' className='inline' />
            </td>
            <td>
              {order.shipping_date && formatDate(new Date(Date.parse(order.shipping_date)))}{' '}
              {shippingIsOverdue(order) && <Badge color={AllColors.Rose}>{t('overdue', 'Overdue')}</Badge>}
            </td>
            <td className={tableHiddenColumnMd}>{productName(order)}</td>
            <td className={tableHiddenColumnMd}>
              {deliveryName(order)} - {deliveryAddress(order)}
            </td>
            <td className={tableHiddenColumnMd}>{shippingName(t, products, productCategories, order)}</td>
            <td>{order.uid}</td>
            <td className='text-right md:text-center pr-2'>
              {!isPicked(order) && (
                <Button onClick={() => pick(order)} variant={ButtonVariant.Primary}>
                  {t('pick-order', 'Pick')}
                </Button>
              )}
              {isPicked(order) && <Check size={22} className='inline' />}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
