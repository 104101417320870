import { zodResolver } from '@hookform/resolvers/zod';
import ApiErrorParser from 'api/ApiErrorParser';
import useFormError from 'api/hooks/useFormError';
import ContactInputSelect from 'components/Contacts/ContactInputSelect';
import { useOrganization } from 'context/OrganizationContext';
import {
  CancelablePromise,
  Contact,
  Horse,
  SupplierOrderItem,
  OrdersService,
  Product,
  SemenTypeEnum,
  StudbookEnum,
  UsageTypeEnum,
  PaginatedHorseList,
  HorsesService,
  ShippingServiceTypeEnum,
  SupplierOrderDetail,
  PatchedSupplierOrderDetail,
  Category,
} from 'openapi';
import { SexEnum } from 'openapi/models/SexEnum';
import { schemas } from 'openapi/zod-schemas';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ButtonVariant } from 'ui/Button';
import { ErrorSection } from 'ui/Error';
import { DateInput, SelectInput, TextAreaInput, TextInput } from 'ui/Inputs';
import RadioButtonGroup from 'ui/Inputs/RadioGroupInput';
import { OptionItemInterface } from 'ui/Inputs/SelectInput';
import { PageModal } from 'ui/Modals';
import { ActionProps, PageModalActions, PageModalContent, PageModalTitle, PageModalWidth } from 'ui/Modals/PageModal';
import Stepper from 'ui/Stepper';
import { contactName } from 'utilities/Contact';
import { transformEmptyToUndefined } from 'utilities/zod';
import { z } from 'zod';
import { useAccount } from 'context/AccountContext';
import UpdateAddressModal from 'components/Contacts/Modals/UpdateAddressModal';
import useModal from 'ui/Modals/UseModal';
import HorseInputSelect from 'components/Horses/HorseInputSelect';
import { BreedingProductOptions, SemenTypeOptions, ShippingOptions, SemenUsageTypeOptions } from './Helpers';
import { Alert } from 'ui/Alert';
import OrderSummaryTable from './OrderSummaryTable';
import { Severity } from 'utilities/severity';
import { objectDiff } from 'utilities/compareObject';
import ContactAddress from 'components/Contacts/ContactAddress';
import { SupplierOrderItemDetail } from 'openapi/models/SupplierOrderItemDetail';
import useCountries from 'hooks/UseCountries';
import { studBookMap } from './Studbook';
import StudbookInputSelect from 'components/Breeding/StudbookInputSelect';

interface Props {
  existingOrder?: SupplierOrderDetail; // For editing an order
  repeatOrder?: SupplierOrderDetail; // A new order can be a followup on a previous order. This happens when the mare doesn't get pregnant. The breeding costs of a repeat order are 0.
  horses: Horse[];
  products: Product[];
  categories: Category[];
  contacts: Contact[];
  open: boolean;
  onRequestClose: (reload: boolean) => void;
  onContactUpdate: () => void;
  onClosed?: () => void;
}

const SaveSemenOrderSchema = z.object({
  // Order fields
  customer: z.string(),
  receiver: z.string(),
  supplier_note: z.string(),
  shipping_date: z.string(),
  shipping_product: z.string(),

  // Semen order line fields.
  product: z.string(),
  product_price: z.string().optional(),
  semen_collection_station: z.string().optional(),
  mare: z.string(),
  semen_type: schemas.SemenTypeEnum,
  usage_type: schemas.UsageTypeEnum,
  studbook: schemas.StudbookEnum,
});

type SaveSemenOrder = z.infer<typeof SaveSemenOrderSchema>;

const transformFromOrderDetail = (
  existingOrder: SupplierOrderDetail,
  products: Product[],
  productCategories: Category[],
): Partial<SaveSemenOrder> => {
  const breedingProductCategory = productCategories.find(cat => cat.default === 'BREEDING');
  const shippingProductCategory = productCategories.find(cat => cat.default === 'SHIPPING');

  const breedingItems = (existingOrder.order_items ?? []).filter(
    item =>
      item.product_uid === products.find(prod => prod.uid === item.product_uid && prod.category === breedingProductCategory?.uid)?.uid,
  );
  const shippingItems = (existingOrder.order_items ?? []).filter(
    item =>
      item.product_uid === products.find(prod => prod.uid === item.product_uid && prod.category === shippingProductCategory?.uid)?.uid,
  );
  if (breedingItems.length > 1) {
    console.error('Editing a order with multiple breeding products is not supported. Taking the first from the list.');
  }

  if (breedingItems.length === 0) {
    console.error('Breeding product not found in order list');
    // Fill in as much as possible to still show the user something.
    return {
      customer: existingOrder.customer_uid,
      receiver: existingOrder.receiver_uid,
      supplier_note: existingOrder.supplier_note,
      shipping_date: existingOrder.shipping_date ?? undefined,
    };
  }

  const breedingItem = breedingItems[0];
  const shippingItem = shippingItems.length > 0 ? shippingItems[0] : undefined;

  return {
    customer: existingOrder.customer_uid,
    receiver: existingOrder.receiver_uid,
    supplier_note: existingOrder.supplier_note,
    shipping_date: existingOrder.shipping_date ?? undefined,
    product: breedingItem.product_uid ?? undefined,
    shipping_product: shippingItem?.product_uid,
    mare: breedingItem.mare_uid ?? undefined,
    product_price: breedingItem.unit_price,
    semen_type: breedingItem.semen_type,
    usage_type: breedingItem.usage_type,
    studbook: breedingItem.studbook,
  };
};

/**
 * We use the AddSemenOrder type for our form input. We do this because the
 * mismatch between de data type that we need to POST to the server and user
 * input is too big. The AddSemenOrder type is defined above and used for the
 * zod validator. The method transforms the AddSemenOrder to an OrderDetail
 * which is required by the server.
 */
const transformToOrderDetail = (
  data: SaveSemenOrder,
  dryRun: boolean,
  productCategories: Category[],
  products: Product[] | undefined,
  existingOrder: SupplierOrderDetail | undefined,
  repeatOrder: SupplierOrderDetail | undefined,
  defaultCurrency = 'EUR',
): Partial<SupplierOrderDetail> => {
  if (!products) {
    return {};
  }
  const breedingProductCategory = productCategories.find(cat => cat.default === 'BREEDING');
  const shippingProductCategory = productCategories.find(cat => cat.default === 'SHIPPING');

  const breedingItems = (existingOrder?.order_items ?? []).filter(
    item =>
      item.product_uid === products.find(prod => prod.uid === item.product_uid && prod.category === breedingProductCategory?.uid)?.uid,
  );
  const shippingItems = (existingOrder?.order_items ?? []).filter(
    item =>
      item.product_uid === products.find(prod => prod.uid === item.product_uid && prod.category === shippingProductCategory?.uid)?.uid,
  );

  if (breedingItems.length > 1) {
    console.error('Editing a order with multiple breeding products is not supported. Taking the first from the list.');
  }

  const foundBreedingItem = breedingItems.length === 0 ? undefined : breedingItems[0];
  const foundShippingItem = breedingItems.length === 0 ? undefined : shippingItems[0];

  // If it's a repeat order, then get the original order item this order is based upon.
  let repeatOrderItemUid: string | null = null;
  if (repeatOrder) {
    const repeatOrderItem = repeatOrder.order_items?.find(item => item.mare_uid);
    if (!repeatOrderItem?.uid) {
      throw Error('Trying to create a repeat order but the original order item cannot be found.');
    }
    repeatOrderItemUid = repeatOrderItem.uid;
  } else if (foundBreedingItem && foundBreedingItem.previous_semen_order_item_uid) {
    repeatOrderItemUid = foundBreedingItem.previous_semen_order_item_uid;
  }

  let semenOrderLine: Partial<SupplierOrderItemDetail> = {
    product_uid: data.product,
    mare_uid: data.mare,
    semen_type: data.semen_type as SemenTypeEnum,
    usage_type: data.usage_type as UsageTypeEnum,
    quantity: 1, // Fixed amount of one breeding order.
    studbook: data.studbook as StudbookEnum,
    unit_price: data.product_price,
    unit_price_currency: foundBreedingItem?.unit_price_currency ?? defaultCurrency,
    // Provide the previous semen order line if this instance is a follow-up
    // for the same mare (whose impregnation failed).
    previous_semen_order_item_uid: repeatOrderItemUid,
  };

  let shippingOrderLine: Partial<SupplierOrderItem> = {
    product_uid: data.shipping_product,
    unit_price_currency: foundShippingItem?.unit_price_currency ?? defaultCurrency,
  };

  // On patch the differences if we're changing the order.
  if (foundBreedingItem) {
    semenOrderLine = { uid: foundBreedingItem?.uid, ...objectDiff(foundBreedingItem, semenOrderLine) };
  }

  // On patch the differences if we're changing the order.
  if (foundShippingItem) {
    shippingOrderLine = { uid: foundShippingItem?.uid, ...objectDiff(foundShippingItem, shippingOrderLine) };
  }

  let order: Partial<SupplierOrderDetail> = {
    customer_uid: data.customer,
    receiver_uid: data.receiver,
    supplier_note: data.supplier_note,
    shipping_date: data.shipping_date,
  };

  if (existingOrder) {
    order = objectDiff(existingOrder, order);
  }

  order.order_items = [semenOrderLine as SupplierOrderItemDetail, shippingOrderLine as SupplierOrderItemDetail];
  order.dry_run = dryRun;
  return order;
};

// Return date of today in YYYY-MM-DD format.
const today = (): string => {
  return new Date().toISOString().substr(0, 10);
};

function SaveSemenOrder({
  existingOrder,
  repeatOrder,
  horses,
  products,
  categories,
  contacts,
  open,
  onRequestClose,
  onContactUpdate,
  onClosed,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const { selectedOrganizationUid, selectedOrganizationDetails } = useOrganization();
  const [customerHorses, setCustomerHorses] = useState<Horse[] | undefined>();
  const [step, setStep] = useState<number>(1);
  const [stepsWithError, setStepsWithError] = useState<number[]>([]);
  const { accountDetails } = useAccount();
  const { modalIsVisible: addressModalIsVisible, closeModal: closeAddressModal, showModal: showAddressModal } = useModal();
  const { countryById } = useCountries();

  // We use dry-run to post an order to the api but not store it. The result of
  // the order has all vat and breeding values correctly set. This way we can
  // show a summary of the prices before submitting.
  const [dryRun, setDryRun] = useState<SupplierOrderDetail | undefined>();

  const defaultValues = useMemo((): Partial<SaveSemenOrder> => {
    if (existingOrder) {
      return transformFromOrderDetail(existingOrder, products, categories);
    }
    if (repeatOrder) {
      const repeat = transformFromOrderDetail(repeatOrder, products, categories);
      repeat.shipping_date = today();
      repeat.supplier_note = '';
      repeat.product_price = '0';
      return repeat;
    }
    return {
      shipping_date: today(),
    };
  }, [existingOrder, repeatOrder, products, categories]);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    control,
    watch,
    reset,
    setValue,
    trigger,
    getFieldState,
  } = useForm<SaveSemenOrder>({
    resolver: zodResolver(SaveSemenOrderSchema),
    reValidateMode: 'onChange',
    defaultValues: defaultValues,
  });

  const { fieldError, nonFieldErrors, setApiError, hasApiError } = useFormError(SaveSemenOrderSchema, errors);

  // Check if a step has field errors.
  const stepErrors = useCallback(
    async (step: number, includeApiErrors = true): Promise<boolean> => {
      const fields: (keyof SaveSemenOrder)[][] = [];
      fields[1] = ['customer', 'mare', 'product', 'product_price'];
      fields[2] = ['semen_type', 'usage_type', 'studbook'];
      fields[3] = ['shipping_product', 'receiver', 'shipping_date', 'supplier_note'];
      fields[4] = [];
      for await (const field of fields[step]) {
        await trigger(field);
        if ((includeApiErrors && fieldError(field)) || getFieldState(field).invalid) {
          return true;
        }
      }
      return false;
    },
    [fieldError, trigger, getFieldState],
  );

  // Returns true if this is a repeat order. It can be a new repeat order or it
  // could be that we edit a repeat order.
  const isRepeatOrder = useMemo(() => {
    if (existingOrder) {
      // For existing orders, check if we have an order item with a followup.
      return existingOrder.order_items?.find(item => item.previous_semen_order_item_uid) !== undefined;
    }
    // For a new order, check if the repeatOrder param is set.
    return repeatOrder !== undefined;
  }, [existingOrder, repeatOrder]);

  const pageActions = useMemo((): ActionProps[] => {
    const availableActions: ActionProps[] = [];
    if (step > 1) {
      availableActions.push({
        variant: ButtonVariant.Default,
        text: t('previous-step', 'Previous step'),
        type: 'button',
        onClick: () => {
          setStep(step - 1);
        },
      });
    }
    if (step < 4) {
      availableActions.push({
        variant: ButtonVariant.Primary,
        text: t('next-step', 'Next step'),
        type: 'button',
        loading: isSubmitting,
        onClick: () => {
          stepErrors(step, false).then(res => {
            if (!res) {
              if (step === 3) {
                handleSubmit(onSubmit)()
                  .then(() => {
                    // We have no error is the current step. We can move to the next.
                    setStep(step + 1);
                  })
                  .catch(e => {
                    console.error('Failed to go to final step in order process', e);
                  });
              } else {
                // We have no error is the current step. We can move to the next.
                setStep(step + 1);
              }
            }
          });
        },
      });
    } else if (step === 4) {
      availableActions.push({
        variant: ButtonVariant.Primary,
        text: existingOrder ? t('save-order', 'Save order') : t('place-order', 'Place order'),
        type: 'submit',
        formId: 'AddSemenOrder',
        loading: isSubmitting,
      });
    }
    return availableActions;
  }, [step, stepErrors, t, isSubmitting, handleSubmit]); //eslint-disable-line

  const product = watch('product');
  const selectedCustomerUid = watch('customer');
  const selectedReceiverUid = watch('receiver');
  const shippingProduct = watch('shipping_product');
  const selectedMareUid = watch('mare');
  const selectedStallionUid = watch('product');
  const selectedStallion = products.find(prod => prod.uid === selectedStallionUid);

  const onSubmit = async (data: SaveSemenOrder) => {
    if (!selectedOrganizationUid) {
      console.error('Cannot load invoice without a selected organization');
      return;
    }
    if (!products) {
      console.error('Products not loaded when trying to save order');
      return;
    }

    const dryRun = step !== 4;

    setApiError(undefined);
    if (dryRun) {
      // Only unset the dry run when we're generating a new one. Otherwise the
      // the gui flickers
      setDryRun(undefined);
    }

    try {
      let promise: CancelablePromise<SupplierOrderDetail>;
      if (existingOrder) {
        const updatedFields = transformToOrderDetail(
          data,
          dryRun,
          categories,
          products,
          existingOrder,
          undefined,
          selectedOrganizationDetails?.currency ?? 'EUR',
        ) as PatchedSupplierOrderDetail;

        promise = OrdersService.ordersSuppliedPartialUpdate({
          uid: existingOrder.uid,
          supplierUid: selectedOrganizationUid,
          requestBody: updatedFields,
        });
      } else {
        const supplierOrder = transformToOrderDetail(
          data,
          dryRun,
          categories,
          products,
          undefined,
          repeatOrder,
          selectedOrganizationDetails?.currency ?? 'EUR',
        ) as SupplierOrderDetail;

        promise = OrdersService.ordersSuppliedCreate({
          supplierUid: selectedOrganizationUid,
          requestBody: supplierOrder,
        });
      }
      const result = await promise;

      // No more errors. We have a result.
      setStepsWithError([]);

      if (dryRun) {
        setDryRun(result);
      } else {
        onRequestClose(true);
      }
    } catch (e) {
      setApiError(new ApiErrorParser<SupplierOrderDetail>(e));
      throw e;
    }
  };

  const selectedCustomerName = useMemo(() => {
    if (!selectedCustomerUid) {
      return t('not-selected', 'Not selected');
    }
    const contact = contacts.find(contact => contact.uid === selectedCustomerUid);
    if (contact) {
      return contactName(contact);
    }
    return selectedCustomerUid;
  }, [selectedCustomerUid, t, contacts]);

  const selectedMareName = useMemo(() => {
    if (!selectedMareUid) {
      return t('not-selected', 'Not selected');
    }
    let horse = horses.find(horse => horse.uid === selectedMareUid);
    if (!horse) {
      horse = customerHorses?.find(horse => horse.uid === selectedMareUid);
    }
    if (horse) {
      return horse.UELN ? `${horse.name} (${horse.UELN})` : horse.name;
    }
    return selectedMareUid;
  }, [selectedMareUid, t, horses, customerHorses]);

  const semenTypeOptions = useMemo(() => {
    return SemenTypeOptions(t);
  }, [t]);

  // Build a list of usage type options to show in the dropdown.
  const usageTypeOptions = useMemo(() => {
    return SemenUsageTypeOptions(t);
  }, [t]);

  const receiverContact = useMemo((): Contact | undefined => {
    if (selectedReceiverUid) {
      return contacts.find(contact => contact.uid === selectedReceiverUid);
    }
  }, [selectedReceiverUid, contacts]);

  // Get the shipping product type so we can determine if we need to show the
  // shipping address div.
  const shippingServiceType = (): ShippingServiceTypeEnum | undefined => {
    return products.find(prod => prod.uid === shippingProduct)?.shipping_service_type;
  };

  // Build a list of breeding products to show in the dropdown.
  const productOptions = useMemo((): OptionItemInterface[] => {
    return BreedingProductOptions(
      t,
      horses ?? [],
      products,
      categories ?? [],
      selectedOrganizationDetails?.country,
      selectedOrganizationDetails?.currency,
    );
  }, [products, t, horses, selectedOrganizationDetails, categories]);

  const shippingOptions = useMemo((): OptionItemInterface[] => {
    return ShippingOptions(
      products,
      categories ?? [],
      receiverContact?.country,
      selectedOrganizationDetails?.country,
      selectedOrganizationDetails?.currency,
    );
  }, [products, selectedOrganizationDetails, receiverContact, categories]);

  // List only the mares from the customer
  const customerMares = useMemo(() => {
    return (customerHorses ?? []).filter(horse => horse.sex === SexEnum._2 && !horse.hidden);
  }, [customerHorses]);

  const noShippingOptionsMessage = useMemo((): string | undefined => {
    if (shippingOptions.length > 0 || !receiverContact?.country) {
      return undefined;
    }

    const shippingCountryName = countryById(receiverContact?.country)?.name ?? receiverContact?.country.toString();
    return t('no-shipping-options-purchaser-order', 'No shipping options available for {{shippingCountryName}}.', {
      shippingCountryName,
    });
  }, [shippingOptions.length, receiverContact?.country, countryById, t]);

  // Load the mares from api for the selected customer
  const loadCustomerHorses = useCallback(
    (customerId: string): CancelablePromise<PaginatedHorseList> => {
      const promise = HorsesService.horsesList({
        organisationUid: selectedOrganizationUid ?? '',
        ownerUid: customerId,
      });
      promise
        .then(res => setCustomerHorses(res.results))
        .catch(e => {
          if (!promise.isCancelled) {
            setApiError(new ApiErrorParser<PaginatedHorseList>(e));
          }
        });
      return promise;
    },
    [selectedOrganizationUid, setCustomerHorses, setApiError],
  );

  // Reset the price to the default stallion price when the selected stallion
  // changes. This only applies when we're not in a repeat order (because
  // the price is default 0) and not in an existing order.
  useEffect(() => {
    if (existingOrder || repeatOrder) {
      // Don't alter the price if we have an existing or repeat order.
      return;
    }

    const productItem = products?.find(item => item.uid === product);
    const stallion = horses.find(horse => horse.uid === productItem?.stallion);
    let defaultSemenCollectionStation: Contact | undefined;
    if (stallion?.default_semen_collection_station) {
      defaultSemenCollectionStation = contacts.find(contact => contact.uid === stallion.default_semen_collection_station);
    }
    const price = productItem?.current_price;

    if (price) {
      setValue('product_price', price);
    } else {
      setValue('product_price', '');
    }
    if (defaultSemenCollectionStation) {
      setValue('semen_collection_station', defaultSemenCollectionStation.uid);
    } else {
      setValue('semen_collection_station', '');
    }
  }, [product, existingOrder, repeatOrder, setValue, products, contacts, horses]);

  /**
   * event that will be fired after the modal has been closed
   */
  const resetForm = () => {
    setApiError(undefined);
    setStep(1);
    setStepsWithError([]);
    setCustomerHorses(undefined);
    reset(defaultValues);
  };

  /**
   * Reset the form when a new 'existing order' or 'repeat order' is set.
   */
  useEffect(() => {
    setApiError(undefined);
    setStep(1);
    setStepsWithError([]);
    reset(defaultValues);
  }, [reset, setApiError, defaultValues]);

  // Load the horses from the customer when a customer is selected.
  useEffect(() => {
    if (selectedCustomerUid) {
      loadCustomerHorses(selectedCustomerUid);
    }
    if (!existingOrder && !repeatOrder && step === 1) {
      // We update the receiver to match the customer. Only when we're at the
      // first step in de wizard.
      setValue('receiver', selectedCustomerUid);
    }
  }, [selectedCustomerUid]); //eslint-disable-line

  // Update the steppers errors when we've gotten a validation error.
  useEffect(() => {
    const validateSteps = async (): Promise<number[]> => {
      const stepsErr: number[] = [];
      if (await stepErrors(1)) stepsErr.push(1);
      if (await stepErrors(2)) stepsErr.push(2);
      if (await stepErrors(3)) stepsErr.push(3);
      return stepsErr;
    };
    // We only set the steps with errors when we've gotten an api error. Otherwise
    // the zod form validation does it's job.
    if (hasApiError) {
      validateSteps().then(res => {
        if (res.length > 0) {
          setStepsWithError(res);
          // Go to the first step/group with an error.
          setStep(res[0]);
        }
      });
    }
  }, [hasApiError, setStepsWithError]); //eslint-disable-line

  /**
   * Reset the mare when we switch from customer
   *
   * We use a watch subscription, so we are only checking the values when the input is changed after the initial setup of register() has been done.
   * If we are using the watch() result and use it with a useEffect, it will always trigger when it's value is changed from undefined.
   * The watch subscription does not trigger when the value is changed from undefined to an actual value.
   */
  useEffect(() => {
    const subscription = watch(({ customer: newCustomer }, { name }) => {
      if (name === 'customer' && selectedCustomerUid !== newCustomer) {
        setValue('mare', '');
      }
    });
    return () => subscription.unsubscribe();
  }, [selectedCustomerUid, setValue, watch]);

  return (
    <PageModal
      open={open}
      onClosed={() => {
        resetForm();
        onClosed?.();
      }}
      parentElement='form'
      width={PageModalWidth.Sm}
      parentProps={{ id: 'AddSemenOrder', noValidate: true, onSubmit: handleSubmit(onSubmit) }}
    >
      <PageModalTitle
        title={existingOrder ? t('edit-semen-order', 'Edit semen order') : t('add-semen-order', 'New semen order')}
        onClose={() => onRequestClose(false)}
      />
      <PageModalContent>
        <ErrorSection className='mb-4' errors={nonFieldErrors} />
        <Stepper stepsWithErrors={stepsWithError} totalSteps={4} step={1} expanded={step === 1}>
          {isRepeatOrder ? (
            <p>
              {existingOrder ? (
                <>
                  {t(
                    'edit-repeat-order-intro',
                    'You are editing a repeat order for "{{selectedMareName}}" of "{{selectedCustomerName}}".',
                    {
                      selectedMareName,
                      selectedCustomerName,
                    },
                  )}
                </>
              ) : (
                <>
                  {t(
                    'create-repeat-order-intro',
                    'You are creating a repeat order for "{{selectedMareName}}" of "{{selectedCustomerName}}".',
                    {
                      selectedMareName,
                      selectedCustomerName,
                    },
                  )}
                </>
              )}
              <ErrorSection errors={[fieldError('customer'), fieldError('mare')].filter(e => e !== undefined) as string[]} />
            </p>
          ) : (
            <>
              <ContactInputSelect
                name='customer'
                control={control}
                contacts={contacts ?? []}
                onCreated={contact => {
                  onContactUpdate(); // Update the list of contacts.
                  setValue('customer', contact.uid);
                }}
                required={true}
                label={t('customer', 'Customer')}
                error={fieldError('customer')}
              />
              <HorseInputSelect
                disabled={!selectedCustomerUid}
                name='mare'
                label={t('mare', 'Mare')}
                required={true}
                control={control}
                horses={customerMares}
                error={fieldError('mare')}
                hint={
                  selectedCustomerUid && customerMares?.length === 0
                    ? t('customer-has-no-mares', 'Customer has no mares in ownership. Please add a mare.')
                    : undefined
                }
                // Disabled for now, as the created mare requires to have some ownership
                // See https://gitlab.qubis.nl/equinem/equiapp/-/issues/84
                onCreated={newHorse => {
                  loadCustomerHorses(selectedCustomerUid);
                  setValue('mare', newHorse.uid);
                }}
                createMareOnly={true}
                ownerUid={selectedCustomerUid}
              />
            </>
          )}
          <SelectInput
            label={t('stallion', 'Stallion')}
            nullable={true}
            required={true}
            options={productOptions}
            error={fieldError('product')}
            nullableValue=''
            {...register('product', { setValueAs: transformEmptyToUndefined() })}
          />
          <ContactInputSelect
            name='semen_collection_station'
            control={control}
            contacts={contacts ?? []}
            label={t('semen-collection-station', 'Semen collection station')}
            error={fieldError('semen_collection_station')}
          />
          <TextInput
            required={true}
            type='number'
            lang={accountDetails?.language}
            step='0.01'
            label={t('price', 'Price')}
            {...register('product_price', { setValueAs: transformEmptyToUndefined() })}
            postText={selectedStallion?.current_price_currency ?? selectedOrganizationDetails?.currency ?? 'EUR'}
            error={fieldError('product_price')}
          />
        </Stepper>

        <Stepper stepsWithErrors={stepsWithError} totalSteps={4} step={2} expanded={step === 2}>
          <RadioButtonGroup<SaveSemenOrder>
            name='semen_type'
            required={true}
            control={control}
            options={semenTypeOptions}
            error={fieldError('semen_type')}
            label={t('semen-type', 'Semen type')}
          />
          <SelectInput
            required={true}
            nullable={true}
            options={usageTypeOptions}
            error={fieldError('usage_type')}
            label={t('semen-usage-type', 'Usage type')}
            nullableValue=''
            {...register('usage_type', { setValueAs: transformEmptyToUndefined() })}
          />
          <StudbookInputSelect
            name='studbook'
            label={t('studbook', 'Studbook')}
            required={true}
            control={control}
            studbookOptions={studBookMap}
            error={fieldError('studbook')}
            setValueAs={transformEmptyToUndefined()}
          />
        </Stepper>
        <Stepper stepsWithErrors={stepsWithError} totalSteps={4} step={3} expanded={step === 3}>
          <ContactInputSelect
            name='receiver'
            control={control}
            contacts={contacts ?? []}
            required={true}
            onCreated={contact => {
              onContactUpdate(); // Update the list of contacts.
              setValue('receiver', contact.uid);
            }}
            label={t('order-shipping-receiver', 'Shipping receiver')}
            error={fieldError('receiver')}
          />
          <SelectInput
            required={true}
            options={shippingOptions}
            nullable={true}
            error={fieldError('shipping_product')}
            label={t('shipping-method', 'Shipping method')}
            nullableValue=''
            {...register('shipping_product', { setValueAs: transformEmptyToUndefined() })}
          />
          {noShippingOptionsMessage && <Alert message={noShippingOptionsMessage} severity={Severity.Danger} />}
          {receiverContact !== undefined &&
            shippingServiceType() !== ShippingServiceTypeEnum.PICK_UP &&
            shippingServiceType() !== undefined && <ContactAddress contact={receiverContact} onEditAddress={() => showAddressModal()} />}
          <DateInput
            control={control}
            required={true}
            label={
              shippingServiceType() !== ShippingServiceTypeEnum.PICK_UP
                ? t('shipping-date', 'Shipping date')
                : t('pickup-date', 'Pick-up date')
            }
            name='shipping_date'
            error={fieldError('shipping_date')}
          />
          <TextAreaInput label={t('internal-note', 'Internal note')} {...register('supplier_note')} error={fieldError('supplier_note')} />
        </Stepper>
        <Stepper stepsWithErrors={stepsWithError} totalSteps={4} step={4} expanded={step === 4}>
          <div className='my-4 space-y-4'>
            {dryRun && <OrderSummaryTable productCategories={categories} order={dryRun} products={products} stallions={horses} />}
          </div>
        </Stepper>
      </PageModalContent>
      <PageModalActions actions={pageActions} />
      {receiverContact && (
        // Modal for quickly updating the shipping address inside the order modal.
        <UpdateAddressModal
          isVisible={addressModalIsVisible}
          onRequestCloseModal={closeAddressModal}
          contact={receiverContact}
          onUpdated={() => onContactUpdate()}
        />
      )}
    </PageModal>
  );
}
export default SaveSemenOrder;
