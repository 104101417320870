import ApiErrorParser from 'api/ApiErrorParser';
import { useOrganization } from 'context/OrganizationContext';
import { usePage } from 'context/PageContext';
import { CancelablePromise, Contact, ContactsService, CountryEnum, OAuth2Token, OauthService, ProviderEnum } from 'openapi';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button, { ButtonVariant } from 'ui/Button';
import { PageMaxWidth } from 'ui/Layout/Page';
import { Tile } from 'ui/Layout/Tile';
import PullScrollWrapper from 'ui/PullScrollWrapper';
import { Page } from '../../ui/Layout';
import SetupIntegrationModal from './SetupIntegrationModal';
import { Integration, IntegrationId, integrations as integrationsConfig } from 'utilities/Integrations';
import ConfigureMicrosoftIntegrationModal from 'components/Integrations/ConfigureMicrosoftIntegrationModal';
import ConfigureExactIntegrationModal from 'components/Integrations/ConfigureExactIntegrationModal';
import IntegrationRow from 'components/Integrations/IntegrationRow';
import useModal from 'ui/Modals/UseModal';
import ContactSyncStatus from 'components/Integrations/ContactSyncStatus';
import ConfigureRvoModal from 'components/Integrations/ConfigureRvoModal';
import useRvoImportStatus from 'hooks/UseRvoImportStatus';
import { ApiPromises } from 'utilities/ApiPromises';

export interface IntegrationDetailSetup extends Integration {
  success: boolean;
}

// The following configurations are configurable.
const CONFIGURABLE = ['microsoft', 'exactnl'];

/**
 * This page lets the organization admin connect to other oauth2 services.
 */
export default function IntegrationsPage(): JSX.Element {
  const [integrations, setIntegrations] = useState<OAuth2Token[]>();
  const [setup, setSetup] = useState<IntegrationDetailSetup | undefined>(); // The integration to show in the modal
  const [setupModalVisible, setSetupModalVisible] = useState<boolean>(false);
  const [showConfigModal, setShowConfigModal] = useState<IntegrationId | undefined>();
  const [, setApiPromises] = useState<ApiPromises>();
  const [contacts, setContacts] = useState<Contact[]>();

  // define the counters for the polling mechanism
  const [pollingTimeInMs, setPollingTimeInMs] = useState(1000);
  const pollingCount = useRef<number>(0);

  const { t } = useTranslation();
  const { selectedOrganization, selectedOrganizationDetails, selectedOrganizationUid, generateCacheKey } = useOrganization();
  const { setApiError } = usePage();

  const { closeModal: closeRvoModal, modalIsVisible: rvoModalIsVisible, showModal: showRvoModal } = useModal();
  const {
    closeModal: closeExactContactSyncModal,
    modalIsVisible: exactContactSyncModalIsVisible,
    showModal: showExactContactSyncModal,
  } = useModal();
  const {
    closeModal: closeMoneybirdContactSyncModal,
    modalIsVisible: moneybirdContactSyncModalIsVisible,
    showModal: showMoneybirdContactSyncModal,
  } = useModal();
  const {
    closeModal: closeSnelstartContactSyncModal,
    modalIsVisible: snelstartContactSyncModalIsVisible,
    showModal: showSnelstartContactSyncModal,
  } = useModal();

  const {
    previousImportMessages,
    importModalElement,
    showImportModal: showRvoImportModal,
    isImporting: rvoIsImporting,
  } = useRvoImportStatus({ contacts });

  const integrationsConfigList = useMemo(() => integrationsConfig(t), [t]);

  const unimplemented = useMemo(() => {
    if (!integrations) {
      return undefined;
    }
    const applied = integrations.map(integration => integration.name);
    return ['mollie', 'moneybird', 'exactnl', 'microsoft', 'snelstart'].filter(name => !applied.includes(name));
  }, [integrations]);

  // Load oauth integrations from the api.
  // TODO move this to the apiPromises
  const loadIntegrations = useCallback((): CancelablePromise<OAuth2Token[]> => {
    const promise = OauthService.oauthTokensList({ organisationUid: selectedOrganization?.uid ?? '' });
    promise
      .then(res => {
        if (setupModalVisible && setup) {
          const found = res.find(item => item.name === setup.id.toString());
          if (found && setup) {
            const cp = setup;
            cp.success = true;
            setSetup(cp);
          }
        }
        setApiError(undefined);
        setIntegrations(res);
      })
      .catch(e => {
        if (!promise.isCancelled) {
          setApiError(new ApiErrorParser<OAuth2Token[]>(e));
        }
      });
    return promise;
  }, [selectedOrganization, setApiError, setup, setupModalVisible]);

  // A function to disconnect from a oauth integration.
  const disconnectIntegration = useCallback(
    async (uid: string) => {
      try {
        await OauthService.oauthTokensDestroy({ organisationUid: selectedOrganization?.uid ?? '', uid });
        loadIntegrations();
      } catch (e) {
        setApiError(new ApiErrorParser<OAuth2Token[]>(e));
      }
    },
    [setApiError, selectedOrganization, loadIntegrations],
  );

  // Load integrations from api at init
  // TODO move this to the apiPromises
  useEffect(() => {
    // We need to have a selected organization.
    if (selectedOrganization) {
      const promise = loadIntegrations();
      return () => promise.cancel();
    }
  }, [selectedOrganization]); //eslint-disable-line

  /**
   * Polling for the integrations when we have a configure modal open.
   */
  useEffect(() => {
    const timer = setInterval(() => {
      setPollingTimeInMs(pollingCount.current > 1 * 10 ? 5000 : 1000);
      if (setupModalVisible) {
        loadIntegrations().then(() => {
          pollingCount.current++;
        });
      } else {
        pollingCount.current++;
      }
    }, pollingTimeInMs);

    //Clearing the interval
    return () => clearTimeout(timer);
  }, [pollingTimeInMs]); //eslint-disable-line

  /**
   * Load data from the api/cache
   */
  const loadApiData = useCallback((): ApiPromises => {
    const promises = new ApiPromises();

    if (!selectedOrganizationUid) {
      return promises;
    }

    promises.appendList<Contact>(
      'contacts',
      () =>
        ContactsService.contactsList({
          organisationUid: selectedOrganizationUid,
        }),
      setContacts,
      generateCacheKey('all-contacts'),
    );

    setApiPromises(promises);
    return promises;
  }, [selectedOrganizationUid, generateCacheKey]);

  /**
   * Load from the api
   */
  useEffect(() => {
    if (selectedOrganizationUid) {
      const promise = loadApiData();
      return () => promise.cancel();
    }
  }, [selectedOrganizationUid]); //eslint-disable-line

  return (
    <Page title={t('integrations', 'Integrations')} maxWidth={PageMaxWidth.Tile} loading={!integrations}>
      <PullScrollWrapper onRefresh={loadIntegrations}>
        <div className='space-y-4'>
          <Tile title={t('active-integrations', 'Active integrations')}>
            <ul className='flex flex-col gap-4'>
              {(integrations ?? []).map(integration => {
                const detail = integrationsConfigList.find(item => item.id.toString() === integration.name);
                return (
                  <IntegrationRow
                    key={integration.uid}
                    description={detail?.description}
                    iconUrl={detail?.iconUrl}
                    title={detail?.title ?? integration.name}
                    buttons={
                      <div className='space-x-1.5 shrink-0'>
                        {CONFIGURABLE.includes(integration.name) && (
                          <Button type='button' onClick={() => setShowConfigModal(detail?.id)} variant={ButtonVariant.Default}>
                            {t('configure', 'Configure')}
                          </Button>
                        )}
                        {detail?.id === IntegrationId.exactnl && (
                          <Button onClick={showExactContactSyncModal}>{t('sync-status', 'Sync status')}</Button>
                        )}
                        {detail?.id === IntegrationId.moneybird && (
                          <Button onClick={showMoneybirdContactSyncModal}>{t('sync-status', 'Sync status')}</Button>
                        )}
                        {detail?.id === IntegrationId.snelstart && (
                          <Button onClick={showSnelstartContactSyncModal}>{t('sync-status', 'Sync status')}</Button>
                        )}
                        <Button variant={ButtonVariant.Danger} onClick={() => disconnectIntegration(integration.uid)}>
                          {t('disconnect', 'Disconnect')}
                        </Button>
                      </div>
                    }
                  />
                );
              })}
              <IntegrationRow
                description={t('horsetelex-desc', 'Use HorseTelex to import your pedigree data.')}
                iconUrl='/integrations/horsetelex.png'
                title={t('horsetelex', 'HorseTelex')}
              />
              {selectedOrganizationDetails?.country === CountryEnum.NL && (
                <IntegrationRow
                  statusMessage={previousImportMessages}
                  description={t('rvo-integration-desc', "Report horses and import horse data. Connect with RvO at 'Manage locations'.")}
                  iconUrl='/integrations/rvo.png'
                  title={t('rvo-full', 'Rijksdienst voor Ondernemend Nederland (RvO)')}
                  buttons={
                    <div className='space-x-1.5 shrink-0 flex items-center'>
                      <Button onClick={showRvoImportModal} loading={rvoIsImporting}>
                        {!rvoIsImporting ? t('import-from-rvo', 'Import from RvO') : t('importing', 'Importing')}
                      </Button>

                      <Button variant={ButtonVariant.Default} onClick={showRvoModal}>
                        {t('connect-location', 'Connect location(s)')}
                      </Button>
                    </div>
                  }
                />
              )}
            </ul>
          </Tile>

          {unimplemented !== undefined && unimplemented.length > 0 && (
            <Tile title={t('add-integrations', 'Add an integration')}>
              <ul className='flex flex-col gap-4'>
                {(unimplemented ?? []).map(integration => {
                  const detail = integrationsConfigList.find(item => item.id.toString() === integration);
                  return (
                    <IntegrationRow
                      key={integration}
                      description={detail?.description}
                      iconUrl={detail?.iconUrl}
                      title={detail?.title ?? integration}
                      buttons={
                        <div>
                          {detail && (
                            <Button
                              onClick={() => {
                                setSetup({ ...detail, success: false });
                                setSetupModalVisible(true);
                              }}
                            >
                              {t('connect', 'Connect')}
                            </Button>
                          )}
                        </div>
                      }
                    />
                  );
                })}
              </ul>
            </Tile>
          )}
        </div>
      </PullScrollWrapper>
      <SetupIntegrationModal
        integration={setup}
        visible={setupModalVisible}
        onRequestCloseModal={() => {
          setSetupModalVisible(false);
          setSetup(undefined);
        }}
      />
      <ConfigureMicrosoftIntegrationModal
        onRequestClose={() => setShowConfigModal(undefined)}
        visible={showConfigModal === IntegrationId.microsoft}
      />
      <ConfigureExactIntegrationModal
        onRequestClose={() => setShowConfigModal(undefined)}
        visible={showConfigModal === IntegrationId.exactnl}
      />

      <ContactSyncStatus
        onRequestClose={closeExactContactSyncModal}
        isVisible={exactContactSyncModalIsVisible}
        integration={ProviderEnum.EXACTNL}
        onUnlinked={loadApiData}
        contacts={contacts ?? []}
      />
      <ContactSyncStatus
        onRequestClose={closeMoneybirdContactSyncModal}
        isVisible={moneybirdContactSyncModalIsVisible}
        integration={ProviderEnum.MONEYBIRD}
        onUnlinked={loadApiData}
        contacts={contacts ?? []}
      />
      <ContactSyncStatus
        onRequestClose={closeSnelstartContactSyncModal}
        isVisible={snelstartContactSyncModalIsVisible}
        integration={ProviderEnum.SNELSTART}
        onUnlinked={loadApiData}
        contacts={contacts ?? []}
      />

      <ConfigureRvoModal
        isVisible={rvoModalIsVisible}
        onRequestCloseModal={closeRvoModal}
        onContactUpdated={loadApiData}
        contacts={contacts ?? []}
      />
      {importModalElement}
    </Page>
  );
}
